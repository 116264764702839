import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { mobileSearchModels } from '../helpers/mobile-search-models'

export function ModelList() {
    let navigate = useNavigate();

    function goTo(page : string, state: any) {
        navigate({
        pathname: page,
        search: `?${createSearchParams(state)}`
        });
    }

    const [searchParams, setSearchParams] = useSearchParams();

    let params = {
        make: searchParams.get('make'),
        makeIndex: Number(searchParams.get('id')),
      };
    const models = mobileSearchModels[params.makeIndex].slice(1);

    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {models.map((modelName: any, index: any)=>{
            return  <div 
                        className='modelCard' 
                        key={index} 
                        onClick={()=>{goTo('/information', {make: params.make, model: modelName})}}> 
                        {modelName}
                    </div>
        })}
      </div>
    );
  }