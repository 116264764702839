export const DATA_BRANDS = {
	ACURA: 'acura',
	ALFA_ROMEO: 'alfa-romeo',
	ALPINA: 'alpina',
	ASTON_MARTIN: 'aston-martin',
	AUDI: 'audi',
	BENTLEY: 'bentley',
	BERTONE: 'bertone',
	BMW: 'bmw',
	BUGATTI: 'bugatti',
	BYD: 'byd',
	CADILLAC: 'cadillac',
	CHEVROLET: 'chevrolet',
	CHRYSLER: 'chrysler',
	CITROEN: 'citroen',
	DACIA: 'dacia',
	DAEWOO: 'daewoo',
	DAIHATSU: 'daihatsu',
	DAIMLER: 'daimler',
	DODGE: 'dodge',
	DONGFENG: 'dongfeng',
	EAGLE: 'eagle',
	FERRARI: 'ferrari',
	FIAT: 'fiat',
	FORD: 'ford',
	FSO: 'fso',
	GAZ: 'gaz',
	GEELY: 'geely',
	GEO: 'geo',
	GMC: 'gmc',
	GONOW: 'gonow',
	GREAT_WALL: 'great-wall',
	HONDA: 'honda',
	HYUNDAI: 'hyundai',
	INFINITI: 'infiniti',
	JAGUAR: 'jaguar',
	JEEP: 'jeep',
	KIA: 'kia',
	LAMBORGHINI: 'lamborghini',
	LANCIA: 'lancia',
	LAND_ROVER: 'land-rover',
	LANDWIND: 'landwind',
	LEXUS: 'lexus',
	LINCOLN: 'lincoln',
	LOTUS: 'lotus',
	MAHINDRA: 'mahindra',
	MASERATI: 'maserati',
	MAYBACH: 'maybach',
	MAZDA: 'mazda',
	MERCEDES_BENZ: 'mercedes-benz',
	MERCURY: 'mercury',
	MG: 'mg',
	MICROCAR: 'microcar',
	MINI: 'mini',
	MITSUBISHI: 'mitsubishi',
	MOSKVICH: 'moskvich',
	NISSAN: 'nissan',
	OPEL: 'opel',
	PEUGEOT: 'peugeot',
	PLYMOUTH: 'plymouth',
	PONTIAC: 'pontiac',
	PORSCHE: 'porsche',
	PROTON: 'proton',
	RENAULT: 'renault',
	ROLLS_ROYCE: 'rolls-royce',
	ROVER: 'rover',
	SAAB: 'saab',
	SATURN: 'saturn',
	SCION: 'scion',
	SEAT: 'seat',
	SKODA: 'skoda',
	SMART: 'smart',
	SSANGYONG: 'ssangyong',
	SUBARU: 'subaru',
	SUZUKI: 'suzuki',
	TALBOT: 'talbot',
	TESLA: 'tesla',
	TOYOTA: 'toyota',
	VW: 'volkswagen',
	VOLVO: 'volvo',
}

export const DATA_MODELS = {
	'ACURA': {
		'ILX': 'ilx',
		'MDX': 'mdx',
		'NSX': 'nsx',
		'RDX': 'rdx',
		'RL': 'rl',
		'RSX': 'rsx',
		'SLX': 'slx',
		'TL': 'tl',
		'TSX': 'tsx',
		'ZDX': 'zdx',
	},
	'ALFA_ROMEO': {
		'145': '145',
		'146': '146',
		'147': '147',
		'155': '155',
		'156': '156',
		'159': '159',
		'164': '164',
		'166': '166',
		'33': '33',
		'4C': '4c',
		'75': '75',
		'8C_COMPETIZIONE': '8c-competizione',
		'90': '90',
		'ALFETTA': 'alfetta',
		'BRERA': 'brera',
		'GIULIA': 'giulia',
		'GIULIETTA': 'giulietta',
		'GT': 'gt',
		'GTV': 'gtv',
		'MITO': 'mito',
		'SPIDER': 'spider',
		'STELVIO': 'stelvio',
	},
	'ALPINA': {
		'B10': 'b10',
		'B11': 'b11',
		'B12': 'b12',
		'B3': 'b3',
		'B5': 'b5',
		'B6': 'b6',
		'B7': 'b7',
		'B8': 'b8',
		'B9': 'b9',
		'C1': 'c1',
		'C2': 'c2',
		'D3': 'd3',
		'D5': 'd5',
		'XD3': 'xd3',
	},
	'ASTON_MARTIN': {
		'DB7': 'db7',
		'DB9': 'db9',
		'DBS': 'dbs',
		'DBX': 'dbx',
		'RAPIDE': 'rapide',
		'V12_VANTAGE': 'v12-vantage',
	},
	'AUDI': {
		'100': '100',
		'200': '200',
		'50': '50',
		'80': '80',
		'90': '90',
		'A1': 'a1',
		'A2': 'a2',
		'A3': 'a3',
		'A4': 'a4',
		'A5': 'a5',
		'A6': 'a6',
		'A7': 'a7',
		'A8': 'a8',
		'ALLROAD': 'allroad',
		'CABRIOLET': 'cabriolet',
		'COUPE': 'coupe',
		'Q2': 'q2',
		'Q3': 'q3',
		'Q5': 'q5',
		'Q7': 'q7',
		'Q8': 'q8',
		'QUATTRO': 'quattro',
		'R8': 'r8',
		'RS3': 'rs3',
		'RS4': 'rs4',
		'RS5': 'rs5',
		'RS6': 'rs6',
		'RS7': 'rs7',
		'S2': 's2',
		'S3': 's3',
		'S4': 's4',
		'S5': 's5',
		'S6': 's6',
		'S7': 's7',
		'S8': 's8',
		'TT': 'tt',
	},
	'BENTLEY': {
		'ARNAGE': 'arnage',
		'AZURE': 'azure',
		'BENTAYGA': 'bentayga',
		'CONTINENTAL': 'continental',
		'MULSANNE': 'mulsanne',
	},
	'BERTONE': {
		'FREECLIMBER': 'freeclimber',
	},
	'BMW': {
		'1_ER': '1er',
		'2_ER': '2er',
		'3_ER': '3er',
		'4_ER': '4er',
		'5_ER': '5er',
		'6_ER': '6er',
		'7_ER': '7er',
		'8_ER': '8er',
		'I3': 'i3',
		'I4': 'i4',
		'I7': 'i7',
		'I8': 'i8',
		'M3': 'm3',
		'M5': 'm5',
		'M6': 'm6',
		'X1': 'x1',
		'X2': 'x2',
		'X3': 'x3',
		'X4': 'x4',
		'X5': 'x5',
		'X6': 'x6',
		'Z1': 'z1',
		'Z3': 'z3',
		'Z4': 'z4',
		'Z8': 'z8',
	},
	'BUGATTI': {
		'CHIRON': 'chiron',
		'VEYRON': 'veyron',
	},
	'BYD': {
	},
	'CADILLAC': {
		'ALLANTE': 'allante',
		'BLS': 'bls',
		'BROUGHAM': 'brougham',
		'CTS': 'cts',
		'DTS': 'dts',
		'ELDORADO': 'eldorado',
		'ESCALADE': 'escalade',
		'FLEETWOOD': 'fleetwood',
		'SEVILLE': 'seville',
		'SRX': 'srx',
		'STS': 'sts',
		'XLR': 'xlr',
	},
	'CHEVROLET': {
		'ALERO': 'alero',
		'ASTRO': 'astro',
		'AVALANCHE': 'avalanche',
		'AVEO': 'aveo',
		'BERETTA': 'beretta',
		'BLAZER': 'blazer',
		'CAMARO': 'camaro',
		'CAPRICE': 'caprice',
		'CAPTIVA': 'captiva',
		'CAVALIER': 'cavalier',
		'COBALT': 'cobalt',
		'COLORADO': 'colorado',
		'CORVETTE': 'corvette',
		'CRUZE': 'cruze',
		'EPICA': 'epica',
		'EQUINOX': 'equinox',
		'EVANDA': 'evanda',
		'HHR': 'hhr',
		'IMPALA': 'impala',
		'LACETTI': 'lacetti',
		'LUMINA': 'lumina',
		'MALIBU': 'malibu',
		'NIVA': 'niva',
		'NUBIRA': 'nubira',
		'ORLANDO': 'orlando',
		'REZZO': 'rezzo',
		'SILVERADO': 'silverado',
		'SPARK': 'spark',
		'SSR': 'ssr',
		'SUBURBAN': 'suburban',
		'TAHOE': 'tahoe',
		'TRACKER': 'tracker',
		'TRAILBLAZER': 'trailblazer',
		'TRAX': 'trax',
	},
	'CHRYSLER': {
		'300C': '300c',
		'300M': '300m',
		'CROSSFIRE': 'crossfire',
		'NEON': 'neon',
		'NEW_YORKER': 'new-yorker',
		'PACIFICA': 'pacifica',
		'PT_CRUISER': 'pt-cruiser',
		'SARATOGA': 'saratoga',
		'SEBRING': 'sebring',
		'STRATUS': 'stratus',
		'VISION': 'vision',
		'VOYAGER': 'voyager',
	},
	'CITROEN': {
		'AX': 'ax',
		'BERLINGO': 'berlingo',
		'BX': 'bx',
		'C_CROSSER': 'c-crosser',
		'C_ELYSEE': 'c-elysee',
		'C1': 'c1',
		'C2': 'c2',
		'C3': 'c3',
		'C4': 'c4',
		'C4_AIRCROSS': 'c4-aircross',
		'C4_CACTUS': 'c4-cactus',
		'C5': 'c5',
		'C5_AIRCROSS': 'c5-aircross',
		'C5X': 'c5x',
		'C6': 'c6',
		'C8': 'c8',
		'CX': 'cx',
		'DS': 'ds',
		'DS3': 'ds3',
		'DS4': 'ds4',
		'DS5': 'ds5',
		'EVASION': 'evasion',
		'JUMPY': 'jumpy',
		'SAXO': 'saxo',
		'VISA': 'visa',
		'XANTIA': 'xantia',
		'XM': 'xm',
		'XSARA': 'xsara',
		'ZX': 'zx',
	},
	'DACIA': {
		'1300': '1300',
		'1310': '1310',
		'DOKKER': 'dokker',
		'DUSTER': 'duster',
		'LODGY': 'lodgy',
		'LOGAN': 'logan',
		'NOVA': 'nova',
		'SANDERO': 'sandero',
		'SOLENZA': 'solenza',
		'SPRING': 'spring',
	},
	'DAEWOO': {
		'ESPERO': 'espero',
		'EVANDA': 'evanda',
		'KALOS': 'kalos',
		'KORANDO': 'korando',
		'LACETTI': 'lacetti',
		'LEGANZA': 'leganza',
		'MAGNUS': 'magnus',
		'MATIZ': 'matiz',
		'MUSSO': 'musso',
		'NEXIA': 'nexia',
		'NUBIRA': 'nubira',
		'PRINCE': 'prince',
		'RACER': 'racer',
		'REZZO': 'rezzo',
		'TACUMA': 'tacuma',
		'TICO': 'tico',
	},
	'DAIHATSU': {
		'APPLAUSE': 'applause',
		'CHARADE': 'charade',
		'CHARMANT': 'charmant',
		'COPEN': 'copen',
		'CUORE': 'cuore',
		'FEROZA': 'feroza',
		'MATERIA': 'materia',
		'MOVE': 'move',
		'ROCKY': 'rocky',
		'SIRION': 'sirion',
		'TERIOS': 'terios',
		'TREVIS': 'trevis',
		'YRV': 'yrv',
	},
	'DAIMLER': {
	},
	'DODGE': {
		'AVENGER': 'avenger',
		'CALIBER': 'caliber',
		'CARAVAN': 'caravan',
		'CHALLENGER': 'challenger',
		'CHARGER': 'charger',
		'DAKOTA': 'dakota',
		'DAYTONA': 'daytona',
		'DURANGO': 'durango',
		'JOURNEY': 'journey',
		'MAGNUM': 'magnum',
		'NEON': 'neon',
		'NITRO': 'nitro',
		'RAM': 'ram',
		'SHADOW': 'shadow',
		'STEALTH': 'stealth',
		'STRATUS': 'stratus',
		'VIPER': 'viper',
	},
	'DONGFENG': {
	},
	'EAGLE': {
		'TALON': 'talon',
		'VISION': 'vision',
	},
	'FERRARI': {
		'348': '348',
		'599': '599',
		'CALIFORNIA': 'california',
		'ENZO': 'enzo',
		'FF': 'ff',
		'TESTAROSSA': 'testarossa',
	},
	'FIAT': {
		'124': '124',
		'126': '126',
		'127': '127',
		'128': '128',
		'131': '131',
		'132': '132',
		'500': '500',
		'600': '600',
		'ALBEA': 'albea',
		'ARGENTA': 'argenta',
		'BARCHETTA': 'barchetta',
		'BRAVA': 'brava',
		'BRAVO': 'bravo',
		'CINQUECENTO': 'cinquecento',
		'COUPE': 'coupe',
		'CROMA': 'croma',
		'DOBLO': 'doblo',
		'DUNA': 'duna',
		'FIORINO': 'fiorino',
		'FREEMONT': 'freemont',
		'FULLBACK': 'fullback',
		'IDEA': 'idea',
		'LINEA': 'linea',
		'MAREA': 'marea',
		'MULTIPLA': 'multipla',
		'PALIO': 'palio',
		'PANDA': 'panda',
		'PUNTO': 'punto',
		'QUBO': 'qubo',
		'REGATA': 'regata',
		'RITMO': 'ritmo',
		'SEDICI': 'sedici',
		'SEICENTO': 'seicento',
		'SIENA': 'siena',
		'STILO': 'stilo',
		'STRADA': 'strada',
		'TEMPRA': 'tempra',
		'TIPO': 'tipo',
		'ULYSSE': 'ulysse',
		'UNO': 'uno',
	},
	'FORD': {
		'AEROSTAR': 'aerostar',
		'B_MAX': 'b-max',
		'BRONCO': 'bronco',
		'C_MAX': 'c-max',
		'CAPRI': 'capri',
		'CONSUL': 'consul',
		'COUGAR': 'cougar',
		'CROWN_VICTORIA': 'crown-victoria',
		'ECOSPORT': 'ecosport',
		'EDGE': 'edge',
		'ESCAPE': 'escape',
		'ESCORT': 'escort',
		'EXCURSION': 'excursion',
		'EXPEDITION': 'expedition',
		'EXPLORER': 'explorer',
		'FIESTA': 'fiesta',
		'FLEX': 'flex',
		'FOCUS': 'focus',
		'FUSION': 'fusion',
		'GALAXY': 'galaxy',
		'GRANADA': 'granada',
		'KA': 'ka',
		'KUGA': 'kuga',
		'MAVERICK': 'maverick',
		'MONDEO': 'mondeo',
		'MUSTANG': 'mustang',
		'ORION': 'orion',
		'PROBE': 'probe',
		'PUMA': 'puma',
		'RANGER': 'ranger',
		'S_MAX': 's-max',
		'SCORPIO': 'scorpio',
		'SIERRA': 'sierra',
		'TAUNUS': 'taunus',
		'TAURUS': 'taurus',
		'THUNDERBIRD': 'thunderbird',
		'WINDSTAR': 'windstar',
	},
	'FSO': {
		'POLONEZ': 'polonez',
	},
	'GAZ': {
		'69': '69',
	},
	'GEELY': {
	},
	'GEO': {
		'METRO': 'metro',
		'PRIZM': 'prizm',
		'STORM': 'storm',
		'TRACKER': 'tracker',
	},
	'GMC': {
		'ACADIA': 'acadia',
		'CANYON': 'canyon',
		'ENVOY': 'envoy',
		'JIMMY': 'jimmy',
		'SAVANA': 'savana',
		'SIERRA': 'sierra',
		'SONOMA': 'sonoma',
		'TYPHOON': 'typhoon',
		'YUKON': 'yukon',
	},
	'GONOW': {
	},
	'GREAT_WALL': {
		'SAFE': 'safe',
	},
	'HONDA': {
		'ACCORD': 'accord',
		'CITY': 'city',
		'CIVIC': 'civic',
		'CONCERTO': 'concerto',
		'CR_V': 'cr-v',
		'CRX': 'crx',
		'ELEMENT': 'element',
		'ELYSION': 'elysion',
		'FIT': 'fit',
		'FR_V': 'fr-v',
		'HR_V': 'hr-v',
		'INSIGHT': 'insight',
		'INTEGRA': 'integra',
		'JAZZ': 'jazz',
		'LEGEND': 'legend',
		'LOGO': 'logo',
		'NSX': 'nsx',
		'ODYSSEY': 'odyssey',
		'PASSPORT': 'passport',
		'PILOT': 'pilot',
		'PRELUDE': 'prelude',
		'QUINTET': 'quintet',
		'RIDGELINE': 'ridgeline',
		'S2000': 's2000',
		'SHUTTLE': 'shuttle',
		'STREAM': 'stream',
	},
	'HYUNDAI': {
		'ACCENT': 'accent',
		'ATOS': 'atos',
		'COUPE': 'coupe',
		'ELANTRA': 'elantra',
		'GALLOPER': 'galloper',
		'GENESIS': 'genesis',
		'GETZ': 'getz',
		'GRANDEUR': 'grandeur',
		'I10': 'i10',
		'I20': 'i20',
		'I30': 'i30',
		'I40': 'i40',
		'IONIQ': 'ioniq',
		'IX20': 'ix20',
		'KONA': 'kona',
		'LANTRA': 'lantra',
		'MATRIX': 'matrix',
		'PONY': 'pony',
		'SANTA_FE': 'santa-fe',
		'SANTAMO': 'santamo',
		'SONATA': 'sonata',
		'TERRACAN': 'terracan',
		'TRAJET': 'trajet',
		'TUCSON': 'tucson',
		'XG': 'xg',
	},
	'INFINITI': {
		'G37': 'g37',
		'Q30': 'q30',
		'Q45': 'q45',
		'Q50': 'q50',
		'QX4': 'qx4',
		'QX56': 'qx56',
	},
	'JAGUAR': {
		'E_PACE': 'e-pace',
		'F_PACE': 'f-pace',
		'F_TYPE': 'f-type',
		'I_PACE': 'i-pace',
		'S_TYPE': 's-type',
		'X_TYPE': 'x-type',
		'XE': 'xe',
		'XF': 'xf',
		'XJ': 'xj',
		'XJR': 'xjr',
		'XKR': 'xkr',
	},
	'JEEP': {
		'CHEROKEE': 'cherokee',
		'COMMANDER': 'commander',
		'COMPASS': 'compass',
		'GRAND_CHEROKEE': 'grand-cherokee',
		'PATRIOT': 'patriot',
		'RENEGADE': 'renegade',
		'WRANGLER': 'wrangler',
	},
	'KIA': {
		'CARENS': 'carens',
		'CARNIVAL': 'carnival',
		'CEED': 'ceed',
		'CLARUS': 'clarus',
		'MAGENTIS': 'magentis',
		'MOHAVE': 'mohave',
		'OPIRUS': 'opirus',
		'OPTIMA': 'optima',
		'PICANTO': 'picanto',
		'PRIDE': 'pride',
		'RETONA': 'retona',
		'RIO': 'rio',
		'SEDONA': 'sedona',
		'SEPHIA': 'sephia',
		'SHUMA': 'shuma',
		'SORENTO': 'sorento',
		'SOUL': 'soul',
		'SPECTRA': 'spectra',
		'SPORTAGE': 'sportage',
		'STINGER': 'stinger',
		'STONIC': 'stonic',
		'VENGA': 'venga',
		'VISTO': 'visto',
	},
	'LAMBORGHINI': {
		'AVENTADOR': 'aventador',
		'COUNTACH': 'countach',
		'DIABLO': 'diablo',
		'GALLARDO': 'gallardo',
		'HURACAN': 'huracan',
		'MURCIELAGO': 'murcielago',
		'REVENTON': 'reventon',
		'URUS': 'urus',
		'VENENO': 'veneno',
	},
	'LANCIA': {
		'BETA': 'beta',
		'DEDRA': 'dedra',
		'DELTA': 'delta',
		'FULVIA': 'fulvia',
		'KAPPA': 'kappa',
		'LYBRA': 'lybra',
		'MUSA': 'musa',
		'PHEDRA': 'phedra',
		'PRISMA': 'prisma',
		'THEMA': 'thema',
		'THESIS': 'thesis',
		'Y': 'y',
		'ZETA': 'zeta',
	},
	'LAND_ROVER': {
		'DEFENDER': 'defender',
		'DISCOVERY': 'discovery',
		'FREELANDER': 'freelander',
		'RANGE_ROVER': 'range-rover',
		'RANGE_ROVER_EVOQUE': 'range-rover-evoque',
	},
	'LANDWIND': {
	},
	'LEXUS': {
		'ES': 'es',
		'GS': 'gs',
		'IS': 'is',
		'LC': 'lc',
		'LS': 'ls',
		'LX': 'lx',
		'NX': 'nx',
		'RX': 'rx',
		'SC': 'sc',
	},
	'LINCOLN': {
		'CONTINENTAL': 'continental',
		'LS': 'ls',
		'MARK': 'mark',
		'MARK_LT': 'mark-lt',
		'MKX': 'mkx',
		'MKZ': 'mkz',
		'NAVIGATOR': 'navigator',
		'TOWN_CAR': 'town-car',
		'ZEPHYR': 'zephyr',
	},
	'LOTUS': {
		'ELISE': 'elise',
		'EVORA': 'evora',
		'EXIGE': 'exige',
	},
	'MAHINDRA': {
		'ARMADA': 'armada',
		'BOLERO': 'bolero',
		'CL': 'cl',
		'COMMANDER': 'commander',
		'KUV_100': 'kuv-100',
		'SCORPIO': 'scorpio',
	},
	'MASERATI': {
		'3200_GT': '3200-gt',
		'BITURBO': 'biturbo',
		'GHIBLI': 'ghibli',
		'GRANCABRIO': 'grancabrio',
		'GRANSPORT': 'gransport',
		'GRANTURISMO': 'granturismo',
		'LEVANTE': 'levante',
		'QUATTROPORTE': 'quattroporte',
		'SPYDER': 'spyder',
	},
	'MAYBACH': {
	},
	'MAZDA': {
		'121': '121',
		'323': '323',
		'626': '626',
		'929': '929',
		'BT_50': 'bt-50',
		'CX_30': 'cx-30',
		'CX_5': 'cx-5',
		'CX_60': 'cx-60',
		'CX_7': 'cx-7',
		'CX_9': 'cx-9',
		'DEMIO': 'demio',
		'2': 'mazda-2',
		'3': 'mazda-3',
		'5': 'mazda-5',
		'6': 'mazda-6',
		'MPV': 'mpv',
		'MX_3': 'mx-3',
		'MX_5': 'mx-5',
		'MX_6': 'mx-6',
		'PREMACY': 'premacy',
		'RX_7': 'rx-7',
		'RX_8': 'rx-8',
		'TRIBUTE': 'tribute',
	},
	'MERCEDES_BENZ': {
		'190': '190',
		'200': '200',
		'220': '220',
		'230': '230',
		'240': '240',
		'250': '250',
		'260': '260',
		'280': '280',
		'300': '300',
		'500': '500',
		'A_CLASS': 'a-klasse',
		'B_CLASS': 'b-klasse',
		'C_CLASS': 'c-klasse',
		'CL_CLASS': 'cl-klasse',
		'CLA_CLASS': 'cla-klasse',
		'CLC_CLASS': 'clc-klasse',
		'CLK_CLASS': 'clk-klasse',
		'CLS_CLASS': 'cls-klasse',
		'E_CLASS': 'e-klasse',
		'EQA_CLASS': 'eqa',
		'EQB_CLASS': 'eqb',
		'EQC_CLASS': 'eqc',
		'EQE_CLASS': 'eqe',
		'G_CLASS': 'g-klasse',
		'GL_CLASS': 'gl-klasse',
		'GLA_CLASS': 'gla-klasse',
		'GLB_CLASS': 'glb-classe',
		'GLC_CLASS': 'glc-klasse',
		'GLE_CLASS': 'gle',
		'GLK_CLASS': 'glk-klasse',
		'GLS_CLASS': 'gls-classe-x166',
		'R_CLASS': 'r-klasse',
		'S_CLASS': 's-klasse',
		'SL_CLASS': 'sl-klasse',
		'SLC_CLASS': 'slc-klasse',
		'SLK_CLASS': 'slk-klasse',
		'SLR_CLASS': 'slr-mclaren',
		'SLS_CLASS': 'sls-amg',
		'T_CLASS': 't-mod',
		'VANEO': 'vaneo',
		'VIANO': 'viano',
		'X_CLASS': 'x-classe',
	},
	'MERCURY': {
		'MARAUDER': 'marauder',
		'MILAN': 'milan',
		'MOUNTAINEER': 'mountaineer',
		'VILLAGER': 'villager',
	},
	'MG': {
		'MGB': 'mgb',
		'MGF': 'mgf',
		'TF': 'tf',
		'ZR': 'zr',
		'ZS': 'zs',
		'ZT': 'zt',
	},
	'MICROCAR': {
	},
	'MINI': {
		'CLUBMAN': 'clubman',
		'COOPER': 'cooper',
		'COUNTRYMAN': 'countryman',
		'ONE': 'one',
		'PACEMAN': 'paceman',
	},
	'MITSUBISHI': {
		'3000_GT': '3000-gt',
		'ASX': 'asx',
		'CARISMA': 'carisma',
		'COLT': 'colt',
		'CORDIA': 'cordia',
		'ECLIPSE': 'eclipse',
		'ECLIPSE_CROSS': 'eclipse-cross',
		'GALANT': 'galant',
		'GRANDIS': 'grandis',
		'LANCER': 'lancer',
		'MIRAGE': 'mirage',
		'MONTERO': 'montero',
		'OUTLANDER': 'outlander',
		'PAJERO': 'pajero',
		'RVR': 'rvr',
		'SAPPORO': 'sapporo',
		'SIGMA': 'sigma',
		'SPACE_GEAR': 'space-gear',
		'SPACE_RUNNER': 'space-runner',
		'SPACE_STAR': 'space-star',
		'SPACE_WAGON': 'space-wagon',
		'STARION': 'starion',
		'TREDIA': 'tredia',
	},
	'MOSKVICH': {
		'2138': '2138',
		'2140': '2140',
		'2141': '2141',
		'401': '401',
		'403': '403',
		'407': '407',
		'408': '408',
		'412': '412',
		'427': '427',
	},
	'NISSAN': {
		'100_NX': '100-nx',
		'200_SX': '200-sx',
		'300_ZX': '300-zx',
		'350Z': '350z',
		'370Z': '370z',
		'ALMERA': 'almera',
		'ALTIMA': 'altima',
		'ARMADA': 'armada',
		'BLUEBIRD': 'bluebird',
		'CEDRIC': 'cedric',
		'CHERRY': 'cherry',
		'CUBE': 'cube',
		'FIGARO': 'figaro',
		'FRONTIER': 'frontier',
		'GT_R': 'gt-r',
		'JUKE': 'juke',
		'LAUREL': 'laurel',
		'MAXIMA': 'maxima',
		'MICRA': 'micra',
		'MURANO': 'murano',
		'NAVARA': 'navara',
		'NOTE': 'note',
		'PATHFINDER': 'pathfinder',
		'PATROL': 'patrol',
		'PRAIRIE': 'prairie',
		'PRIMERA': 'primera',
		'PULSAR': 'pulsar',
		'QASHQAI': 'qashqai',
		'QUEST': 'quest',
		'ROGUE': 'rogue',
		'SENTRA': 'sentra',
		'SERENA': 'serena',
		'SILVIA': 'silvia',
		'SKYLINE': 'skyline',
		'SUNNY': 'sunny',
		'TEANA': 'teana',
		'TERRANO': 'terrano',
		'TIIDA': 'tiida',
		'VERSA': 'versa',
		'X_TRAIL': 'x-trail',
	},
	'OPEL': {
		'ADAM': 'adam',
		'ADMIRAL': 'admiral',
		'AGILA': 'agila',
		'ANTARA': 'antara',
		'ASCONA': 'ascona',
		'ASTRA': 'astra',
		'CALIBRA': 'calibra',
		'CAMPO': 'campo',
		'COMBO': 'combo',
		'COMMODORE': 'commodore',
		'CORSA': 'corsa',
		'CROSSLAND_X': 'crossland-x',
		'DIPLOMAT': 'diplomat',
		'FRONTERA': 'frontera',
		'GRANDLAND_X': 'grandland-x',
		'GT': 'gt',
		'INSIGNIA': 'insignia',
		'KADETT': 'kadett',
		'MANTA': 'manta',
		'MERIVA': 'meriva',
		'MOKKA': 'mokka',
		'MONTEREY': 'monterey',
		'MONZA': 'monza',
		'OMEGA': 'omega',
		'REKORD': 'rekord',
		'SENATOR': 'senator',
		'SIGNUM': 'signum',
		'SINTRA': 'sintra',
		'SPEEDSTER': 'speedster',
		'TIGRA': 'tigra',
		'VECTRA': 'vectra',
		'ZAFIRA': 'zafira',
	},
	'PEUGEOT': {
		'1007': '1007',
		'104': '104',
		'106': '106',
		'107': '107',
		'2008': '2008',
		'204': '204',
		'205': '205',
		'206': '206',
		'207': '207',
		'208': '208',
		'3008': '3008',
		'301': '301',
		'304': '304',
		'305': '305',
		'306': '306',
		'307': '307',
		'308': '308',
		'309': '309',
		'4007': '4007',
		'4008': '4008',
		'405': '405',
		'406': '406',
		'407': '407',
		'5008': '5008',
		'504': '504',
		'505': '505',
		'508': '508',
		'604': '604',
		'605': '605',
		'607': '607',
		'806': '806',
		'807': '807',
		'BIPPER': 'bipper',
		'ION': 'ion',
		'PARTNER': 'partner',
		'RCZ': 'rcz',
		'RIFTER': 'rifter',
		'TRAVELER': 'traveler',
	},
	'PLYMOUTH': {
		'ACCLAIM': 'acclaim',
		'BREEZE': 'breeze',
		'GRAND_VOYAGER': 'grand-voyager',
		'LASER': 'laser',
		'NEON': 'neon',
		'PROWLER': 'prowler',
		'SUNDANCE': 'sundance',
		'VOYAGER': 'voyager',
	},
	'PONTIAC': {
		'AZTEC': 'aztec',
		'BONNEVILLE': 'bonneville',
		'FIERO': 'fiero',
		'FIREBIRD': 'firebird',
		'G6': 'g6',
		'GRAND_AM': 'grand-am',
		'GRAND_PRIX': 'grand-prix',
		'GTO': 'gto',
		'LEMANS': 'lemans',
		'SOLSTICE': 'solstice',
		'SUNBIRD': 'sunbird',
		'SUNFIRE': 'sunfire',
		'TORRENT': 'torrent',
		'TRANS_SPORT': 'trans-sport',
		'VIBE': 'vibe',
	},
	'PORSCHE': {
		'911': '911',
		'924': '924',
		'928': '928',
		'944': '944',
		'968': '968',
		'BOXSTER': 'boxster',
		'CAYENNE': 'cayenne',
		'CAYMAN': 'cayman',
		'MACAN': 'macan',
		'PANAMERA': 'panamera',
	},
	'PROTON': {
	},
	'RENAULT': {
		'11': '11',
		'12': '12',
		'14': '14',
		'16': '16',
		'18': '18',
		'19': '19',
		'20': '20',
		'21': '21',
		'25': '25',
		'30': '30',
		'4': '4',
		'5': '5',
		'9': '9',
		'ARKANA': 'arkana',
		'AVANTIME': 'avantime',
		'CAPTUR': 'captur',
		'CLIO': 'clio',
		'ESPACE': 'espace',
		'FLUENCE': 'fluence',
		'FUEGO': 'fuego',
		'KADJAR': 'kadjar',
		'KANGOO': 'kangoo',
		'KOLEOS': 'koleos',
		'LAGUNA': 'laguna',
		'LATITUDE': 'latitude',
		'MEGANE': 'megane',
		'MODUS': 'modus',
		'RAPID': 'rapid',
		'SAFRANE': 'safrane',
		'SCENIC': 'scenic',
		'SYMBOL': 'symbol',
		'TALISMAN': 'talisman',
		'TWINGO': 'twingo',
		'TWIZY': 'twizy',
		'VEL_SATIS': 'vel-satis',
		'WIND': 'wind',
		'ZOE': 'zoe',
	},
	'ROLLS_ROYCE': {
		'GHOST': 'ghost',
		'PHANTOM': 'phantom',
		'SILVER_SERAPH': 'silver-seraph',
		'SILVER_SPUR': 'silver-spur',
	},
	'ROVER': {
		'200': '200',
		'25': '25',
		'400': '400',
		'45': '45',
		'600': '600',
		'75': '75',
		'800': '800',
		'MAESTRO': 'maestro',
		'MONTEGO': 'montego',
	},
	'SAAB': {
		'9_3': '9-3',
		'9_5': '9-5',
		'9_7X': '9-7x',
		'900': '900',
		'9000': '9000',
	},
	'SATURN': {
		'ASTRA': 'astra',
		'AURA': 'aura',
		'OUTLOOK': 'outlook',
		'SKY': 'sky',
		'VUE': 'vue',
	},
	'SCION': {
		'TC': 'tc',
		'XA': 'xa',
		'XB': 'xb',
	},
	'SEAT': {
		'ALHAMBRA': 'alhambra',
		'ALTEA': 'altea',
		'ARONA': 'arona',
		'AROSA': 'arosa',
		'ATECA': 'ateca',
		'CORDOBA': 'cordoba',
		'EXEO': 'exeo',
		'FURA': 'fura',
		'IBIZA': 'ibiza',
		'LEON': 'leon',
		'MALAGA': 'malaga',
		'MARBELLA': 'marbella',
		'MII': 'mii',
		'RONDA': 'ronda',
		'TOLEDO': 'toledo',
	},
	'SKODA': {
		'100': '100',
		'130': '130',
		'CITIGO': 'citigo',
		'ENYAQ': 'enyaq',
		'FABIA': 'fabia',
		'FAVORIT': 'favorit',
		'FELICIA': 'felicia',
		'KAMIQ': 'kamiq',
		'KAROQ': 'karoq',
		'KODIAQ': 'kodiaq',
		'OCTAVIA': 'octavia',
		'RAPID': 'rapid',
		'ROOMSTER': 'roomster',
		'SCALA': 'skala',
		'SUPERB': 'superb',
		'YETI': 'yeti',
	},
	'SMART': {
		'FORFOUR': 'forfour',
		'FORTWO': 'fortwo',
		'ROADSTER': 'roadster',
	},
	'SSANGYONG': {
		'ACTYON': 'actyon',
		'KORANDO': 'korando',
		'KYRON': 'kyron',
		'MUSSO': 'musso',
		'REXTON': 'rexton',
	},
	'SUBARU': {
		'BAJA': 'baja',
		'BRZ': 'brz',
		'FORESTER': 'forester',
		'IMPREZA': 'impreza',
		'JUSTY': 'justy',
		'LEGACY': 'legacy',
		'LEONE': 'leone',
		'LEVORG': 'levorg',
		'LIBERO': 'libero',
		'OUTBACK': 'outback',
		'SVX': 'svx',
		'TREZIA': 'trezia',
		'VIVIO': 'vivio',
		'XT': 'xt',
		'XV': 'xv',
	},
	'SUZUKI': {
		'ALTO': 'alto',
		'BALENO': 'baleno',
		'FORENZA': 'forenza',
		'GRAND_VITARA': 'grand-vitara',
		'IGNIS': 'ignis',
		'JIMNY': 'jimny',
		'KIZASHI': 'kizashi',
		'LIANA': 'liana',
		'RENO': 'reno',
		'SAMURAI': 'samurai',
		'SIDEKICK': 'sidekick',
		'SPLASH': 'splash',
		'SWIFT': 'swift',
		'SX4': 'sx4',
		'VITARA': 'vitara',
		'WAGON_R': 'wagon-r',
		'X_90': 'x-90',
	},
	'TALBOT': {
		'HORIZON': 'horizon',
		'MURENA': 'murena',
		'SAMBA': 'samba',
		'SOLARA': 'solara',
	},
	'TESLA': {
		'MODEL_S': 'model-s',
		'ROADSTER': 'roadster',
	},
	'TOYOTA': {
		'4RUNNER': '4runner',
		'ALPHARD': 'alphard',
		'AURIS': 'auris',
		'AVALON': 'avalon',
		'AVENSIS': 'avensis',
		'AYGO': 'aygo',
		'CAMRY': 'camry',
		'CARINA': 'carina',
		'CELICA': 'celica',
		'COROLLA': 'corolla',
		'CRESSIDA': 'cressida',
		'CROWN': 'crown',
		'FJ_CRUISER': 'fj-cruiser',
		'HARRIER': 'harrier',
		'HIGHLANDER': 'highlander',
		'HILUX': 'hilux',
		'IQ': 'iq',
		'LAND_CRUISER': 'land-cruiser',
		'MATRIX': 'matrix',
		'PASEO': 'paseo',
		'PICNIC': 'picnic',
		'PREVIA': 'previa',
		'PRIUS': 'prius',
		'RAV4': 'rav-4',
		'SEQUOIA': 'sequoia',
		'SIENNA': 'sienna',
		'STARLET': 'starlet',
		'SUPRA': 'supra',
		'TACOMA': 'tacoma',
		'TERCEL': 'tercel',
		'TUNDRA': 'tundra',
		'URBAN_CRUISER': 'urban-cruiser',
		'VENZA': 'venza',
		'VERSO': 'verso',
		'YARIS': 'yaris',
	},
	'VW': {
		'ARTEON': 'arteon',
		'BORA': 'bora',
		'CADDY': 'caddy',
		'CORRADO': 'corrado',
		'DERBY': 'derby',
		'EOS': 'eos',
		'FOX': 'fox',
		'GOLF': 'golf',
		'ID_3': 'id-3',
		'ID_4': 'id-4',
		'ID_5': 'id-5',
		'ID_6': 'id-6',
		'JETTA': 'jetta',
		'LUPO': 'lupo',
		'MULTIVAN': 'multivan',
		'NEW_BEETLE': 'new-beetle',
		'PASSAT': 'passat',
		'PHAETON': 'phaeton',
		'POLO': 'polo',
		'SANTANA': 'santana',
		'SCIROCCO': 'scirocco',
		'SHARAN': 'sharan',
		'T_CROSS': 't-cross',
		'T_ROC': 't-roc',
		'TAIGO': 'taigo',
		'TARO': 'taro',
		'TIGUAN': 'tiguan',
		'TOUAREG': 'touareg',
		'TOURAN': 'touran',
		'UP': 'up',
		'VENTO': 'vento',
	},
	'VOLVO': {
		'164': '164',
		'240': '240',
		'66': '66',
		'740': '740',
		'760': '760',
		'850': '850',
		'940': '940',
		'960': '960',
		'C30': 'c30',
		'C70': 'c70',
		'S40': 's40',
		'S60': 's60',
		'S70': 's70',
		'S80': 's80',
		'S90': 's90',
		'V40': 'v40',
		'V50': 'v50',
		'V60': 'v60',
		'V70': 'v70',
		'V90': 'v90',
		'XC40': 'xc40',
		'XC60': 'xc60',
		'XC70': 'xc70',
		'XC90': 'xc90',
	},
}

export const DATA_GENERATIONS = {
	'ACURA': {
		'ILX': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"ilx"}',
		],
		'MDX': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"mdx"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"mdx-ii"}',
		],
		'NSX': [
			'{"prodYearStart":1990,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"nsx"}',
			'{"prodYearStart":1995,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"nsx-t"}',
		],
		'RDX': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"rdx"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"rdx-i-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"rdx-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"rdx-iii"}',
		],
		'RL': [
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"rl-ii"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"rl-ka964"}',
		],
		'RSX': [
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[9],"folderName":"rsx-i"}',
			'{"prodYearStart":1989,"prodYearEnd":1993,"coupeTypes":[9,7],"folderName":"rsx-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"rsx-iv"}',
		],
		'SLX': [
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[3],"folderName":"slx"}',
		],
		'TL': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"tl-2013"}',
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"tl-i-ua2"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"tl-ii-ua5"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"tl-iii-ua67"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[7],"folderName":"tl-iv-ua89"}',
		],
		'TSX': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[7],"folderName":"tsx-facelift"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"tsx-i-cl9"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[7],"folderName":"tsx-ii-cu2"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[3],"folderName":"tsx-sport-wagon"}',
		],
		'ZDX': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[1],"folderName":"zdx"}',
		],
	},
	'ALFA_ROMEO': {
		'145': [
			'{"prodYearStart":199,"prodYearEnd":2002,"coupeTypes":[9,10],"folderName":"145-930"}',
		],
		'146': [
			'{"prodYearStart":0,"prodYearEnd":2001,"coupeTypes":[9,10],"folderName":"146-930"}',
		],
		'147': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"147-3-doors"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"147-3-doors-face"}',
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"147-5-doors"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"147-5-doors-face"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[9],"folderName":"147-gta"}',
		],
		'155': [
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[7,10],"folderName":"155-167"}',
		],
		'156': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"156-932"}',
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"156-crosswagon"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"156-gta"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"156-gta-sport-wagon"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[7,10],"folderName":"156-ii"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[3],"folderName":"156-sport-wagon"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[3,10],"folderName":"156-sport-wagon-ii"}',
		],
		'159': [
			'{"prodYearStart":2005,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"159"}',
			'{"prodYearStart":2005,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"159-sportwagon"}',
		],
		'164': [
			'{"prodYearStart":1987,"prodYearEnd":1998,"coupeTypes":[7,10],"folderName":"164-164"}',
		],
		'166': [
			'{"prodYearStart":1998,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"166-936"}',
		],
		'33': [
			'{"prodYearStart":1983,"prodYearEnd":1990,"coupeTypes":[10,9],"folderName":"33-905"}',
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[9,10],"folderName":"33-907a"}',
			'{"prodYearStart":1984,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"33-sport-wagon-905a"}',
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[3,10],"folderName":"33-sport-wagon-907b"}',
		],
		'4C': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[4],"folderName":"4c-type-960"}',
		],
		'75': [
			'{"prodYearStart":1985,"prodYearEnd":1992,"coupeTypes":[7],"folderName":"75-162b"}',
		],
		'8C_COMPETIZIONE': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[4],"folderName":"8c-competizione"}',
		],
		'90': [
			'{"prodYearStart":1984,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"90-162"}',
		],
		'ALFETTA': [
			'{"prodYearStart":1974,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"alfetta-116"}',
			'{"prodYearStart":1974,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"alfetta-gt-116"}',
		],
		'BRERA': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[4],"folderName":"brera"}',
		],
		'GIULIA': [
			'{"prodYearStart":1964,"prodYearEnd":1978,"coupeTypes":[7],"folderName":"giulia"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"giulia-ii"}',
		],
		'GIULIETTA': [
			'{"prodYearStart":1977,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"giulietta-116"}',
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"giulietta-type-940"}',
		],
		'GT': [
			'{"prodYearStart":1963,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"gt"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[4],"folderName":"gt-coupe"}',
		],
		'GTV': [
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"gtv-116"}',
			'{"prodYearStart":1995,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"gtv-916"}',
		],
		'MITO': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"mito"}',
		],
		'SPIDER': [
			'{"prodYearStart":1966,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"spider-105"}',
			'{"prodYearStart":1976,"prodYearEnd":1993,"coupeTypes":[2,10],"folderName":"spider-115"}',
			'{"prodYearStart":1995,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"spider-916"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"spider-premium"}',
		],
		'STELVIO': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"stelvio"}',
		],
	},
	'ALPINA': {
		'B10': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[10],"folderName":"b10-e34"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"b10-e39"}',
			'{"prodYearStart":1993,"prodYearEnd":1997,"coupeTypes":[3],"folderName":"b10-touring-e34"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"b10-touring-e39"}',
		],
		'B11': [
			'{"prodYearStart":1987,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"b11-e32"}',
		],
		'B12': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"b12-coupe-e31"}',
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[7],"folderName":"b12-e32"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"b12-e38"}',
		],
		'B3': [
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"b3-cabrio-e30"}',
			'{"prodYearStart":1994,"prodYearEnd":1996,"coupeTypes":[2],"folderName":"b3-cabrio-e36"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"b3-cabrio-e46"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[2],"folderName":"b3-cabrio-e93"}',
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"b3-coupe-e36"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"b3-coupe-e46"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[4],"folderName":"b3-coupe-e92"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"b3-e30"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"b3-e36"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"b3-e46"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"b3-e90"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"b3-f30"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"b3-touring-e30"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"b3-touring-e36"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"b3-touring-e46"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[3],"folderName":"b3-touring-f31"}',
		],
		'B5': [
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"b5-e60"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[7],"folderName":"b5-sedan-f10"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[3],"folderName":"b5-touring-e61"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[3],"folderName":"b5-touring-f11"}',
		],
		'B6': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[2],"folderName":"b6-cabrio-e64"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2],"folderName":"b6-cabrio-f13"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"b6-coupe-e63"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[4],"folderName":"b6-coupe-f12"}',
			'{"prodYearStart":1978,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"b6-e21"}',
			'{"prodYearStart":1983,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"b6-e30"}',
			'{"prodYearStart":1992,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"b6-e36"}',
		],
		'B7': [
			'{"prodYearStart":1978,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"b7-coupe-e24"}',
			'{"prodYearStart":1978,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"b7-e12"}',
			'{"prodYearStart":1984,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"b7-e28"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"b7-e65e66l"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"b7-sedan-f01"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"b7-sedan-lwb-f01"}',
		],
		'B8': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"b8-cabrio-e36"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[4],"folderName":"b8-coupe-e36"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"b8-e36"}',
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"b8-touring-e36"}',
		],
		'B9': [
			'{"prodYearStart":1982,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"b9-coupe-e24"}',
			'{"prodYearStart":1981,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"b9-e28"}',
		],
		'C1': [
			'{"prodYearStart":1980,"prodYearEnd":1983,"coupeTypes":[7],"folderName":"c1-e21"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[7],"folderName":"c1-e30"}',
		],
		'C2': [
			'{"prodYearStart":1986,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"c2-cabrio-e30"}',
			'{"prodYearStart":1985,"prodYearEnd":1988,"coupeTypes":[4],"folderName":"c2-e30"}',
		],
		'D3': [
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"d3-e90"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"d3-touring-e91"}',
		],
		'D5': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[7],"folderName":"d5-sedan-f10"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[3],"folderName":"d5-touring-f11"}',
		],
		'XD3': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[1],"folderName":"xd3-f25"}',
		],
	},
	'ASTON_MARTIN': {
		'DB7': [
			'{"prodYearStart":2003,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"db7-gt"}',
			'{"prodYearStart":1999,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"db7-vantage"}',
			'{"prodYearStart":1999,"prodYearEnd":1999,"coupeTypes":[2],"folderName":"db7-volante"}',
			'{"prodYearStart":2002,"prodYearEnd":2003,"coupeTypes":[10],"folderName":"db7-zagato"}',
		],
		'DB9': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"db9-coupe"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[10],"folderName":"db9-restyling-cabriolet"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"db9-restyling-cupe"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[2],"folderName":"db9-restyling-ii-cabriolet"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[4],"folderName":"db9-restyling-ii-cupe"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"db9-volante"}',
		],
		'DBS': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"dbs"}',
		],
		'DBX': [
			'{"prodYearStart":2020,"prodYearEnd":null,"coupeTypes":[1],"folderName":"dbx-1"}',
		],
		'RAPIDE': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[7],"folderName":"rapide"}',
		],
		'V12_VANTAGE': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[4],"folderName":"v12-vantage"}',
		],
	},
	'AUDI': {
		'100': [
			'{"prodYearStart":1976,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"100-43"}',
			'{"prodYearStart":1982,"prodYearEnd":1994,"coupeTypes":[7],"folderName":"100-4444q"}',
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[7,10],"folderName":"100-4ac4"}',
			'{"prodYearStart":1977,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"100-avant-43"}',
			'{"prodYearStart":1982,"prodYearEnd":1990,"coupeTypes":[3],"folderName":"100-avant-4444q"}',
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[3,7,10],"folderName":"100-avant-4ac4"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"100-coupe"}',
			'{"prodYearStart":1968,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"100-i"}',
		],
		'200': [
			'{"prodYearStart":1979,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"200-43"}',
			'{"prodYearStart":1983,"prodYearEnd":1991,"coupeTypes":[7],"folderName":"200-4444q"}',
			'{"prodYearStart":1983,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"200-avant-4444q"}',
		],
		'50': [
			'{"prodYearStart":1974,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"50-86"}',
		],
		'80': [
			'{"prodYearStart":1972,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"80-i"}',
			'{"prodYearStart":1976,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"80-ii-82"}',
			'{"prodYearStart":1978,"prodYearEnd":1986,"coupeTypes":[7],"folderName":"80-iii-8185"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[7],"folderName":"80-iv-8989q8a"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[7,10],"folderName":"80-v-8cb4"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[3,10],"folderName":"80-v-avant-8cb4"}',
		],
		'90': [
			'{"prodYearStart":1984,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"90-8185"}',
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[7,10],"folderName":"90-8989q8a"}',
		],
		'A1': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"a1"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[9],"folderName":"a1-ii"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"a1-restyling"}',
		],
		'A2': [
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"a2-8z"}',
		],
		'A3': [
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"a3-8l"}',
			'{"prodYearStart":2004,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"a3-8p"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"a3-8v"}',
			'{"prodYearStart":2008,"prodYearEnd":20013,"coupeTypes":[2],"folderName":"a3-cabriolet"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"a3-iii-8v-sportback-restyling"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"a3-iv-sportback"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"a3-sedan-8v"}',
			'{"prodYearStart":2004,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"a3-sportback-8p"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"a3-sportback-8v"}',
		],
		'A4': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"a4-8db5"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"a4-8e"}',
			'{"prodYearStart":2009,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"a4-allroad"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[3,10],"folderName":"a4-avant-8db5"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"a4-avant-8e"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"a4-avant-b8"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"a4-b8"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"a4-cabriolet-8hqb6"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"a4-v-b9-avant"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"a4-v-b9-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[7],"folderName":"a4-v-b9-sedan"}',
		],
		'A5': [
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"a5-8t3"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[2,4],"folderName":"a5-cabriolet-8f7"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"a5-ii-f5-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"a5-ii-sportback"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"a5-liftback-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[4],"folderName":"a5-restyling"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"a5-sportback-8ta"}',
		],
		'A6': [
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[7,10],"folderName":"a6-4ac4"}',
			'{"prodYearStart":1997,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"a6-4bc5"}',
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"a6-4fc6"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[3],"folderName":"a6-allroad-quattro-4g-c7"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[3,10],"folderName":"a6-avant-4ac4"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"a6-avant-4bc5"}',
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"a6-avant-4fc6"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[3],"folderName":"a6-avant-4g-c7"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"a6-c7-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"a6-limousine-4g-c7"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[7],"folderName":"a6-v-c8"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[3],"folderName":"a6-v-c8-avant"}',
		],
		'A7': [
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"a7-4g-restyling"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"a7-sportback-4g"}',
		],
		'A8': [
			'{"prodYearStart":1989,"prodYearEnd":2002,"coupeTypes":[7,10],"folderName":"a8-d24d"}',
			'{"prodYearStart":2002,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"a8-d34e"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"a8-d4"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"a8-d4-long"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"a8-d4-long-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"a8-d4-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"a8-iv-d5"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"a8-long-4e"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"a8-long-d2"}',
		],
		'ALLROAD': [
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"allroad-4bc5"}',
		],
		'CABRIOLET': [
			'{"prodYearStart":1991,"prodYearEnd":2001,"coupeTypes":[2,10],"folderName":"cabriolet-89"}',
		],
		'COUPE': [
			'{"prodYearStart":1980,"prodYearEnd":1988,"coupeTypes":[10,4],"folderName":"coupe-8185"}',
			'{"prodYearStart":1988,"prodYearEnd":1996,"coupeTypes":[10,4],"folderName":"coupe-898b"}',
		],
		'Q2': [
			'{"prodYearStart":2016,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"q2-i"}',
		],
		'Q3': [
			'{"prodYearStart":201,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"q3"}',
		],
		'Q5': [
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"q5"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"q5-8r-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"q5-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10,1],"folderName":"q5-ii-fy-restyling"}',
		],
		'Q7': [
			'{"prodYearStart":2006,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"q7"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"q7-ii"}',
		],
		'Q8': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"q8"}',
		],
		'QUATTRO': [
			'{"prodYearStart":1980,"prodYearEnd":1991,"coupeTypes":[4],"folderName":"quattro-85"}',
		],
		'R8': [
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[4],"folderName":"r8"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"r8-coupe-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"r8-ii-coupe"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[2],"folderName":"r8-ii-roadster"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"r8-roadster-restyling"}',
		],
		'RS3': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"rs3-sportback-8p"}',
		],
		'RS4': [
			'{"prodYearStart":1999,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"rs4-avant"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"rs4-avant-8e"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"rs4-b8"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"rs4-cabrio-8e"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"rs4-salon-8e"}',
		],
		'RS5': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[4],"folderName":"rs5-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[4],"folderName":"rs5-typ-8t"}',
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[2],"folderName":"rs5-typ-8t-cabriolet"}',
		],
		'RS6': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"rs6-4bc5"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"rs6-4fc6"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"rs6-avant-4bc5"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[3],"folderName":"rs6-avant-4fc6"}',
			'{"prodYearStart":2013,"prodYearEnd":2014,"coupeTypes":[3],"folderName":"rs6-c7"}',
		],
		'RS7': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[7],"folderName":"rs-7-sportback-4g"}',
		],
		'S2': [
			'{"prodYearStart":1992,"prodYearEnd":1995,"coupeTypes":[3],"folderName":"s2-avant"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"s2-coupe"}',
		],
		'S3': [
			'{"prodYearStart":1999,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"s3-8l"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[9],"folderName":"s3-8p"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s3-iii-8v-sedan"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[9],"folderName":"s3-sportback-8p"}',
		],
		'S4': [
			'{"prodYearStart":1991,"prodYearEnd":1994,"coupeTypes":[7,10],"folderName":"s4-4ac4"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"s4-8db5"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"s4-8e"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"s4-avant-8db5"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[3],"folderName":"s4-avant-8e"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s4-b8"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[2],"folderName":"s4-cabriolet"}',
		],
		'S5': [
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"s5"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"s5-cabriolet-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"s5-liftback-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"s5-restyling"}',
		],
		'S6': [
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"s6-4ac4"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"s6-4bc5"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"s6-4fc6"}',
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[3],"folderName":"s6-avant-4ac4"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"s6-avant-4bc5"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"s6-avant-4fc6"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s6-c7-restyling"}',
		],
		'S7': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s7-sportback-4g"}',
		],
		'S8': [
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"s8-4e"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"s8-d2"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s8-iii-d4-restyling"}',
		],
		'TT': [
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"tt-8n"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[4],"folderName":"tt-pq35pq46"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"tt-roadster-8n"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2],"folderName":"tt-roadster-pq3536"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"tt-rs-coupe"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[2],"folderName":"tt-rs-roadster"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"tts"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[2],"folderName":"tts-roadster"}',
		],
	},
	'BENTLEY': {
		'ARNAGE': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[7],"folderName":"arnage-i"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[7],"folderName":"arnage-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"arnage-r"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"arnage-t"}',
		],
		'AZURE': [
			'{"prodYearStart":1995,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"azure"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[2],"folderName":"azure-ii"}',
		],
		'BENTAYGA': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[1],"folderName":"bentayga"}',
		],
		'CONTINENTAL': [
			'{"prodYearStart":1984,"prodYearEnd":1995,"coupeTypes":[2],"folderName":"continental-1984"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7,10],"folderName":"continental-flying-sp"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[4],"folderName":"continental-gt"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[4],"folderName":"continental-gt-speed"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"continental-gtc"}',
			'{"prodYearStart":1991,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"continental-r"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"continental-t"}',
		],
		'MULSANNE': [
			'{"prodYearStart":1984,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"mulsanne"}',
		],
	},
	'BERTONE': {
		'FREECLIMBER': [
			'{"prodYearStart":1989,"prodYearEnd":1993,"coupeTypes":[3,10],"folderName":"freeclimber"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[10],"folderName":"freeclimber-2"}',
		],
	},
	'BMW': {
		'1_ER': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[2],"folderName":"1er-cabrio-e88"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"1er-coupe-e82"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"1er-e81"}',
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"1er-e87"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"1er-hatchback-f20-5-dr"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"1er-hatchback-f20-f21-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"1er-hatchback-f21-3-dr"}',
			'{"prodYearStart":2017,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"1er-ii-f20-f21"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"1er-iii-f40"}',
		],
		'2_ER': [
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[4],"folderName":"2er"}',
			'{"prodYearStart":2017,"prodYearEnd":null,"coupeTypes":[4],"folderName":"2er-f22-restyling"}',
		],
		'3_ER': [
			'{"prodYearStart":1985,"prodYearEnd":1993,"coupeTypes":[2,10],"folderName":"3er-cabrio-e30"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[2,10],"folderName":"3er-cabrio-e36"}',
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"3er-cabrio-e46"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[2],"folderName":"3er-cabrio-e93"}',
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"3er-compact-e36"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"3er-compact-e46"}',
			'{"prodYearStart":1992,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"3er-coupe-e36"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"3er-coupe-e46"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[4],"folderName":"3er-coupe-e92"}',
			'{"prodYearStart":1975,"prodYearEnd":1984,"coupeTypes":[4],"folderName":"3er-e21"}',
			'{"prodYearStart":1982,"prodYearEnd":1991,"coupeTypes":[10,7],"folderName":"3er-e30"}',
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[7,4],"folderName":"3er-e36"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"3er-e46"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"3er-e90"}',
			'{"prodYearStart":2018,"prodYearEnd":2022,"coupeTypes":[7],"folderName":"3er-g20"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"3er-gran-turismo-f34"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"3er-sedan-f30"}',
			'{"prodYearStart":1987,"prodYearEnd":1994,"coupeTypes":[10,3],"folderName":"3er-touring-e30"}',
			'{"prodYearStart":1995,"prodYearEnd":1999,"coupeTypes":[10,3],"folderName":"3er-touring-e36"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"3er-touring-e46"}',
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"3er-touring-e91"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"3er-touring-f31"}',
			'{"prodYearStart":2022,"prodYearEnd":0,"coupeTypes":[7],"folderName":"3er-vii-g2x-restyling"}',
		],
		'4_ER': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[2],"folderName":"4er-convertible"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[4],"folderName":"4er-coupe"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"4er-f32"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"4er-gran-coupe"}',
		],
		'5_ER': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[7],"folderName":"5er-active-hibrid"}',
			'{"prodYearStart":1972,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"5er-e12"}',
			'{"prodYearStart":1981,"prodYearEnd":1987,"coupeTypes":[10,7],"folderName":"5er-e28"}',
			'{"prodYearStart":1986,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"5er-e34"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"5er-e39"}',
			'{"prodYearStart":2003,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"5er-e60"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"5er-g30"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[3],"folderName":"5er-g30-touring"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"5er-gran-turismo-f07"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"5er-sedan-f10"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[10,3],"folderName":"5er-touring-e34"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[3,10],"folderName":"5er-touring-e39"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"5er-touring-e61"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"5er-touring-f11"}',
		],
		'6_ER': [
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"6er-convertible-e64"}',
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"6er-convertible-f13"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"6er-coupe-f12"}',
			'{"prodYearStart":1976,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"6er-e24"}',
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[4],"folderName":"6er-e63"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"6er-gran-coupe-f12"}',
		],
		'7_ER': [
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"7er-e23"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[7,10],"folderName":"7er-e32"}',
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[7,10],"folderName":"7er-e38"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"7er-e65e66-l"}',
			'{"prodYearStart":2008,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"7er-f01"}',
			'{"prodYearStart":2015,"prodYearEnd":2029,"coupeTypes":[7],"folderName":"7er-vi-g11-g12"}',
		],
		'8_ER': [
			'{"prodYearStart":1989,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"8er-e31"}',
		],
		'I3': [
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"i3"}',
		],
		'I4': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"i4"}',
		],
		'I7': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"i7"}',
		],
		'I8': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"i8-restyling"}',
		],
		'M3': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"bmw-v-f80"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10,2],"folderName":"m3-cabrio-e30"}',
			'{"prodYearStart":1992,"prodYearEnd":1999,"coupeTypes":[2],"folderName":"m3-cabrio-e36"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"m3-cabrio-e46"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[2],"folderName":"m3-cabrio-e92"}',
			'{"prodYearStart":1992,"prodYearEnd":1995,"coupeTypes":[10,4],"folderName":"m3-coupe-e36"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"m3-coupe-e46"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"m3-coupe-e92"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[4,10],"folderName":"m3-e30"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"m3-e90"}',
		],
		'M5': [
			'{"prodYearStart":1972,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"m5-e12"}',
			'{"prodYearStart":1985,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"m5-e28"}',
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"m5-e34"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"m5-e39"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"m5-e60"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[7],"folderName":"m5-f10"}',
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[3],"folderName":"m5-touring-e34"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[3],"folderName":"m5-touring-e61"}',
		],
		'M6': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"m6-cabrio-e63"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[2],"folderName":"m6-cabrio-f13"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[4],"folderName":"m6-coupe-f12"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[4],"folderName":"m6-e63"}',
		],
		'X1': [
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"x1-i-e84"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"x1-i-e84-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"x1-ii-f48"}',
		],
		'X2': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x2"}',
		],
		'X3': [
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"x3-e83"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"x3-f25"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"x3-f25-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x3-g01"}',
		],
		'X4': [
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"x4"}',
			'{"prodYearStart":2018,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"x4-ii-g02"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"x4-ii-g02-restyling"}',
		],
		'X5': [
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"x5-e53"}',
			'{"prodYearStart":2006,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"x5-e70"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"x5-e70-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"x5-iii-f15"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x5-iv-g05"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x5-m-e70"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x5-m-ii-f85"}',
		],
		'X6': [
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"x6-e71-e72"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"x6-ii-f16"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"x6-iii-g06"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"x6-m-e71-e72"}',
		],
		'Z1': [
			'{"prodYearStart":1988,"prodYearEnd":1991,"coupeTypes":[2],"folderName":"z1-e30"}',
		],
		'Z3': [
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"z3-coupe-e367"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[2,4],"folderName":"z3-e367"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[4],"folderName":"z3-m-coupe-e367"}',
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[2],"folderName":"z3-m-e367"}',
		],
		'Z4': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[4],"folderName":"z4-coupe-e85"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"z4-e85"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[2],"folderName":"z4-e89"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[4],"folderName":"z4-m-coupe-2009"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"z4-m-coupe-e85"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"z4-m-e85"}',
		],
		'Z8': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"z8-e52"}',
		],
	},
	'BUGATTI': {
		'CHIRON': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[10],"folderName":"chiron"}',
		],
		'VEYRON': [
			'{"prodYearStart":2005,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"veyron-eb-164"}',
		],
	},
	'BYD': {
	},
	'CADILLAC': {
		'ALLANTE': [
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[2],"folderName":"allante"}',
		],
		'BLS': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"bls"}',
		],
		'BROUGHAM': [
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"brougham"}',
		],
		'CTS': [
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"cts"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"cts-coupe"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"cts-ii"}',
		],
		'DTS': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"dts"}',
		],
		'ELDORADO': [
			'{"prodYearStart":1991,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"eldorado"}',
		],
		'ESCALADE': [
			'{"prodYearStart":1998,"prodYearEnd":2000,"coupeTypes":[1],"folderName":"escalade-i"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"escalade-ii"}',
			'{"prodYearStart":0,"prodYearEnd":2014,"coupeTypes":[10,1],"folderName":"escalade-iii"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[1],"folderName":"escalade-iv"}',
			'{"prodYearStart":2001,"prodYearEnd":2014,"coupeTypes":[6],"folderName":"escalade-pick-up"}',
		],
		'FLEETWOOD': [
			'{"prodYearStart":1950,"prodYearEnd":0,"coupeTypes":[10],"folderName":"fleetwood"}',
		],
		'SEVILLE': [
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[7],"folderName":"seville"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"seville-v"}',
		],
		'SRX': [
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"srx"}',
		],
		'STS': [
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"sts"}',
		],
		'XLR': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[4],"folderName":"xlr"}',
		],
	},
	'CHEVROLET': {
		'ALERO': [
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"alero-gm-p90"}',
		],
		'ASTRO': [
			'{"prodYearStart":1985,"prodYearEnd":1999,"coupeTypes":[5],"folderName":"astro"}',
		],
		'AVALANCHE': [
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[6],"folderName":"avalanche"}',
		],
		'AVEO': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"aveo-hatchback"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"aveo-ii-hatchback"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"aveo-ii-sedan"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"aveo-sedan"}',
		],
		'BERETTA': [
			'{"prodYearStart":1987,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"beretta"}',
		],
		'BLAZER': [
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[10,1],"folderName":"blazer-i"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[1],"folderName":"blazer-ii"}',
		],
		'CAMARO': [
			'{"prodYearStart":1993,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"camaro-convertible-iv"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[2],"folderName":"camaro-convertible-v"}',
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"camaro-iv"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"camaro-v"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[4],"folderName":"camaro-vi"}',
		],
		'CAPRICE': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"caprice"}',
			'{"prodYearStart":1970,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"caprice-70"}',
			'{"prodYearStart":1983,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"caprice-83"}',
			'{"prodYearStart":1990,"prodYearEnd":0,"coupeTypes":[3],"folderName":"caprice-station-wagon"}',
		],
		'CAPTIVA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"captiva"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[1],"folderName":"captiva-ii"}',
		],
		'CAVALIER': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"cavalier-convertible-iii-j"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"cavalier-coupe-iii-j"}',
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"cavalier-i"}',
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"cavalier-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"cavalier-iii-j"}',
		],
		'COBALT': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"cobalt"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[4],"folderName":"cobalt-coupe"}',
		],
		'COLORADO': [
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[6],"folderName":"colorado"}',
		],
		'CORVETTE': [
			'{"prodYearStart":2013,"prodYearEnd":2019,"coupeTypes":[2],"folderName":"corvette-cabriolet-c7"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[2],"folderName":"corvette-cabriolet-c8"}',
			'{"prodYearStart":1984,"prodYearEnd":1998,"coupeTypes":[2],"folderName":"corvette-convertible-iv"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"corvette-convertible-yy"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2,10],"folderName":"corvette-convertible-z06c6"}',
			'{"prodYearStart":2013,"prodYearEnd":2019,"coupeTypes":[4],"folderName":"corvette-coupe-c7"}',
			'{"prodYearStart":1984,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"corvette-coupe-iv"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"corvette-coupe-yy"}',
			'{"prodYearStart":2004,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"corvette-coupe-z06c6"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"corvette-hardtop-yy"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[4],"folderName":"corvette-targa-c8"}',
		],
		'CRUZE': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"cruze-combi"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"cruze-hatchback"}',
			'{"prodYearStart":2008,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"cruze-sedan"}',
		],
		'EPICA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"epica"}',
		],
		'EQUINOX': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[1],"folderName":"equinox"}',
		],
		'EVANDA': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"evanda"}',
		],
		'HHR': [
			'{"prodYearStart":2005,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"hhr"}',
		],
		'IMPALA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"impala"}',
			'{"prodYearStart":1994,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"impala-vi"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"impala-w"}',
		],
		'LACETTI': [
			'{"prodYearStart":2004,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"lacetti-hatchback"}',
			'{"prodYearStart":2004,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"lacetti-sedan"}',
			'{"prodYearStart":2004,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"lacetti-wagon"}',
		],
		'LUMINA': [
			'{"prodYearStart":1989,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"lumina"}',
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[5],"folderName":"lumina-apv"}',
		],
		'MALIBU': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[9],"folderName":"malibu-maxx"}',
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"malibu-v-gm90"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"malibu-vi-e"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"malibu-vii"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"malibu-viii"}',
		],
		'NIVA': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[1],"folderName":"niva"}',
		],
		'NUBIRA': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"nubira"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[3],"folderName":"nubira-station-wagon"}',
		],
		'ORLANDO': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[0,5],"folderName":"orlando"}',
		],
		'REZZO': [
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"rezzo"}',
		],
		'SILVERADO': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[6],"folderName":"silverado-i"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[6],"folderName":"silverado-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[6],"folderName":"silverado-iii"}',
		],
		'SPARK': [
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"spark"}',
		],
		'SSR': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[6],"folderName":"ssr"}',
		],
		'SUBURBAN': [
			'{"prodYearStart":1973,"prodYearEnd":1991,"coupeTypes":[1],"folderName":"suburban-ck"}',
			'{"prodYearStart":1991,"prodYearEnd":null,"coupeTypes":[1],"folderName":"suburban-gmt400"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"suburban-gmt800"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"suburban-gmt900"}',
		],
		'TAHOE': [
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[1],"folderName":"tahoe-gmt410"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"tahoe-gmt840"}',
			'{"prodYearStart":2006,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"tahoe-gmt900"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"tahoe-iv"}',
		],
		'TRACKER': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[1],"folderName":"tracker"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"tracker-convertibe"}',
		],
		'TRAILBLAZER': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"trailblazer-gmt800"}',
		],
		'TRAX': [
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"trax"}',
		],
	},
	'CHRYSLER': {
		'300C': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"300c"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"300c-touring"}',
		],
		'300M': [
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"300m"}',
		],
		'CROSSFIRE': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[4],"folderName":"crossfire"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[2],"folderName":"crossfire-roadster"}',
		],
		'NEON': [
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"neon"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"neon-ii"}',
		],
		'NEW_YORKER': [
			'{"prodYearStart":1994,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"new-yorker"}',
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"new-yorker-fifth-avenue"}',
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"new-yorker-x-salon"}',
		],
		'PACIFICA': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"pacifica"}',
		],
		'PT_CRUISER': [
			'{"prodYearStart":2000,"prodYearEnd":2010,"coupeTypes":[3,5],"folderName":"pt-cruiser"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[2,10],"folderName":"pt-cruiser-cabrio"}',
		],
		'SARATOGA': [
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"saratoga"}',
		],
		'SEBRING': [
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[7],"folderName":"sebring"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"sebring-convertible"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[2],"folderName":"sebring-convertible-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[2],"folderName":"sebring-convertible-iii"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"sebring-coupe"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"sebring-coupe-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"sebring-ii"}',
		],
		'STRATUS': [
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"stratus-cabrio-jx"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"stratus-ja"}',
		],
		'VISION': [
			'{"prodYearStart":1993,"prodYearEnd":0,"coupeTypes":[7],"folderName":"vision"}',
		],
		'VOYAGER': [
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"voyager-5-restyling"}',
			'{"prodYearStart":1984,"prodYearEnd":1995,"coupeTypes":[5,10],"folderName":"voyager-i-es"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"voyager-ii-gs"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"voyager-iv"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[5],"folderName":"voyager-v"}',
		],
	},
	'CITROEN': {
		'AX': [
			'{"prodYearStart":1986,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"ax-za-_"}',
		],
		'BERLINGO': [
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"berlingo"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[5],"folderName":"berlingo-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"berlingo-ii-phase-ii"}',
		],
		'BX': [
			'{"prodYearStart":1985,"prodYearEnd":1994,"coupeTypes":[10,3],"folderName":"bx-break-xb-_"}',
			'{"prodYearStart":1982,"prodYearEnd":1993,"coupeTypes":[10,9],"folderName":"bx-xb-_"}',
		],
		'C_CROSSER': [
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"c-crosser"}',
		],
		'C_ELYSEE': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[7],"folderName":"c-elysee-ii"}',
		],
		'C1': [
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"c1"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"c1-facelift-2012"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[9],"folderName":"c1-ii"}',
		],
		'C2': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"c2"}',
		],
		'C3': [
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"c3-ii-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"c3-iii"}',
			'{"prodYearStart":2002,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"c3-mk-i"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"c3-mk-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[5],"folderName":"c3-picaso-restyling"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"c3-picasso"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[2],"folderName":"c3-pluriel"}',
		],
		'C4': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[1],"folderName":"c4-aircross"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"c4-coupe"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"c4-grand-picasso"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"c4-hatchback"}',
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"c4-ii-hatchback"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"c4-ii-l-sedan"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"c4-ii-picasso"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"c4-l-sedan"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[9,0,5],"folderName":"c4-picasso"}',
		],
		'C4_AIRCROSS': [
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"c4-aircross"}',
		],
		'C4_CACTUS': [
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"c4-cactus"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10,1],"folderName":"c4-cactus-restyling"}',
		],
		'C5': [
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"c5-i"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"c5-i-break"}',
			'{"prodYearStart":2007,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"c5-ii"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[3],"folderName":"c5-ii-tourer"}',
		],
		'C5_AIRCROSS': [
			'{"prodYearStart":2018,"prodYearEnd":2022,"coupeTypes":[1],"folderName":"c5-aircross"}',
			'{"prodYearStart":2022,"prodYearEnd":0,"coupeTypes":[1],"folderName":"c5-aircross-restyling"}',
		],
		'C5X': [
			'{"prodYearStart":2022,"prodYearEnd":0,"coupeTypes":[7],"folderName":"c5x"}',
		],
		'C6': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10,7],"folderName":"c6"}',
		],
		'C8': [
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"c8"}',
		],
		'CX': [
			'{"prodYearStart":1974,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"cx-i"}',
			'{"prodYearStart":1975,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"cx-i-break"}',
			'{"prodYearStart":1983,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"cx-ii"}',
			'{"prodYearStart":1983,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"cx-ii-break"}',
		],
		'DS': [
			'{"prodYearStart":1959,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"ds"}',
			'{"prodYearStart":1972,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"ds-break"}',
			'{"prodYearStart":1958,"prodYearEnd":1972,"coupeTypes":[2],"folderName":"ds-cabriolet"}',
		],
		'DS3': [
			'{"prodYearStart":2009,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"ds3"}',
		],
		'DS4': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"ds4"}',
		],
		'DS5': [
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"ds5"}',
		],
		'EVASION': [
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[5],"folderName":"evasion-22"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"evasion-u6u"}',
		],
		'JUMPY': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[5],"folderName":"jumpy-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[5],"folderName":"jumpy-ii-multispace"}',
		],
		'SAXO': [
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[9],"folderName":"saxo-s0s1"}',
		],
		'VISA': [
			'{"prodYearStart":1978,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"visa"}',
		],
		'XANTIA': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[3,10],"folderName":"xantia-break-x1"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[3,9],"folderName":"xantia-break-x2"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"xantia-x1"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"xantia-x2"}',
		],
		'XM': [
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[3,9],"folderName":"xm-break-y3"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"xm-break-y4"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"xm-y3"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"xm-y4"}',
		],
		'XSARA': [
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"xsara-break-n2"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[9,4],"folderName":"xsara-coupe-n0"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[9],"folderName":"xsara-n1"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[5],"folderName":"xsara-picasso-n68"}',
		],
		'ZX': [
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[3,10],"folderName":"zx-break-n2"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[9,10],"folderName":"zx-n2"}',
		],
	},
	'DACIA': {
		'1300': [
			'{"prodYearStart":1969,"prodYearEnd":0,"coupeTypes":[7],"folderName":"1300"}',
			'{"prodYearStart":1969,"prodYearEnd":2004,"coupeTypes":[10],"folderName":"1300-combi"}',
		],
		'1310': [
			'{"prodYearStart":1983,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"1310"}',
			'{"prodYearStart":1983,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"1310-combi"}',
		],
		'DOKKER': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"dokker"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"dokker-van"}',
		],
		'DUSTER': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"duster-i"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"duster-i-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"duster-ii"}',
		],
		'LODGY': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[0],"folderName":"lodgy"}',
		],
		'LOGAN': [
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"logan-i"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"logan-i-facelift"}',
			'{"prodYearStart":2014,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"logan-ii"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"logan-ii-restyling"}',
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"logan-mcv"}',
			'{"prodYearStart":2014,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"logan-mcv-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[3],"folderName":"logan-van"}',
		],
		'NOVA': [
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"nova"}',
		],
		'SANDERO': [
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"sandero-i"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"sandero-i-stepway"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"sandero-ii"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"sandero-ii-stepway"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"sandero-iii"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"sandero-iii-stepway"}',
		],
		'SOLENZA': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"solenza"}',
		],
		'SPRING': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[3],"folderName":"spring"}',
		],
	},
	'DAEWOO': {
		'ESPERO': [
			'{"prodYearStart":1995,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"espero-klej"}',
		],
		'EVANDA': [
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"evanda"}',
		],
		'KALOS': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[9],"folderName":"kalos"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"kalos-sedan"}',
		],
		'KORANDO': [
			'{"prodYearStart":1999,"prodYearEnd":2001,"coupeTypes":[2],"folderName":"korando-cabrio-kj"}',
			'{"prodYearStart":1999,"prodYearEnd":2001,"coupeTypes":[1],"folderName":"korando-kj"}',
		],
		'LACETTI': [
			'{"prodYearStart":2002,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"lacetti"}',
		],
		'LEGANZA': [
			'{"prodYearStart":1997,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"leganza-klav"}',
		],
		'MAGNUS': [
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"magnus"}',
		],
		'MATIZ': [
			'{"prodYearStart":2000,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"matiz-ii"}',
			'{"prodYearStart":1998,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"matiz-klya"}',
		],
		'MUSSO': [
			'{"prodYearStart":1999,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"musso-fj"}',
		],
		'NEXIA': [
			'{"prodYearStart":1995,"prodYearEnd":2008,"coupeTypes":[7,10],"folderName":"nexia-kletn"}',
			'{"prodYearStart":1995,"prodYearEnd":1997,"coupeTypes":[9,10],"folderName":"nexia-sedan-kletn"}',
		],
		'NUBIRA': [
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"nubira-combi"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[3],"folderName":"nubira-combi-ii"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"nubira-hatcback"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"nubira-hatchback-ii"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"nubira-sedan"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[3],"folderName":"nubira-sedan-ii"}',
			'{"prodYearStart":2003,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"nubira-sedan-iii"}',
		],
		'PRINCE': [
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"prince"}',
		],
		'RACER': [
			'{"prodYearStart":1986,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"racer"}',
		],
		'REZZO': [
			'{"prodYearStart":2000,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"rezzo-klau"}',
		],
		'TACUMA': [
			'{"prodYearStart":2000,"prodYearEnd":2011,"coupeTypes":[5],"folderName":"tacuma"}',
		],
		'TICO': [
			'{"prodYearStart":1991,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"tico-kly3"}',
		],
	},
	'DAIHATSU': {
		'APPLAUSE': [
			'{"prodYearStart":1989,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"applause-i-a101a111"}',
			'{"prodYearStart":1997,"prodYearEnd":0,"coupeTypes":[9],"folderName":"applause-ii"}',
		],
		'CHARADE': [
			'{"prodYearStart":1977,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"charade-i-combi-g10"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10,4],"folderName":"charade-ii-g11g30"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[9,3,10],"folderName":"charade-iii-combi"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"charade-iv-com-g200"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"charade-iv-g200"}',
		],
		'CHARMANT': [
			'{"prodYearStart":1981,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"charmant-a"}',
		],
		'COPEN': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[2,4],"folderName":"copen-l8"}',
		],
		'CUORE': [
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"cuore-i-l55l60"}',
			'{"prodYearStart":1985,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"cuore-ii-l80l81"}',
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"cuore-iii-l201"}',
			'{"prodYearStart":1995,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"cuore-iv-l501"}',
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[9],"folderName":"cuore-v-l5"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"cuore-vi-l701"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"cuore-vii-l2"}',
		],
		'FEROZA': [
			'{"prodYearStart":1988,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"feroza-hard-top-f300"}',
			'{"prodYearStart":1988,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"feroza-soft-top-f300"}',
		],
		'MATERIA': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[9],"folderName":"materia"}',
		],
		'MOVE': [
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"move-l9"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[5],"folderName":"move-latte-l55"}',
		],
		'ROCKY': [
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[1,10],"folderName":"rocky-hard-top-f7f8"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[1,10],"folderName":"rocky-soft-top-f7f8"}',
		],
		'SIRION': [
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"sirion-m1"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[9],"folderName":"sirion-m2"}',
		],
		'TERIOS': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"terios-ii"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[1],"folderName":"terios-ii-restyling"}',
			'{"prodYearStart":1997,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"terios-j1"}',
			'{"prodYearStart":1997,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"terios-kid"}',
		],
		'TREVIS': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[9],"folderName":"trevis"}',
		],
		'YRV': [
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[5],"folderName":"yrv"}',
		],
	},
	'DAIMLER': {
	},
	'DODGE': {
		'AVENGER': [
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"avenger-coupe"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"avenger-sedan"}',
		],
		'CALIBER': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"caliber"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"caliber-srt"}',
		],
		'CARAVAN': [
			'{"prodYearStart":1984,"prodYearEnd":1990,"coupeTypes":[5],"folderName":"caravan-i"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[3],"folderName":"caravan-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"caravan-iii"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[5],"folderName":"caravan-iv"}',
		],
		'CHALLENGER': [
			'{"prodYearStart":1962,"prodYearEnd":1974,"coupeTypes":[10],"folderName":"challenger"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[4],"folderName":"challenger-srt8"}',
		],
		'CHARGER': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"charger"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"charger-lx"}',
		],
		'DAKOTA': [
			'{"prodYearStart":1987,"prodYearEnd":1996,"coupeTypes":[6],"folderName":"dakota"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[6],"folderName":"dakota-ii"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[6],"folderName":"dakota-iii"}',
		],
		'DAYTONA': [
			'{"prodYearStart":1984,"prodYearEnd":1993,"coupeTypes":[9],"folderName":"daytona"}',
		],
		'DURANGO': [
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"durango"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"durango-ii"}',
		],
		'JOURNEY': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1,9],"folderName":"journey"}',
		],
		'MAGNUM': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[3,10],"folderName":"magnum"}',
		],
		'NEON': [
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"neon"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"neon-coupe"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"neon-ii"}',
		],
		'NITRO': [
			'{"prodYearStart":2006,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"nitro"}',
		],
		'RAM': [
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[6,10],"folderName":"ram-1500-brbe"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[6],"folderName":"ram-1500-drdh"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[6],"folderName":"ram-1500-dsdj"}',
			'{"prodYearStart":1981,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"ram-1500-dw"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[3],"folderName":"ramcharger"}',
		],
		'SHADOW': [
			'{"prodYearStart":1985,"prodYearEnd":1995,"coupeTypes":[2],"folderName":"shadow-convertible"}',
		],
		'STEALTH': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"stealth"}',
		],
		'STRATUS': [
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"stratus-i"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[4],"folderName":"stratus-i-coupe"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[7],"folderName":"stratus-ii"}',
		],
		'VIPER': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"viper-coupe-gts"}',
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"viper-rt"}',
			'{"prodYearStart":2002,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"viper-rt-ii"}',
		],
	},
	'DONGFENG': {
	},
	'EAGLE': {
		'TALON': [
			'{"prodYearStart":1992,"prodYearEnd":0,"coupeTypes":[4],"folderName":"talon"}',
		],
		'VISION': [
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"vision"}',
		],
	},
	'FERRARI': {
		'348': [
			'{"prodYearStart":1993,"prodYearEnd":1995,"coupeTypes":[10],"folderName":"348-spider"}',
			'{"prodYearStart":1989,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"348-tb"}',
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[2],"folderName":"348-ts"}',
		],
		'599': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[4],"folderName":"599-gtb-fiorano"}',
		],
		'CALIFORNIA': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[10,4],"folderName":"california"}',
		],
		'ENZO': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[4],"folderName":"enzo"}',
		],
		'FF': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[4],"folderName":"ff"}',
		],
		'TESTAROSSA': [
			'{"prodYearStart":1984,"prodYearEnd":1992,"coupeTypes":[4],"folderName":"testarossa"}',
		],
	},
	'FIAT': {
		'124': [
			'{"prodYearStart":1966,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"124"}',
			'{"prodYearStart":1967,"prodYearEnd":1976,"coupeTypes":[10,4],"folderName":"124-coupe"}',
			'{"prodYearStart":1967,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"124-familiare"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[2],"folderName":"124-roadster"}',
			'{"prodYearStart":1966,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"124-spider"}',
		],
		'126': [
			'{"prodYearStart":1972,"prodYearEnd":1996,"coupeTypes":[10,9],"folderName":"126"}',
		],
		'127': [
			'{"prodYearStart":1971,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"127"}',
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"127-panorama"}',
		],
		'128': [
			'{"prodYearStart":1969,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"128"}',
			'{"prodYearStart":1972,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"128-coupe"}',
			'{"prodYearStart":1969,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"128-familiare"}',
		],
		'131': [
			'{"prodYearStart":1974,"prodYearEnd":1984,"coupeTypes":[10,4],"folderName":"131"}',
			'{"prodYearStart":1975,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"131-familiarepanorama"}',
		],
		'132': [
			'{"prodYearStart":1972,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"132"}',
		],
		'500': [
			'{"prodYearStart":1957,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"500"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[9],"folderName":"500l"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[9],"folderName":"500l-trekking"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[1],"folderName":"500x"}',
			'{"prodYearStart":2007,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"new-500"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[2],"folderName":"new-500-c"}',
		],
		'600': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[9],"folderName":"600"}',
		],
		'ALBEA': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"albea"}',
		],
		'ARGENTA': [
			'{"prodYearStart":1981,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"argenta-132a"}',
		],
		'BARCHETTA': [
			'{"prodYearStart":1995,"prodYearEnd":0,"coupeTypes":[2],"folderName":"barchetta-183"}',
		],
		'BRAVA': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"brava-182"}',
		],
		'BRAVO': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"bravo-182"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"bravo-ii"}',
		],
		'CINQUECENTO': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"cinquecento"}',
		],
		'COUPE': [
			'{"prodYearStart":1993,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"coupe-fa175"}',
		],
		'CROMA': [
			'{"prodYearStart":1985,"prodYearEnd":1996,"coupeTypes":[10,9],"folderName":"croma-154"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[3,9],"folderName":"croma-ii"}',
		],
		'DOBLO': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[5],"folderName":"doblo-ii"}',
			'{"prodYearStart":2001,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"doblo-panorama"}',
		],
		'DUNA': [
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"duna-146-b"}',
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"duna-weekend-146-b"}',
		],
		'FIORINO': [
			'{"prodYearStart":1977,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"fiorino-127"}',
			'{"prodYearStart":1980,"prodYearEnd":2000,"coupeTypes":[10,5],"folderName":"fiorino-147"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[10],"folderName":"fiorino-combi"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[5,10],"folderName":"fiorino-qubo"}',
		],
		'FREEMONT': [
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"freemont"}',
		],
		'FULLBACK': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[6],"folderName":"fillback"}',
		],
		'IDEA': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[5,0],"folderName":"idea"}',
		],
		'LINEA': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"linea"}',
		],
		'MAREA': [
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"marea-185"}',
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[3],"folderName":"marea-weekend-185"}',
		],
		'MULTIPLA': [
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[5],"folderName":"multipla-186"}',
		],
		'PALIO': [
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"palio-178"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"palio-weekend-178"}',
		],
		'PANDA': [
			'{"prodYearStart":1981,"prodYearEnd":2003,"coupeTypes":[10,9],"folderName":"panda-141a"}',
			'{"prodYearStart":2003,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"panda-4x4"}',
			'{"prodYearStart":2003,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"panda-ii-169"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"panda-iii-319"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"panda-iii-4x4"}',
			'{"prodYearStart":1986,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"panda-van"}',
		],
		'PUNTO': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"grande-punto"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[9],"folderName":"punto-2012-evo-facelift"}',
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[2],"folderName":"punto-cabrio-176c"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[9],"folderName":"punto-evo"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[9,10],"folderName":"punto-i-176"}',
			'{"prodYearStart":1999,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"punto-ii-188"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"punto-ii-restyling"}',
		],
		'QUBO': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[5],"folderName":"qubo"}',
		],
		'REGATA': [
			'{"prodYearStart":1983,"prodYearEnd":1990,"coupeTypes":[10,3,7],"folderName":"regata-138"}',
			'{"prodYearStart":1984,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"regata-weekend"}',
		],
		'RITMO': [
			'{"prodYearStart":1980,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"ritmo-bertone-cabrio"}',
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"ritmo-i-138a"}',
			'{"prodYearStart":1982,"prodYearEnd":1988,"coupeTypes":[10,4],"folderName":"ritmo-ii-138a"}',
		],
		'SEDICI': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sedici"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sedici-2009-facelift"}',
		],
		'SEICENTO': [
			'{"prodYearStart":1998,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"seicento-187"}',
		],
		'SIENA': [
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"siena-178"}',
		],
		'STILO': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"stilo-192"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[3],"folderName":"stilo-multi-wagon"}',
		],
		'STRADA': [
			'{"prodYearStart":1999,"prodYearEnd":2009,"coupeTypes":[10],"folderName":"strada-178e"}',
		],
		'TEMPRA': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[7,10],"folderName":"tempra-159"}',
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[10,3,7],"folderName":"tempra-sw-159"}',
		],
		'TIPO': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"tipo-160"}',
			'{"prodYearStart":2015,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"tipo-356"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"tipo-356-restyling"}',
		],
		'ULYSSE': [
			'{"prodYearStart":1994,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"ulysse-i-22220"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[5],"folderName":"ulysse-ii-179"}',
		],
		'UNO': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"uno"}',
			'{"prodYearStart":1983,"prodYearEnd":2002,"coupeTypes":[9,10],"folderName":"uno-146a"}',
		],
	},
	'FORD': {
		'AEROSTAR': [
			'{"prodYearStart":1986,"prodYearEnd":1997,"coupeTypes":[5],"folderName":"aerostar"}',
		],
		'B_MAX': [
			'{"prodYearStart":2012,"prodYearEnd":2018,"coupeTypes":[5],"folderName":"b-max"}',
		],
		'BRONCO': [
			'{"prodYearStart":1967,"prodYearEnd":1992,"coupeTypes":[1],"folderName":"bronco-i-iv"}',
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"bronco-v"}',
		],
		'C_MAX': [
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"c-max"}',
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"c-max-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[5],"folderName":"c-max-ii-restyling"}',
		],
		'CAPRI': [
			'{"prodYearStart":1974,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"capri-ii-gecp"}',
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[10,4],"folderName":"capri-iii-gecp"}',
		],
		'CONSUL': [
			'{"prodYearStart":1972,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"consul-coupe-ggcl"}',
			'{"prodYearStart":1972,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"consul-ggfl"}',
			'{"prodYearStart":1972,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"consul-turnier-ggnl"}',
		],
		'COUGAR': [
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"cougar-bcv"}',
		],
		'CROWN_VICTORIA': [
			'{"prodYearStart":1990,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"crown-victoria-ii"}',
			'{"prodYearStart":1999,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"crown-victoria-p7"}',
		],
		'ECOSPORT': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"ecosport"}',
		],
		'EDGE': [
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"edge"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"edge-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"edge-restyling"}',
		],
		'ESCAPE': [
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[1],"folderName":"escape"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[3],"folderName":"escape-ii"}',
		],
		'ESCORT': [
			'{"prodYearStart":1968,"prodYearEnd":1976,"coupeTypes":[7],"folderName":"escort-i-afhath"}',
			'{"prodYearStart":1968,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"escort-i-turnier"}',
			'{"prodYearStart":1973,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"escort-ii-ath"}',
			'{"prodYearStart":1975,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"escort-ii-turnier"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"escort-ii-usa"}',
			'{"prodYearStart":1983,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"escort-iii-cabrio-ald"}',
			'{"prodYearStart":1980,"prodYearEnd":1989,"coupeTypes":[10,9],"folderName":"escort-iii-gaaawaabftava"}',
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"escort-iii-turnier-awa"}',
			'{"prodYearStart":1986,"prodYearEnd":1990,"coupeTypes":[10,2],"folderName":"escort-iv-cabrio"}',
			'{"prodYearStart":1985,"prodYearEnd":1990,"coupeTypes":[10,9],"folderName":"escort-iv-gafawfabft"}',
			'{"prodYearStart":1986,"prodYearEnd":1990,"coupeTypes":[3,10],"folderName":"escort-iv-turnier-awfavf"}',
			'{"prodYearStart":1990,"prodYearEnd":1992,"coupeTypes":[2,10],"folderName":"escort-v-cabrio-all"}',
			'{"prodYearStart":1990,"prodYearEnd":1992,"coupeTypes":[10,9],"folderName":"escort-v-gal"}',
			'{"prodYearStart":1990,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"escort-v-turnier-galavl"}',
			'{"prodYearStart":1993,"prodYearEnd":1995,"coupeTypes":[2],"folderName":"escort-vi-cabrio-all"}',
			'{"prodYearStart":1992,"prodYearEnd":1995,"coupeTypes":[7,10],"folderName":"escort-vi-gal"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"escort-vi-hatch-gal"}',
			'{"prodYearStart":1992,"prodYearEnd":1995,"coupeTypes":[10,3],"folderName":"escort-vi-turnier-gal"}',
			'{"prodYearStart":1995,"prodYearEnd":0,"coupeTypes":[2],"folderName":"escort-vii-cabrio"}',
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"escort-vii-galaalabl"}',
			'{"prodYearStart":1995,"prodYearEnd":1999,"coupeTypes":[9],"folderName":"escort-vii-hatch-galafl"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"escort-vii-turnier-galanl"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[3],"folderName":"escort-wagon-ii-usa"}',
		],
		'EXCURSION': [
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[1],"folderName":"excursion"}',
		],
		'EXPEDITION': [
			'{"prodYearStart":2003,"prodYearEnd":2005,"coupeTypes":[1],"folderName":"expedition-ii"}',
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"expedition-u173"}',
		],
		'EXPLORER': [
			'{"prodYearStart":1991,"prodYearEnd":1994,"coupeTypes":[1],"folderName":"explorer-i"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[1],"folderName":"explorer-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[3],"folderName":"explorer-u"}',
			'{"prodYearStart":1993,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"explorer-u2"}',
		],
		'FIESTA': [
			'{"prodYearStart":1976,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"fiesta-i"}',
			'{"prodYearStart":1983,"prodYearEnd":1989,"coupeTypes":[9,10,4],"folderName":"fiesta-ii"}',
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"fiesta-iii-mk3"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"fiesta-iv-mk4-mk5"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[9],"folderName":"fiesta-mk7-restyling"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"fiesta-v-mk6"}',
			'{"prodYearStart":2008,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"fiesta-vi-mk7-mk8"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"fiesta-vii"}',
		],
		'FLEX': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1],"folderName":"flex"}',
		],
		'FOCUS': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[10,2],"folderName":"focus-cabriolet-ii"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"focus-hatchback-i"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[9],"folderName":"focus-hatchback-usa"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"focus-i-sedan"}',
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"focus-ii-hatchback"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"focus-ii-sedan"}',
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"focus-iii-hatchback"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[3],"folderName":"focus-iii-restyling-turnier"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"focus-iii-sedan"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[7],"folderName":"focus-iii-sedan-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[9],"folderName":"focus-rs-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[7],"folderName":"focus-sedan-usa"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"focus-turnier-i"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[3],"folderName":"focus-turnier-ii"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[3],"folderName":"focus-turnier-usa"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"fokus-iii-hatchback-restyling"}',
		],
		'FUSION': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[3],"folderName":"fusion"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"fusion-usa"}',
		],
		'GALAXY': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[5],"folderName":"galaxy-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"galaxy-wgr"}',
		],
		'GRANADA': [
			'{"prodYearStart":1972,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"granada-coupe-ggcl"}',
			'{"prodYearStart":1972,"prodYearEnd":1977,"coupeTypes":[10,7],"folderName":"granada-ggtlggfl"}',
			'{"prodYearStart":1977,"prodYearEnd":1985,"coupeTypes":[10,7],"folderName":"granada-gu"}',
			'{"prodYearStart":1972,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"granada-turnier-ggnl"}',
			'{"prodYearStart":1977,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"granada-turnier-gnu"}',
		],
		'KA': [
			'{"prodYearStart":2009,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"ka-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"ka-iii"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"ka-rbt"}',
		],
		'KUGA': [
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"kuga"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"kuga-facelift"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"kuga-facelift-ii"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"kuga-iii"}',
		],
		'MAVERICK': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"maverick-ii"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"maverick-udsuns"}',
		],
		'MONDEO': [
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"mondeo-i-gbp"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"mondeo-i-hatchback"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[3],"folderName":"mondeo-i-turnier-bnp"}',
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"mondeo-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"mondeo-ii-hatchback"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"mondeo-ii-turnier"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"mondeo-iii"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"mondeo-iii-hatchback"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"mondeo-iii-turnier"}',
			'{"prodYearStart":2006,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"mondeo-iv"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"mondeo-iv-hatchback"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[3],"folderName":"mondeo-iv-turnier"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[7,1,9],"folderName":"mondeo-v-liftback"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[7],"folderName":"mondeo-v-sedan"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"mondeo-v-turnier"}',
		],
		'MUSTANG': [
			'{"prodYearStart":1964,"prodYearEnd":1974,"coupeTypes":[10],"folderName":"mustang-convertible-i"}',
			'{"prodYearStart":1978,"prodYearEnd":1993,"coupeTypes":[2],"folderName":"mustang-convertible-iii"}',
			'{"prodYearStart":1993,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"mustang-convertible-iv"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[2],"folderName":"mustang-convertible-v"}',
			'{"prodYearStart":1964,"prodYearEnd":1974,"coupeTypes":[10],"folderName":"mustang-i"}',
			'{"prodYearStart":1978,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"mustang-iii"}',
			'{"prodYearStart":1993,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"mustang-iv"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[4],"folderName":"mustang-v"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[4],"folderName":"mustang-vi"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[2],"folderName":"mustang-vi-cabriolet"}',
		],
		'ORION': [
			'{"prodYearStart":1983,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"orion-i-afd"}',
			'{"prodYearStart":1985,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"orion-ii-aff"}',
			'{"prodYearStart":1990,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"orion-iii-gal"}',
		],
		'PROBE': [
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"probe-i"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[4],"folderName":"probe-ii-ecp"}',
		],
		'PUMA': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[4],"folderName":"puma-ect"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"puma-ii"}',
		],
		'RANGER': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[6,1],"folderName":"ranger-i"}',
			'{"prodYearStart":2006,"prodYearEnd":2012,"coupeTypes":[6,10],"folderName":"ranger-ii"}',
		],
		'S_MAX': [
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"s-max"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[0],"folderName":"s-max-2010"}',
		],
		'SCORPIO': [
			'{"prodYearStart":1985,"prodYearEnd":1994,"coupeTypes":[7,9],"folderName":"scorpio-i-gaegge"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[10,9],"folderName":"scorpio-i-hatch-gge"}',
			'{"prodYearStart":0,"prodYearEnd":1994,"coupeTypes":[10,3],"folderName":"scorpio-i-turnier-gge"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"scorpio-ii-gfrggr"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"scorpio-ii-turnier"}',
		],
		'SIERRA': [
			'{"prodYearStart":1982,"prodYearEnd":1989,"coupeTypes":[9],"folderName":"sierra-hatchback-i"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[9,10,7],"folderName":"sierra-hatchback-ii"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[7,10],"folderName":"sierra-sedan"}',
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[3],"folderName":"sierra-turnier-i"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[3,10],"folderName":"sierra-turnier-ii"}',
		],
		'TAUNUS': [
			'{"prodYearStart":1979,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"taunus-80-turnier-gbns"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"taunus-coupe-gbck"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"taunus-gbfk"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"taunus-gbtk"}',
			'{"prodYearStart":1975,"prodYearEnd":1979,"coupeTypes":[10,7],"folderName":"taunus-gbtsgbfscbts"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"taunus-turnier-gbnk"}',
			'{"prodYearStart":1975,"prodYearEnd":1982,"coupeTypes":[10,7],"folderName":"taunus-turnier-gbns"}',
		],
		'TAURUS': [
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"taurus"}',
			'{"prodYearStart":1995,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"taurus-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"taurus-mkv"}',
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[3],"folderName":"taurus-station-wagon"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"taurus-station-wagon-ii"}',
		],
		'THUNDERBIRD': [
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[4],"folderName":"thunderbird-aero-birds"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"thunderbird-retro-birds"}',
			'{"prodYearStart":1988,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"thunderbird-super-birds"}',
		],
		'WINDSTAR': [
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"windstar-a3"}',
		],
	},
	'FSO': {
		'POLONEZ': [
			'{"prodYearStart":1977,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"polonez-i"}',
			'{"prodYearStart":1988,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"polonez-ii"}',
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"polonez-iii"}',
		],
	},
	'GAZ': {
		'69': [
			'{"prodYearStart":1953,"prodYearEnd":1971,"coupeTypes":[1],"folderName":"69"}',
		],
	},
	'GEELY': {
	},
	'GEO': {
		'METRO': [
			'{"prodYearStart":0,"prodYearEnd":1997,"coupeTypes":[9,7,10],"folderName":"metro"}',
		],
		'PRIZM': [
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[7,10],"folderName":"prizm"}',
		],
		'STORM': [
			'{"prodYearStart":1990,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"storm"}',
		],
		'TRACKER': [
			'{"prodYearStart":0,"prodYearEnd":1996,"coupeTypes":[1],"folderName":"tracker"}',
		],
	},
	'GMC': {
		'ACADIA': [
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"acadia"}',
		],
		'CANYON': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[6],"folderName":"canyon"}',
		],
		'ENVOY': [
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[1],"folderName":"envoy-gmt330"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"envoy-gmt840"}',
		],
		'JIMMY': [
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[1],"folderName":"jimmy"}',
		],
		'SAVANA': [
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[5],"folderName":"savana"}',
		],
		'SIERRA': [
			'{"prodYearStart":1998,"prodYearEnd":2007,"coupeTypes":[6],"folderName":"sierra-gm840"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[6],"folderName":"sierra-k2xx"}',
		],
		'SONOMA': [
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[6],"folderName":"sonoma-gmt400"}',
		],
		'TYPHOON': [
			'{"prodYearStart":1992,"prodYearEnd":1993,"coupeTypes":[1],"folderName":"typhoon"}',
		],
		'YUKON': [
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"yukon"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"yukon-gmt800"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"yukon-gmt900"}',
		],
	},
	'GONOW': {
	},
	'GREAT_WALL': {
		'SAFE': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[1],"folderName":"safe"}',
		],
	},
	'HONDA': {
		'ACCORD': [
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"accord-i-hatchback-sjsy"}',
			'{"prodYearStart":1978,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"accord-i-sjsy"}',
			'{"prodYearStart":1983,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"accord-ii-acad"}',
			'{"prodYearStart":1983,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"accord-ii-hatchback-acad"}',
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"accord-iii-ca4ca5"}',
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"accord-iii-wagon-ca5"}',
			'{"prodYearStart":1990,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"accord-iv-cb3cb7"}',
			'{"prodYearStart":1990,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"accord-iv-coupe-cc1"}',
			'{"prodYearStart":1990,"prodYearEnd":1993,"coupeTypes":[3],"folderName":"accord-iv-wagon-cb8"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"accord-ix"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[4],"folderName":"accord-ix-coupe"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"accord-v-cc7"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[4],"folderName":"accord-v-coupe-cd7"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"accord-v-wagon-ce"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"accord-vi-cecf"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"accord-vi-coupe"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"accord-vi-hatchback"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"accord-vi-wagon"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"accord-vii"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"accord-vii-coupe"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"accord-vii-wagon"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"accord-viii"}',
			'{"prodYearStart":2008,"prodYearEnd":2014,"coupeTypes":[3,10],"folderName":"accord-viii-wagon"}',
		],
		'CITY': [
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"city"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"city-sedan"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"city-zx-sedan"}',
		],
		'CIVIC': [
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"civic-5d-viii"}',
			'{"prodYearStart":1994,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"civic-coupe-v"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"civic-coupe-vi"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[4],"folderName":"civic-coupe-vii"}',
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"civic-fastback-v"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"civic-fastback-vi"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"civic-fastback-vii"}',
			'{"prodYearStart":1972,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"civic-hatchback-i"}',
			'{"prodYearStart":1980,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"civic-hatchback-ii"}',
			'{"prodYearStart":1985,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"civic-hatchback-iii"}',
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"civic-hatchback-iv"}',
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"civic-hatchback-v"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"civic-hatchback-vi"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"civic-hatchback-vii"}',
			'{"prodYearStart":1972,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"civic-i"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"civic-i-shuttle"}',
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"civic-i-wagon"}',
			'{"prodYearStart":1980,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"civic-ii"}',
			'{"prodYearStart":1988,"prodYearEnd":1997,"coupeTypes":[10,5],"folderName":"civic-ii-shuttle"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"civic-iii"}',
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"civic-iv"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"civic-ix"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"civic-ix-sedan"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[9],"folderName":"civic-type-r-ix"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"civic-type-r-x"}',
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"civic-v"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[7,10],"folderName":"civic-vi"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[3,10],"folderName":"civic-vi-wagon"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"civic-vii"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"civic-viii-sedan"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[9],"folderName":"civic-viii-type-r"}',
			'{"prodYearStart":2015,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"civic-x"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"civic-xi"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"civik-ix-tourer"}',
		],
		'CONCERTO': [
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"concerto-hatch-hw"}',
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"concerto-hw"}',
		],
		'CR_V': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1,10],"folderName":"cr-v-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"cr-v-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"cr-v-iv"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"cr-v-iv-restyling"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"cr-v-rd"}',
			'{"prodYearStart":2018,"prodYearEnd":2022,"coupeTypes":[1],"folderName":"cr-v-v"}',
		],
		'CRX': [
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"crx-i-afas"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10,4],"folderName":"crx-ii-edee"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"crx-iii-eheg"}',
		],
		'ELEMENT': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"element"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"element-ii"}',
		],
		'ELYSION': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[5],"folderName":"elysion"}',
		],
		'FIT': [
			'{"prodYearStart":0,"prodYearEnd":2008,"coupeTypes":[10,9],"folderName":"fit-i"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[9],"folderName":"fit-iii"}',
		],
		'FR_V': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[5],"folderName":"fr-vedix"}',
		],
		'HR_V': [
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[1],"folderName":"hr-v-gh"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"hr-v-ii"}',
		],
		'INSIGHT': [
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"insight"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"insight-ii"}',
		],
		'INTEGRA': [
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"integra-coupe-dc2"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[4],"folderName":"integra-coupe-dc5"}',
		],
		'JAZZ': [
			'{"prodYearStart":1983,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"jazz-aa"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"jazz-i"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"jazz-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"jazz-ii-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"jazz-iii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"jazz-iii-restyling"}',
		],
		'LEGEND': [
			'{"prodYearStart":1987,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"legend-i-coupe-ka3"}',
			'{"prodYearStart":1986,"prodYearEnd":1990,"coupeTypes":[7,10],"folderName":"legend-i-hska"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"legend-ii-coupe-ka8"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"legend-ii-ka7"}',
			'{"prodYearStart":1996,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"legend-iii-ka9"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"legend-iv-kb1"}',
		],
		'LOGO': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"logo-ga3"}',
		],
		'NSX': [
			'{"prodYearStart":1990,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"nsx-cabrio-na"}',
			'{"prodYearStart":1990,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"nsx-coupe-na"}',
		],
		'ODYSSEY': [
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[5],"folderName":"odyssey-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[5],"folderName":"odyssey-ii"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"odyssey-iii"}',
		],
		'PASSPORT': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[1],"folderName":"passport"}',
		],
		'PILOT': [
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"pilot-i"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1],"folderName":"pilot-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"pilot-ii-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":null,"coupeTypes":[1],"folderName":"pilot-iii"}',
		],
		'PRELUDE': [
			'{"prodYearStart":1978,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"prelude-i-coupe-sn"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"prelude-ii-ab"}',
			'{"prodYearStart":1986,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"prelude-iii-ba"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"prelude-iv-bb"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"prelude-v-bb"}',
		],
		'QUINTET': [
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"quintet-su"}',
		],
		'RIDGELINE': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[6],"folderName":"ridgeline"}',
		],
		'S2000': [
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"s2000"}',
		],
		'SHUTTLE': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"shuttle-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[5],"folderName":"shuttle-ii"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"shuttle-iii"}',
		],
		'STREAM': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"stream"}',
		],
	},
	'HYUNDAI': {
		'ACCENT': [
			'{"prodYearStart":0,"prodYearEnd":2000,"coupeTypes":[9,10],"folderName":"accent-hatchback-i"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[9],"folderName":"accent-hatchback-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"accent-hatchback-iii"}',
			'{"prodYearStart":1995,"prodYearEnd":1999,"coupeTypes":[7,10],"folderName":"accent-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"accent-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"accent-iii"}',
		],
		'ATOS': [
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"atos"}',
			'{"prodYearStart":2000,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"atos-prime"}',
		],
		'COUPE': [
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"coupe-i-j2"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"coupe-ii-rd"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"coupe-iii-gk"}',
		],
		'ELANTRA': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"elantra-i"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[10,7],"folderName":"elantra-ii"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[10,3,9],"folderName":"elantra-ii-wagon"}',
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"elantra-iii"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"elantra-iii-wagon"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"elantra-iv"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"elantra-v"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"elantra-v-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"elantra-vi"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"elantra-xd"}',
		],
		'GALLOPER': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"galloper"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"galloper-ii"}',
		],
		'GENESIS': [
			'{"prodYearStart":2008,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"genesis"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[4],"folderName":"genesis-coupe"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[7],"folderName":"genesis-ii"}',
		],
		'GETZ': [
			'{"prodYearStart":2002,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"getz"}',
		],
		'GRANDEUR': [
			'{"prodYearStart":1986,"prodYearEnd":1992,"coupeTypes":[7],"folderName":"grandeur-i"}',
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[7,10],"folderName":"grandeur-ii"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"grandeur-iii"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"grandeur-iv"}',
		],
		'I10': [
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"i10"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"i10-ii"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"i10-iii"}',
		],
		'I20': [
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"i20"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"i20-ii"}',
			'{"prodYearStart":2018,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"i20-ii-ib-restyling"}',
			'{"prodYearStart":2020,"prodYearEnd":null,"coupeTypes":[9],"folderName":"i20-iii"}',
		],
		'I30': [
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"i30"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"i30-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"i30-ii-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"i30-iii"}',
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"i30-restyling"}',
			'{"prodYearStart":2018,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"i300-iii-restyling"}',
			'{"prodYearStart":2020,"prodYearEnd":null,"coupeTypes":[9],"folderName":"i300-iii-restyling-2"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"i30cw"}',
		],
		'I40': [
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"i40-i"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"i40-i-cw"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[7],"folderName":"i40-i-restiling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"i40-i-restiling-cw"}',
		],
		'IONIQ': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ioniq"}',
		],
		'IX20': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"ix20"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"ix20-restyling"}',
		],
		'KONA': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"kona-i"}',
		],
		'LANTRA': [
			'{"prodYearStart":0,"prodYearEnd":2000,"coupeTypes":[3,7],"folderName":"lantra"}',
		],
		'MATRIX': [
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[5],"folderName":"matrix"}',
		],
		'PONY': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[9,7],"folderName":"pony-x-2"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"ponyexcel-hatchback-x-2"}',
		],
		'SANTA_FE': [
			'{"prodYearStart":2000,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"santa-fe-i"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"santa-fe-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"santa-fe-iii"}',
		],
		'SANTAMO': [
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[0,5],"folderName":"santamo"}',
		],
		'SONATA': [
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"sonata-ii"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"sonata-iii"}',
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"sonata-iii-restyling"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"sonata-iv"}',
			'{"prodYearStart":2001,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"sonata-iv-restyling"}',
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"sonata-v"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[7],"folderName":"sonata-vi"}',
		],
		'TERRACAN': [
			'{"prodYearStart":2001,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"terracan"}',
		],
		'TRAJET': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[5,10],"folderName":"trajet-fo"}',
		],
		'TUCSON': [
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"tucson-iii"}',
		],
		'XG': [
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"xg"}',
		],
	},
	'INFINITI': {
		'G37': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[2],"folderName":"g37-convertible"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[4],"folderName":"g37-coupe"}',
		],
		'Q30': [
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"q30"}',
		],
		'Q45': [
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"q45-i"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"q45-ii"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[7],"folderName":"q45-iii"}',
		],
		'Q50': [
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"q50"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[7],"folderName":"q50-restyling"}',
		],
		'QX4': [
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[1],"folderName":"qx4"}',
		],
		'QX56': [
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"qx56"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"qx56-iii"}',
		],
	},
	'JAGUAR': {
		'E_PACE': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"e-pace"}',
		],
		'F_PACE': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[1],"folderName":"f-pace"}',
		],
		'F_TYPE': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[4],"folderName":"f-type"}',
		],
		'I_PACE': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"i-pace"}',
		],
		'S_TYPE': [
			'{"prodYearStart":1998,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"s-type-ccx"}',
		],
		'X_TYPE': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[3],"folderName":"x-type-estate"}',
			'{"prodYearStart":2001,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"x-type-x400"}',
		],
		'XE': [
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"xe"}',
		],
		'XF': [
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"xf"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"xf-restyling"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[7],"folderName":"xfr"}',
		],
		'XJ': [
			'{"prodYearStart":1968,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"xj"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7],"folderName":"xj-new"}',
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"xj-x300nawnab"}',
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"xj-x308nawnab"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"xj-x350na3"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[10,7],"folderName":"xj-xj40xj81"}',
		],
		'XJR': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"xjr"}',
		],
		'XKR': [
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"xkr"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"xkr-convertible-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"xkr-coupe-ii"}',
		],
	},
	'JEEP': {
		'CHEROKEE': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cherokee"}',
			'{"prodYearStart":1988,"prodYearEnd":2001,"coupeTypes":[1],"folderName":"cherokee-i-xj"}',
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"cherokee-ii"}',
		],
		'COMMANDER': [
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"commander"}',
		],
		'COMPASS': [
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"compass"}',
		],
		'GRAND_CHEROKEE': [
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[1],"folderName":"grand-cherokee-i-z"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"grand-cherokee-ii-wj"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"grand-cherokee-iii-wh"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"grand-cherokee-iv-wk2"}',
			'{"prodYearStart":2013,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"grand-cherokee-iv-wk2-restyling"}',
		],
		'PATRIOT': [
			'{"prodYearStart":2006,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"patriot"}',
		],
		'RENEGADE': [
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"renegade"}',
		],
		'WRANGLER': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[1],"folderName":"wrangler-i"}',
			'{"prodYearStart":1997,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"wrangler-ii-tj"}',
			'{"prodYearStart":2007,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"wrangler-iii-jk"}',
		],
	},
	'KIA': {
		'CARENS': [
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"carens-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[5],"folderName":"carens-iii"}',
		],
		'CARNIVAL': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"carnival-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[5,1],"folderName":"carnival-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"carnival-up"}',
		],
		'CEED': [
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"ceed"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"ceed-gt-hatchback"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"ceed-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"ceed-ii-restyling"}',
			'{"prodYearStart":2018,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"ceed-iii"}',
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"ceed-sw"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"ceed-sw-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[9],"folderName":"pro-ceed"}',
		],
		'CLARUS': [
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"clarus-combi-gc"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"clarus-gc"}',
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"clarus-k9a"}',
		],
		'MAGENTIS': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"magentis-i"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"magentis-ii-mg"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"magentis-iii"}',
		],
		'MOHAVE': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1],"folderName":"mohave"}',
		],
		'OPIRUS': [
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"opirus"}',
		],
		'OPTIMA': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"optima-i"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"optima-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"optima-iii"}',
			'{"prodYearStart":2013,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"optima-iii-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"optima-iv"}',
		],
		'PICANTO': [
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"picanto"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9,7],"folderName":"picanto-ii"}',
		],
		'PRIDE': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"pride-combi"}',
			'{"prodYearStart":1990,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"pride-da"}',
		],
		'RETONA': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"retona-ce"}',
		],
		'RIO': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"rio-i-hatchback"}',
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"rio-i-sedan"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"rio-ii-hatchback"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"rio-ii-sedan"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"rio-iii-hatchback"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"rio-iii-hatchback-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"rio-iii-sedan"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"rio-iii-sedan-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"rio-iv-hatchback"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"rio-iv-restyling"}',
		],
		'SEDONA': [
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[5],"folderName":"sedona"}',
		],
		'SEPHIA': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"sephia-fa"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[10],"folderName":"sephia-hatchback-fa"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[7],"folderName":"sephia-ii"}',
		],
		'SHUMA': [
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"shuma-fb"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[9],"folderName":"shuma-ii"}',
		],
		'SORENTO': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"sorento-i"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sorento-i-restiling"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"sorento-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"sorento-ii-restiling"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"sorento-iii"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"sorento-iii-restyling"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sorento-iv"}',
		],
		'SOUL': [
			'{"prodYearStart":0,"prodYearEnd":2011,"coupeTypes":[9,10],"folderName":"soul"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"soul-ii"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"soul-ii-restyling"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"soul-iii"}',
		],
		'SPECTRA': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"spectra"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[10],"folderName":"spectra-usa"}',
		],
		'SPORTAGE': [
			'{"prodYearStart":2004,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"sportage-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"sportage-iii"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"sportage-iv"}',
			'{"prodYearStart":2019,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"sportage-iv-restyling"}',
			'{"prodYearStart":1997,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"sportage-ja"}',
			'{"prodYearStart":1994,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"sportage-k00"}',
			'{"prodYearStart":1997,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"sportage-soft-top-ja"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sportage-v"}',
		],
		'STINGER': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[7],"folderName":"stinger-i"}',
		],
		'STONIC': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"stonic-i"}',
		],
		'VENGA': [
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[5],"folderName":"venga"}',
		],
		'VISTO': [
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[9],"folderName":"visto"}',
		],
	},
	'LAMBORGHINI': {
		'AVENTADOR': [
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[4],"folderName":"aventador-lp-700-4"}',
		],
		'COUNTACH': [
			'{"prodYearStart":1974,"prodYearEnd":1991,"coupeTypes":[4],"folderName":"countach"}',
		],
		'DIABLO': [
			'{"prodYearStart":1990,"prodYearEnd":2006,"coupeTypes":[4,10],"folderName":"diablo"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[10],"folderName":"diablo-roadster"}',
		],
		'GALLARDO': [
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"gallardo"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"gallardo-lp-550-2"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[4,2],"folderName":"gallardo-lp-560-4"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2,4],"folderName":"gallardo-lp-570-4"}',
			'{"prodYearStart":2005,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"gallardo-roadster"}',
		],
		'HURACAN': [
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[4],"folderName":"huracan"}',
		],
		'MURCIELAGO': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"murcielago"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[4],"folderName":"murcielago-lp640"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"murcielago-roadster"}',
		],
		'REVENTON': [
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[4],"folderName":"reventon"}',
		],
		'URUS': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"urus"}',
		],
		'VENENO': [
			'{"prodYearStart":2013,"prodYearEnd":2013,"coupeTypes":[10],"folderName":"veneno"}',
		],
	},
	'LANCIA': {
		'BETA': [
			'{"prodYearStart":1976,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"beta-828"}',
			'{"prodYearStart":1976,"prodYearEnd":1984,"coupeTypes":[10,4],"folderName":"beta-coupe-bc"}',
			'{"prodYearStart":1976,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"beta-hpe-828-bf"}',
			'{"prodYearStart":1976,"prodYearEnd":1986,"coupeTypes":[10,2],"folderName":"beta-spider"}',
		],
		'DEDRA': [
			'{"prodYearStart":1989,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"dedra-835"}',
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[3],"folderName":"dedra-station-wagon-835"}',
		],
		'DELTA': [
			'{"prodYearStart":1979,"prodYearEnd":1994,"coupeTypes":[10,9,7],"folderName":"delta-i-831-abo"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[9,10],"folderName":"delta-ii-836"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"delta-iii"}',
		],
		'FULVIA': [
			'{"prodYearStart":1968,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"fulvia"}',
		],
		'KAPPA': [
			'{"prodYearStart":1994,"prodYearEnd":0,"coupeTypes":[7],"folderName":"kappa-838"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[10,4],"folderName":"kappa-coupe-838"}',
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[3],"folderName":"kappa-station-wagon-838"}',
		],
		'LYBRA': [
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"lybra-839"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"lybra-sw-839"}',
		],
		'MUSA': [
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[10],"folderName":"musa"}',
		],
		'PHEDRA': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[5],"folderName":"phedra"}',
		],
		'PRISMA': [
			'{"prodYearStart":1982,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"prisma-831-ab"}',
		],
		'THEMA': [
			'{"prodYearStart":1984,"prodYearEnd":1994,"coupeTypes":[7,10],"folderName":"thema-834"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"thema-ii"}',
			'{"prodYearStart":1987,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"thema-station-wagon-834"}',
		],
		'THESIS': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"thesis"}',
		],
		'Y': [
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"y-840"}',
			'{"prodYearStart":1985,"prodYearEnd":1995,"coupeTypes":[10,9],"folderName":"y10-156"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"ypsilon"}',
		],
		'ZETA': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"zeta"}',
		],
	},
	'LAND_ROVER': {
		'DEFENDER': [
			'{"prodYearStart":1983,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"defender-110"}',
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[6],"folderName":"defender-130"}',
			'{"prodYearStart":1983,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"defender-90"}',
		],
		'DISCOVERY': [
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"discovery-i"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"discovery-ii"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"discovery-iii"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"discovery-iv"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"discovery-iv-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"discovery-v"}',
		],
		'FREELANDER': [
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"freelander-hard-top"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"freelander-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"freelander-ii-restyling"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"freelander-ln"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"freelander-soft-top"}',
		],
		'RANGE_ROVER': [
			'{"prodYearStart":1981,"prodYearEnd":1994,"coupeTypes":[10,1],"folderName":"range-rover-i"}',
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[3,1],"folderName":"range-rover-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"range-rover-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"range-rover-iv"}',
			'{"prodYearStart":2005,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"range-rover-sport"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"range-rover-sport-ii"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"range-rover-velar"}',
		],
		'RANGE_ROVER_EVOQUE': [
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"range-rover-evoque-3-doors"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"range-rover-evoque-5-doors"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"range-rover-evoque-5-doors-restyling"}',
		],
	},
	'LANDWIND': {
	},
	'LEXUS': {
		'ES': [
			'{"prodYearStart":2001,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"es-bf"}',
			'{"prodYearStart":1991,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"es-f1f2"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"es-v"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"es-v-restyling"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"es-vi"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"es-vi-restyling"}',
		],
		'GS': [
			'{"prodYearStart":1993,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"gs-i"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"gs-ii"}',
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"gs-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"gs-iv"}',
		],
		'IS': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"is-coupe-convertible"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"is-f"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"is-i"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"is-i-sportcross"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"is-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"is-ii-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"is-iii"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[7],"folderName":"is-iii-restyling"}',
		],
		'LC': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[4],"folderName":"lc-cupe"}',
		],
		'LS': [
			'{"prodYearStart":1989,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"ls-i"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"ls-ii"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"ls-iii"}',
			'{"prodYearStart":2006,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"ls-iv"}',
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"ls-iv-restyling"}',
		],
		'LX': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"lx-i"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[1],"folderName":"lx-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"lx-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"lx-iii-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"lx-iii-restyling-ii"}',
		],
		'NX': [
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"nx"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"nx-restyling"}',
		],
		'RX': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"rx-i"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"rx-ii"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"rx-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"rx-iii-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"rx-iv"}',
		],
		'SC': [
			'{"prodYearStart":1991,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"sc-i"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[2],"folderName":"sc-ii"}',
		],
	},
	'LINCOLN': {
		'CONTINENTAL': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"continental"}',
			'{"prodYearStart":null,"prodYearEnd":0,"coupeTypes":[2],"folderName":"continental-gt"}',
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[7],"folderName":"continental-vii"}',
		],
		'LS': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[7],"folderName":"ls"}',
		],
		'MARK': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"mark-viii"}',
		],
		'MARK_LT': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[6],"folderName":"mark-lt"}',
		],
		'MKX': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"mkx"}',
		],
		'MKZ': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"mkz"}',
		],
		'NAVIGATOR': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"navigator-i"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"navigator-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"navigator-iii"}',
		],
		'TOWN_CAR': [
			'{"prodYearStart":1981,"prodYearEnd":2001,"coupeTypes":[7,10],"folderName":"town-car"}',
		],
		'ZEPHYR': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"zephyr"}',
		],
	},
	'LOTUS': {
		'ELISE': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"elise"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[2],"folderName":"elise-340-r"}',
			'{"prodYearStart":2000,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"elise-ii"}',
		],
		'EVORA': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[4],"folderName":"evora"}',
		],
		'EXIGE': [
			'{"prodYearStart":2001,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"exige"}',
		],
	},
	'MAHINDRA': {
		'ARMADA': [
			'{"prodYearStart":1990,"prodYearEnd":0,"coupeTypes":[1],"folderName":"armada-cj7"}',
		],
		'BOLERO': [
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[1],"folderName":"bolero-cj7"}',
		],
		'CL': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"cl"}',
		],
		'COMMANDER': [
			'{"prodYearStart":1990,"prodYearEnd":0,"coupeTypes":[1],"folderName":"commander"}',
		],
		'KUV_100': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"kuv-100"}',
		],
		'SCORPIO': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[1],"folderName":"scorpio"}',
		],
	},
	'MASERATI': {
		'3200_GT': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"3200-gt"}',
		],
		'BITURBO': [
			'{"prodYearStart":1984,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"biturbo"}',
			'{"prodYearStart":1981,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"biturbo-coupe"}',
			'{"prodYearStart":1985,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"biturbo-spider"}',
		],
		'GHIBLI': [
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"ghibli-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"ghibli-iii"}',
		],
		'GRANCABRIO': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2],"folderName":"grancabrio"}',
		],
		'GRANSPORT': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[4],"folderName":"gransport"}',
		],
		'GRANTURISMO': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[4],"folderName":"granturismo"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[4],"folderName":"granturismo-s"}',
		],
		'LEVANTE': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[1],"folderName":"levante"}',
		],
		'QUATTROPORTE': [
			'{"prodYearStart":1976,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"quattroporte"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"quattroporte-iii"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"quattroporte-iv"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7],"folderName":"quattroporte-s"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"quattroporte-sport-gt-s"}',
		],
		'SPYDER': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[2],"folderName":"spyder"}',
		],
	},
	'MAYBACH': {
	},
	'MAZDA': {
		'121': [
			'{"prodYearStart":1987,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"121-i-da"}',
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"121-ii-db"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"121-iii-jasmjbsm"}',
		],
		'323': [
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[9,10,7],"folderName":"323-c-iv-bg"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[9],"folderName":"323-c-v-ba"}',
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"323-f-iv-bg"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"323-f-v-ba"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"323-f-vi-bj"}',
			'{"prodYearStart":1977,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"323-i-fa"}',
			'{"prodYearStart":1978,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"323-i-station-wagon-fa"}',
			'{"prodYearStart":1980,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"323-ii-bd"}',
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"323-ii-hatchback-bd"}',
			'{"prodYearStart":1985,"prodYearEnd":1991,"coupeTypes":[10,7],"folderName":"323-iii-bf"}',
			'{"prodYearStart":1985,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"323-iii-hatchbac-bf"}',
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"323-iii-station-wagon-bw"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"323-p-vi-bj"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[10,9],"folderName":"323-s-iv-bg"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"323-s-v-ba"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"323-s-vi-bj"}',
		],
		'626': [
			'{"prodYearStart":1979,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"626-i-cb"}',
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"626-ii-gc"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"626-ii-hatchback-gc"}',
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[4,10],"folderName":"626-iii-coupe-gd"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[7,10],"folderName":"626-iii-gd"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10,9],"folderName":"626-iii-hatchbac-gd"}',
			'{"prodYearStart":1988,"prodYearEnd":1996,"coupeTypes":[10,3],"folderName":"626-iii-station-wagon-gv"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"626-iv-ge"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[9,10],"folderName":"626-iv-hatchbac-ge"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"626-iv-station-wagon"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"626-v-gf"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"626-v-hatchback-gf"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"626-v-station-wagon-gfgw"}',
		],
		'929': [
			'{"prodYearStart":1979,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"929-i-la"}',
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"929-ii-coupe-hb"}',
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"929-ii-hb"}',
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"929-ii-station-wagon-hv"}',
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"929-iii-hc"}',
		],
		'BT_50': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[6],"folderName":"bt-50"}',
		],
		'CX_30': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cx-30"}',
		],
		'CX_5': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cx-5-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"cx-5-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"mazda-cx-5"}',
		],
		'CX_60': [
			'{"prodYearStart":2022,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cx-60"}',
		],
		'CX_7': [
			'{"prodYearStart":2006,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"cx-7"}',
		],
		'CX_9': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cx-9"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[1],"folderName":"cx-9-restyling"}',
		],
		'DEMIO': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"demio-dw"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"demio-dy"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"demio-iii-de"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[9],"folderName":"demio-iv-dj"}',
		],
		'2': [
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"mazda-2"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"mazda-2-dy"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"mazda-2-ii-de2-restyling"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"mazda-2-iii-dj"}',
		],
		'3': [
			'{"prodYearStart":0,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"mazda-3-hatchback"}',
			'{"prodYearStart":2009,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"mazda-3-ii-hatchback"}',
			'{"prodYearStart":2009,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"mazda-3-ii-saloon"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"mazda-3-iii-hatchback"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"mazda-3-iii-sedan"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"mazda-3-iv-bp-hatchback"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[7],"folderName":"mazda-3-iv-bp-sedan"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7],"folderName":"mazda-3-saloon"}',
		],
		'5': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[5],"folderName":"mazda-5"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[5],"folderName":"mazda-5-ii"}',
		],
		'6': [
			'{"prodYearStart":2007,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"mazda-6-ii-combi-gh"}',
			'{"prodYearStart":2007,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"mazda-6-ii-hatchback-gh"}',
			'{"prodYearStart":2007,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"mazda-6-ii-sedan-gh"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"mazda-6-iii-restyling"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"mazda-6-iii-sedan-gj"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"mazda-6-iii-sport-combi-gj"}',
		],
		'MPV': [
			'{"prodYearStart":1990,"prodYearEnd":1999,"coupeTypes":[5],"folderName":"mpv-i-lv"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[5,10],"folderName":"mpv-ii-lw"}',
			'{"prodYearStart":2006,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"mpv-iii-mazda-8"}',
		],
		'MX_3': [
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"mx-3-ec"}',
		],
		'MX_5': [
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[2],"folderName":"mx-5-i-na"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[2],"folderName":"mx-5-ii-nb"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[2],"folderName":"mx-5-iii"}',
			'{"prodYearStart":2009,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"mx-5-iii-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[2],"folderName":"mx-5-iv"}',
		],
		'MX_6': [
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"mx-6-ge6"}',
		],
		'PREMACY': [
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"premacy-cp"}',
		],
		'RX_7': [
			'{"prodYearStart":1979,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"rx-7-i-sa"}',
			'{"prodYearStart":1985,"prodYearEnd":1991,"coupeTypes":[4,10],"folderName":"rx-7-ii-fc"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"rx-7-iii-fd"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[4],"folderName":"rx-7-iv"}',
		],
		'RX_8': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[4],"folderName":"rx-8"}',
		],
		'TRIBUTE': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"tribute"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"tribute-hybrid"}',
		],
	},
	'MERCEDES_BENZ': {
		'190': [
			'{"prodYearStart":1982,"prodYearEnd":1993,"coupeTypes":[7,10],"folderName":"190-w201"}',
		],
		'200': [
			'{"prodYearStart":1976,"prodYearEnd":1985,"coupeTypes":[7],"folderName":"200-w123"}',
			'{"prodYearStart":1985,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"200-w124"}',
		],
		'220': [
			'{"prodYearStart":1976,"prodYearEnd":1979,"coupeTypes":[7],"folderName":"220-w123"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"220-w124"}',
		],
		'230': [
			'{"prodYearStart":1976,"prodYearEnd":1985,"coupeTypes":[7],"folderName":"230-w123"}',
			'{"prodYearStart":1985,"prodYearEnd":1992,"coupeTypes":[10,7],"folderName":"230-w124"}',
		],
		'240': [
			'{"prodYearStart":1978,"prodYearEnd":1985,"coupeTypes":[7],"folderName":"240-w123"}',
		],
		'250': [
			'{"prodYearStart":1976,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"250-w123"}',
			'{"prodYearStart":1985,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"250-w124"}',
		],
		'260': [
			'{"prodYearStart":1985,"prodYearEnd":1992,"coupeTypes":[7],"folderName":"260-w124"}',
		],
		'280': [
			'{"prodYearStart":1975,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"280-w123"}',
			'{"prodYearStart":1992,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"280-w124"}',
		],
		'300': [
			'{"prodYearStart":1976,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"300-w123"}',
			'{"prodYearStart":1985,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"300-w124"}',
		],
		'500': [
			'{"prodYearStart":1991,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"500-w124"}',
		],
		'A_CLASS': [
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"a-classe-iii-w176"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[9],"folderName":"a-klasse-168"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"a-klasse-169"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"a-klasse-iii-w176-restyling"}',
			'{"prodYearStart":2018,"prodYearEnd":2022,"coupeTypes":[9],"folderName":"a-klasse-iv"}',
		],
		'B_CLASS': [
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"b-klasse-second-generation-w246"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"b-klasse-w245"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"b-klasse-w246-restyling"}',
		],
		'C_CLASS': [
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"c-classe-w205"}',
			'{"prodYearStart":2018,"prodYearEnd":2021,"coupeTypes":[7],"folderName":"c-classe-w205-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"c-klasse-coupe-204"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[4],"folderName":"c-klasse-sport-coupe-203"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[3,10],"folderName":"c-klasse-t-mod-s202"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[3,7],"folderName":"c-klasse-t-mod-s203"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[3],"folderName":"c-klasse-t-mod-s204"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"c-klasse-t-mod-w205"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"c-klasse-w202"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"c-klasse-w203"}',
			'{"prodYearStart":2007,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"c-klasse-w204"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[4],"folderName":"c-klasse-w205-coupe"}',
		],
		'CL_CLASS': [
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"cl-klasse-i-c140"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"cl-klasse-ii-c215"}',
			'{"prodYearStart":0,"prodYearEnd":2010,"coupeTypes":[4],"folderName":"cl-klasse-iii-c216"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[4],"folderName":"cl-klasse-iii-c216-restyling"}',
		],
		'CLA_CLASS': [
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[7],"folderName":"cla-classe-c117-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"cla-klasse"}',
		],
		'CLC_CLASS': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"clc-klasse"}',
		],
		'CLK_CLASS': [
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"clk-klasse-i-w208"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[4],"folderName":"clk-klasse-ii-w209-restyling"}',
		],
		'CLS_CLASS': [
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"cls-klasse-ii-w218-restyling"}',
			'{"prodYearStart":2018,"prodYearEnd":2021,"coupeTypes":[7],"folderName":"cls-klasse-iii-c257"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[7],"folderName":"cls-klasse-iii-c257-restyling"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"cls-klasse-w218"}',
		],
		'E_CLASS': [
			'{"prodYearStart":2013,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"e-classe-iv-w212-s212-c207"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[2,10],"folderName":"e-klasse-cabrio-a124"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[2],"folderName":"e-klasse-cabrio-a207"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[4,10],"folderName":"e-klasse-coupe-c124"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"e-klasse-coupe-c207"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"e-klasse-coupe-c212"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[3,10],"folderName":"e-klasse-t-mod-s124"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[3,10],"folderName":"e-klasse-t-mod-s210"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"e-klasse-t-mod-s211"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"e-klasse-t-mod-s212"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"e-klasse-v-w213"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[7],"folderName":"e-klasse-v-w213-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"e-klasse-v-w213-t-mod-restyling"}',
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[7,4,10],"folderName":"e-klasse-w124"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[7,10],"folderName":"e-klasse-w210"}',
			'{"prodYearStart":2002,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"e-klasse-w211"}',
			'{"prodYearStart":0,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"e-klasse-w212"}',
		],
		'EQA_CLASS': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"eqa"}',
		],
		'EQB_CLASS': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[9,1],"folderName":"eqb"}',
		],
		'EQC_CLASS': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"eqc-i-n293"}',
		],
		'EQE_CLASS': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[7],"folderName":"eqe"}',
		],
		'G_CLASS': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"g-classe-w464"}',
			'{"prodYearStart":1797,"prodYearEnd":1992,"coupeTypes":[1],"folderName":"g-klasse-cabrio-w460-w461"}',
			'{"prodYearStart":1989,"prodYearEnd":2007,"coupeTypes":[2],"folderName":"g-klasse-cabriolet-w463"}',
			'{"prodYearStart":1979,"prodYearEnd":1989,"coupeTypes":[1],"folderName":"g-klasse-w460-w461"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"g-klasse-w463"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"g-klasse-w463-restyling-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"g-klasse-w463-restyling-iii"}',
		],
		'GL_CLASS': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"gl-klasse-x164"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"gla-klasse-ii-x166"}',
		],
		'GLA_CLASS': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"gla-classe-x156-restyling"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"gla-klasse"}',
		],
		'GLB_CLASS': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"glb-classe"}',
		],
		'GLC_CLASS': [
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"glc-klasse-i-x235"}',
		],
		'GLE_CLASS': [
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"gle-i-w166"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"gle-ii-w167"}',
		],
		'GLK_CLASS': [
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"glk-classe-x204-restyling"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"glk-klasse"}',
		],
		'GLS_CLASS': [
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"gls-classe-x166"}',
		],
		'R_CLASS': [
			'{"prodYearStart":2005,"prodYearEnd":2007,"coupeTypes":[5],"folderName":"r-klasse-i"}',
		],
		'S_CLASS': [
			'{"prodYearStart":1981,"prodYearEnd":1991,"coupeTypes":[4,10],"folderName":"s-klasse-coupe-c126"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"s-klasse-coupe-c140"}',
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"s-klasse-coupe-c215"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"s-klasse-maybach"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"s-klasse-vi-w222-c217"}',
			'{"prodYearStart":1972,"prodYearEnd":1980,"coupeTypes":[7,10],"folderName":"s-klasse-w116"}',
			'{"prodYearStart":1979,"prodYearEnd":1991,"coupeTypes":[10,7],"folderName":"s-klasse-w126"}',
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[7,10],"folderName":"s-klasse-w140"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[7,10],"folderName":"s-klasse-w220"}',
			'{"prodYearStart":2005,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"s-klasse-w221"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[4],"folderName":"s-klasse-w222-c127-coupe"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"s-klasse-w222-c127-sedan"}',
		],
		'SL_CLASS': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[2],"folderName":"sl-klasse-v-r320-restyling-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"sl-klasse-vi-r231"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[2],"folderName":"sl-klasse-vi-r231-restyling"}',
		],
		'SLC_CLASS': [
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[2],"folderName":"slc-klasse-i-r172"}',
		],
		'SLK_CLASS': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[2],"folderName":"skl-klasse-ii-r171-restyling"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"slk-klasse-i-r170"}',
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"slk-klasse-i-r170-restiling"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"slk-klasse-ii-r171"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[2],"folderName":"slk-klasse-iii-r172"}',
		],
		'SLR_CLASS': [
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[4],"folderName":"slr-mclaren-c199-coupe"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[2],"folderName":"slr-mclaren-c199-roadster"}',
		],
		'SLS_CLASS': [
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"sls-amg"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"sls-amg-roadster"}',
		],
		'T_CLASS': [
			'{"prodYearStart":1977,"prodYearEnd":1985,"coupeTypes":[10,3],"folderName":"t-mod-s123"}',
			'{"prodYearStart":1985,"prodYearEnd":1996,"coupeTypes":[10,3],"folderName":"t-mod-s124"}',
		],
		'VANEO': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"vaneo-w414"}',
		],
		'VIANO': [
			'{"prodYearStart":0,"prodYearEnd":2010,"coupeTypes":[5,10],"folderName":"viano-639"}',
		],
		'X_CLASS': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[6],"folderName":"x-classe"}',
		],
	},
	'MERCURY': {
		'MARAUDER': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"marauder"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[2],"folderName":"marauder-convertible"}',
		],
		'MILAN': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"milan"}',
		],
		'MOUNTAINEER': [
			'{"prodYearStart":1996,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"mountaineer"}',
		],
		'VILLAGER': [
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[5],"folderName":"villager"}',
		],
	},
	'MG': {
		'MGB': [
			'{"prodYearStart":1966,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"mgb-cabrio"}',
			'{"prodYearStart":1966,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"mgb-gt"}',
		],
		'MGF': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"mgf"}',
		],
		'TF': [
			'{"prodYearStart":2002,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"tf"}',
		],
		'ZR': [
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"zr"}',
		],
		'ZS': [
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"zs"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"zs-hatchback"}',
		],
		'ZT': [
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"zt"}',
			'{"prodYearStart":2002,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"zt-t"}',
		],
	},
	'MICROCAR': {
	},
	'MINI': {
		'CLUBMAN': [
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"clubman-i"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"clubman-i-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"clubman-ii"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[3],"folderName":"clubman-ii-restyling"}',
		],
		'COOPER': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"cooper"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"cooper-cabrio"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"cooper-cabrio-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"cooper-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"cooper-s"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[10],"folderName":"cooper-s-cabrio"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"cooper-s-cabrio-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"cooper-s-ii"}',
		],
		'COUNTRYMAN': [
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"countryman"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"countryman-ii-f60"}',
		],
		'ONE': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"one"}',
			'{"prodYearStart":2007,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"one-cabrio"}',
		],
		'PACEMAN': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"paceman"}',
		],
	},
	'MITSUBISHI': {
		'3000_GT': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"3000-gt-spyder"}',
			'{"prodYearStart":1990,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"3000-gt-z16a"}',
		],
		'ASX': [
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"asx"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"asx-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"asx-restyling-ii"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"asx-restyling-iii"}',
		],
		'CARISMA': [
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[7,10],"folderName":"carisma"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"carisma-hatchback"}',
		],
		'COLT': [
			'{"prodYearStart":1978,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"colt-i-a150"}',
			'{"prodYearStart":1984,"prodYearEnd":1988,"coupeTypes":[10,9],"folderName":"colt-ii-c10"}',
			'{"prodYearStart":1988,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"colt-iii-c50"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"colt-iv-cao"}',
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"colt-v-cjo"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[9,2],"folderName":"colt-vi-z30"}',
		],
		'CORDIA': [
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"cordia-a21_a"}',
		],
		'ECLIPSE': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"eclipse-i-d2_a"}',
			'{"prodYearStart":1995,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"eclipse-ii-d3_"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"eclipse-iii-d30"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"eclipse-iv"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"eclipse-spyder-iii-d30"}',
			'{"prodYearStart":2006,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"eclipse-spyder-iv"}',
		],
		'ECLIPSE_CROSS': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"eclipse-cross"}',
		],
		'GALANT': [
			'{"prodYearStart":1976,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"galant-iii"}',
			'{"prodYearStart":1979,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"galant-iii-wagon"}',
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"galant-iv"}',
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"galant-iv-wagon"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"galant-ix"}',
			'{"prodYearStart":1984,"prodYearEnd":1987,"coupeTypes":[7,10],"folderName":"galant-v"}',
			'{"prodYearStart":1988,"prodYearEnd":1992,"coupeTypes":[10,7],"folderName":"galant-vi"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"galant-vi-hatchback"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"galant-vii"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[9,10],"folderName":"galant-vii-hatchback"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"galant-vii-wagon"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"galant-viii"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[10],"folderName":"galant-viii-restyling"}',
		],
		'GRANDIS': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[5],"folderName":"grandis"}',
		],
		'LANCER': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[10],"folderName":"lancer-cedia"}',
			'{"prodYearStart":1995,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"lancer-evolution-iii"}',
			'{"prodYearStart":2006,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"lancer-evolution-ix"}',
			'{"prodYearStart":1998,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"lancer-evolution-v"}',
			'{"prodYearStart":1999,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"lancer-evolution-vi"}',
			'{"prodYearStart":2001,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"lancer-evolution-vii"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"lancer-evolution-viii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[7],"folderName":"lancer-evolution-x"}',
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"lancer-i"}',
			'{"prodYearStart":1983,"prodYearEnd":1984,"coupeTypes":[7,10],"folderName":"lancer-ii"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"lancer-iii"}',
			'{"prodYearStart":1985,"prodYearEnd":1992,"coupeTypes":[3,10],"folderName":"lancer-iii-wagon"}',
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[7,9,10],"folderName":"lancer-iv"}',
			'{"prodYearStart":1988,"prodYearEnd":1992,"coupeTypes":[9,10],"folderName":"lancer-iv-hatchback"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"lancer-ix"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[3],"folderName":"lancer-ix-wagon"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[9],"folderName":"lancer-sportback-x-gs44s"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[7,10],"folderName":"lancer-v"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"lancer-v-wagon"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"lancer-vi"}',
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"lancer-x"}',
		],
		'MIRAGE': [
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"mirage-asti-cjo"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"mirage-cjo"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"mirage-hatchback"}',
		],
		'MONTERO': [
			'{"prodYearStart":1990,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"montero"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[1],"folderName":"montero-sport"}',
		],
		'OUTLANDER': [
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"outlander"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"outlander-iii"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"outlander-iii-restyling-2"}',
			'{"prodYearStart":2006,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"outlander-iixl"}',
		],
		'PAJERO': [
			'{"prodYearStart":1982,"prodYearEnd":1991,"coupeTypes":[1],"folderName":"pajero-i-canvas-top-l04_g"}',
			'{"prodYearStart":1982,"prodYearEnd":1991,"coupeTypes":[1],"folderName":"pajero-i-l04_gl14_g"}',
			'{"prodYearStart":1990,"prodYearEnd":2000,"coupeTypes":[10,1],"folderName":"pajero-ii-canvas-top-v2_wv4_w"}',
			'{"prodYearStart":1990,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"pajero-ii-metal-top-v2_wv4_w"}',
			'{"prodYearStart":1990,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"pajero-ii-v2_wv4_w"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"pajero-iii"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[1],"folderName":"pajero-io-h60"}',
			'{"prodYearStart":2006,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"pajero-iv"}',
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[1],"folderName":"pajero-jr"}',
			'{"prodYearStart":1994,"prodYearEnd":0,"coupeTypes":[1],"folderName":"pajero-mini"}',
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[1],"folderName":"pajero-pinin-h60"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1,10],"folderName":"pajero-sport-ii"}',
			'{"prodYearStart":1998,"prodYearEnd":2008,"coupeTypes":[1],"folderName":"pajero-sport-k90"}',
		],
		'RVR': [
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[5],"folderName":"rvr-e-n23w"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"rvr-n61w"}',
		],
		'SAPPORO': [
			'{"prodYearStart":1978,"prodYearEnd":1980,"coupeTypes":[10,4],"folderName":"sapporo-i-a12_"}',
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10,4],"folderName":"sapporo-ii-a16_a"}',
			'{"prodYearStart":1987,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"sapporo-iii-e16a"}',
		],
		'SIGMA': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"sigma-f16a"}',
			'{"prodYearStart":1993,"prodYearEnd":1996,"coupeTypes":[3],"folderName":"sigma-station-wagon-f07w"}',
		],
		'SPACE_GEAR': [
			'{"prodYearStart":1995,"prodYearEnd":2007,"coupeTypes":[5],"folderName":"space-gear-pa0"}',
		],
		'SPACE_RUNNER': [
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[5],"folderName":"space-runner-n1_wn2_w"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"space-runner-n50"}',
		],
		'SPACE_STAR': [
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[5],"folderName":"space-star-dg0"}',
		],
		'SPACE_WAGON': [
			'{"prodYearStart":1998,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"space-wagon"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"space-wagon-d0_w"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[5],"folderName":"space-wagon-iii"}',
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[10,5],"folderName":"space-wagon-n3_wn4_w"}',
		],
		'STARION': [
			'{"prodYearStart":1982,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"starion-a18_a"}',
		],
		'TREDIA': [
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"tredia-a21_"}',
		],
	},
	'MOSKVICH': {
		'2138': [
			'{"prodYearStart":1976,"prodYearEnd":1987,"coupeTypes":[7],"folderName":"2138"}',
		],
		'2140': [
			'{"prodYearStart":1976,"prodYearEnd":1988,"coupeTypes":[7],"folderName":"2140"}',
			'{"prodYearStart":1976,"prodYearEnd":1988,"coupeTypes":[7],"folderName":"21406"}',
		],
		'2141': [
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"2141-01"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"214101"}',
			'{"prodYearStart":1987,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"21412-01"}',
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[9],"folderName":"21412-136"}',
		],
		'401': [
			'{"prodYearStart":1954,"prodYearEnd":1956,"coupeTypes":[7],"folderName":"401"}',
		],
		'403': [
			'{"prodYearStart":1962,"prodYearEnd":1965,"coupeTypes":[7],"folderName":"403"}',
		],
		'407': [
			'{"prodYearStart":1958,"prodYearEnd":1963,"coupeTypes":[7],"folderName":"407"}',
		],
		'408': [
			'{"prodYearStart":1964,"prodYearEnd":1969,"coupeTypes":[7],"folderName":"408"}',
		],
		'412': [
			'{"prodYearStart":1967,"prodYearEnd":1969,"coupeTypes":[7],"folderName":"412"}',
		],
		'427': [
			'{"prodYearStart":1975,"prodYearEnd":1976,"coupeTypes":[3],"folderName":"427-combi"}',
		],
	},
	'NISSAN': {
		'100_NX': [
			'{"prodYearStart":1990,"prodYearEnd":1994,"coupeTypes":[4],"folderName":"100-nx-b13"}',
		],
		'200_SX': [
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"200-sx-s13"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"200-sx-s14"}',
		],
		'300_ZX': [
			'{"prodYearStart":1984,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"300-zx-z31"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"300-zx-z32"}',
		],
		'350Z': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[2],"folderName":"350z-roadster-z33"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"350z-z33"}',
		],
		'370Z': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[4],"folderName":"370z"}',
		],
		'ALMERA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"almera-classic-b10"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"almera-i-hatchback-n15"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"almera-i-n15"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"almera-ii-hatchback-n16"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"almera-ii-n16"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[10,5],"folderName":"almera-tino"}',
		],
		'ALTIMA': [
			'{"prodYearStart":1993,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"altima-i"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"altima-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"altima-iii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[4],"folderName":"altima-iv"}',
		],
		'ARMADA': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[1],"folderName":"armada"}',
		],
		'BLUEBIRD': [
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"bluebird-910"}',
			'{"prodYearStart":1974,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"bluebird-b610"}',
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"bluebird-coupe-910"}',
			'{"prodYearStart":1985,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"bluebird-hatchback-t72t12"}',
			'{"prodYearStart":1984,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"bluebird-station-wagon-wu11"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"bluebird-sylphy"}',
			'{"prodYearStart":1985,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"bluebird-t72-t12"}',
			'{"prodYearStart":1980,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"bluebird-traveller-w910"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"bluebird-u11"}',
			'{"prodYearStart":1987,"prodYearEnd":1991,"coupeTypes":[7],"folderName":"bluebird-u12"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[10],"folderName":"bluebird-u13"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[7,10],"folderName":"bluebird-u14"}',
		],
		'CEDRIC': [
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[7],"folderName":"cedric-y30"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"cedric-y32"}',
			'{"prodYearStart":1999,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"cedric-y34"}',
		],
		'CHERRY': [
			'{"prodYearStart":1978,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"cherry-coupe-n10"}',
			'{"prodYearStart":1970,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"cherry-e10"}',
			'{"prodYearStart":1978,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"cherry-hatchback-n10"}',
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"cherry-n12"}',
			'{"prodYearStart":1978,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"cherry-traveller-vn10"}',
		],
		'CUBE': [
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"cube-i"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"cube-ii"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[3],"folderName":"cube-iii"}',
		],
		'FIGARO': [
			'{"prodYearStart":1991,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"figaro"}',
		],
		'FRONTIER': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[6],"folderName":"frontier"}',
		],
		'GT_R': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"gt-r"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[4],"folderName":"gt-r-i-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[4],"folderName":"gt-r-restyling-iii"}',
		],
		'JUKE': [
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"juke"}',
			'{"prodYearStart":2013,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"juke-nismo"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"juke-yf-restyling"}',
		],
		'LAUREL': [
			'{"prodYearStart":1990,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"laurel-e-hc33"}',
			'{"prodYearStart":1997,"prodYearEnd":0,"coupeTypes":[7],"folderName":"laurel-e-hc35e-gc35"}',
			'{"prodYearStart":1977,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"laurel-hlc230"}',
			'{"prodYearStart":1981,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"laurel-jc31"}',
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"laurel-jc32"}',
		],
		'MAXIMA': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"maxima-2009"}',
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"maxima-i-j30"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"maxima-qx-ii-a32"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[7],"folderName":"maxima-qx-iii-a33"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"maxima-qx-iv-a34"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"maxima-station-wagon"}',
		],
		'MICRA': [
			'{"prodYearStart":2002,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"micra-cc-k12"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"micra-iv-restyling"}',
			'{"prodYearStart":1982,"prodYearEnd":1992,"coupeTypes":[10,9],"folderName":"micra-k10"}',
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"micra-k11"}',
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"micra-k12"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"micra-k13"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[9],"folderName":"micra-v"}',
		],
		'MURANO': [
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"murano-iii-z52"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[1],"folderName":"murano-z50"}',
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"murano-z51"}',
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"murano-z51-restyling"}',
		],
		'NAVARA': [
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[6],"folderName":"navara-ii-d22"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[6],"folderName":"navara-iii-d40"}',
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[6],"folderName":"navara-iii-d40-facelift"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[6],"folderName":"navara-iv-d23"}',
		],
		'NOTE': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[9],"folderName":"note"}',
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[9],"folderName":"note-2010"}',
		],
		'PATHFINDER': [
			'{"prodYearStart":1995,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"pathfinder-ii"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[1],"folderName":"pathfinder-iii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[1],"folderName":"pathfinder-iii-2010-facelift"}',
		],
		'PATROL': [
			'{"prodYearStart":1988,"prodYearEnd":1997,"coupeTypes":[10,1],"folderName":"patrol-gr-i-y60"}',
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"patrol-gr-ii-y61"}',
			'{"prodYearStart":1979,"prodYearEnd":1988,"coupeTypes":[10,1],"folderName":"patrol-hardtop-k160"}',
			'{"prodYearStart":1986,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"patrol-hardtop-k260"}',
			'{"prodYearStart":1979,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"patrol-station-wagon-w160"}',
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[10],"folderName":"patrol-station-wagon-w260"}',
		],
		'PRAIRIE': [
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"prairie-m10nm10"}',
			'{"prodYearStart":1988,"prodYearEnd":1998,"coupeTypes":[10,5],"folderName":"prairie-m11"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[5],"folderName":"prairie-m12"}',
		],
		'PRIMERA': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[9,10],"folderName":"primera-hatch-p10"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"primera-hatch-p11"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[9],"folderName":"primera-hatch-p12"}',
			'{"prodYearStart":1990,"prodYearEnd":1997,"coupeTypes":[7,10],"folderName":"primera-p10"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[10,7],"folderName":"primera-p11"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"primera-p12"}',
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"primera-wagon-p10"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[10,3],"folderName":"primera-wagon-p11"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"primera-wagon-p12"}',
		],
		'PULSAR': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"pulsar-n14"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"pulsar-n15"}',
			'{"prodYearStart":2014,"prodYearEnd":null,"coupeTypes":[9],"folderName":"pulsar-vi-c13"}',
		],
		'QASHQAI': [
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"qashkqai-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"qashqai"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"qashqai-2010-facelift"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"qashqai-ii-restyling"}',
			'{"prodYearStart":2008,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"qashqai2"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"qashqai2-2010-facelift"}',
		],
		'QUEST': [
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[5],"folderName":"quest-dn11"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[5],"folderName":"quest-ff-l"}',
		],
		'ROGUE': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"rogue"}',
		],
		'SENTRA': [
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"sentra-s14"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"sentra-s15"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"sentra-vi"}',
		],
		'SERENA': [
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[5,10],"folderName":"serena-c23m"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[5],"folderName":"serena-c24"}',
		],
		'SILVIA': [
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"silvia-s110"}',
			'{"prodYearStart":1984,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"silvia-s12"}',
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[4],"folderName":"silvia-s13"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"silvia-s14"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"silvia-s15"}',
		],
		'SKYLINE': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"skyline-gt-r-x-r34"}',
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"skyline-ix-r33"}',
			'{"prodYearStart":1981,"prodYearEnd":1985,"coupeTypes":[7],"folderName":"skyline-r30"}',
			'{"prodYearStart":1985,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"skyline-r32"}',
			'{"prodYearStart":1985,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"skyline-viii-r32"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"skyline-x-r34"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[7],"folderName":"skyline-xi-r35"}',
		],
		'SUNNY': [
			'{"prodYearStart":1970,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"sunny-140y150y"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[10,7],"folderName":"sunny-b15"}',
			'{"prodYearStart":1982,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"sunny-i-b11"}',
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"sunny-i-coupe-b11"}',
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"sunny-i-wagon-b11"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10,4],"folderName":"sunny-ii-coupe-b12"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10,7],"folderName":"sunny-ii-ga15de-b12"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10,9],"folderName":"sunny-ii-hatchback-n13"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"sunny-ii-n13"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"sunny-iii-hatch-n14"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[9],"folderName":"sunny-iii-hatchback-n14"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"sunny-iii-n14"}',
			'{"prodYearStart":1990,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"sunny-iii-wagon-y10"}',
			'{"prodYearStart":1990,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"sunny-kasten-y10"}',
			'{"prodYearStart":1979,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"sunny-traveller-140y150y"}',
		],
		'TEANA': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"teana"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[7],"folderName":"teana-ii"}',
		],
		'TERRANO': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[1],"folderName":"terrano-i-wd21"}',
			'{"prodYearStart":1993,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"terrano-ii-r20"}',
		],
		'TIIDA': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[9],"folderName":"tiida-hatchback"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[7],"folderName":"tiida-sedan"}',
		],
		'VERSA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"versa"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"versa-sedan"}',
		],
		'X_TRAIL': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"x-trail-i"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"x-trail-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"x-trail-ii-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"x-trail-iii"}',
		],
	},
	'OPEL': {
		'ADAM': [
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[9],"folderName":"adam"}',
		],
		'ADMIRAL': [
			'{"prodYearStart":1969,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"admiral-b"}',
		],
		'AGILA': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[5],"folderName":"agila-i"}',
			'{"prodYearStart":2008,"prodYearEnd":2014,"coupeTypes":[5],"folderName":"agila-ii"}',
		],
		'ANTARA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"antara"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"antara-2011"}',
		],
		'ASCONA': [
			'{"prodYearStart":1970,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"ascona-a"}',
			'{"prodYearStart":1970,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"ascona-a-voyage"}',
			'{"prodYearStart":1975,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"ascona-b"}',
			'{"prodYearStart":1981,"prodYearEnd":1988,"coupeTypes":[10,9,7],"folderName":"ascona-c"}',
			'{"prodYearStart":1981,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"ascona-c-cc"}',
		],
		'ASTRA': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[9,7,10],"folderName":"astra-f"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[10,2],"folderName":"astra-f-cabrio"}',
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[3,10],"folderName":"astra-f-caravan"}',
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[10,9,7],"folderName":"astra-f-cc"}',
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[9,7],"folderName":"astra-g"}',
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"astra-g-cabrio"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"astra-g-caravan"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[9],"folderName":"astra-g-cc"}',
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"astra-g-coupe"}',
			'{"prodYearStart":2006,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"astra-gtc-h"}',
			'{"prodYearStart":2004,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"astra-h"}',
			'{"prodYearStart":2004,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"astra-h-caravan"}',
			'{"prodYearStart":2006,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"astra-h-sedan"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"astra-h-twintop"}',
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"astra-j"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"astra-j-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"astra-k"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"astra-k-caravan"}',
			'{"prodYearStart":2019,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"astra-k-restyling"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[9],"folderName":"astra-l"}',
		],
		'CALIBRA': [
			'{"prodYearStart":1990,"prodYearEnd":1997,"coupeTypes":[4,10],"folderName":"calibra-a"}',
		],
		'CAMPO': [
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[10,6],"folderName":"campo"}',
		],
		'COMBO': [
			'{"prodYearStart":0,"prodYearEnd":2000,"coupeTypes":[10,6],"folderName":"combo"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[5],"folderName":"combo-e"}',
			'{"prodYearStart":2011,"prodYearEnd":2017,"coupeTypes":[5],"folderName":"combo-tour"}',
		],
		'COMMODORE': [
			'{"prodYearStart":1972,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"commodore-b"}',
			'{"prodYearStart":1972,"prodYearEnd":1978,"coupeTypes":[10,4],"folderName":"commodore-b-coupe"}',
			'{"prodYearStart":1978,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"commodore-c"}',
			'{"prodYearStart":1980,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"commodore-c-caravan"}',
		],
		'CORSA': [
			'{"prodYearStart":1982,"prodYearEnd":1993,"coupeTypes":[9,10],"folderName":"corsa-a"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"corsa-b"}',
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[3,10],"folderName":"corsa-b-kasten"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[9,10],"folderName":"corsa-c"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"corsa-d-3-door"}',
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"corsa-d-5-door"}',
			'{"prodYearStart":2010,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"corsa-d-facelift-3-door"}',
			'{"prodYearStart":2010,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"corsa-d-facelift-5-door"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"corsa-e-hatchback-3d"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"corsa-e-hatchback-5d"}',
		],
		'CROSSLAND_X': [
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"crossland-x"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"crossland-x-restyling"}',
		],
		'DIPLOMAT': [
			'{"prodYearStart":1969,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"diplomat-b"}',
		],
		'FRONTERA': [
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"frontera-a"}',
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"frontera-a-sport"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"frontera-b"}',
			'{"prodYearStart":1997,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"frontera-b-sport"}',
		],
		'GRANDLAND_X': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"grandland-x"}',
		],
		'GT': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"gt"}',
		],
		'INSIGNIA': [
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[9,7],"folderName":"insignia-hatchback"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"insignia-ii-hatchback"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7,9],"folderName":"insignia-sedan"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[3,9],"folderName":"insignia-sports-tourer"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[3],"folderName":"insigniq-ii-combi"}',
		],
		'KADETT': [
			'{"prodYearStart":1973,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"kadett-c"}',
			'{"prodYearStart":1973,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"kadett-c-caravan"}',
			'{"prodYearStart":1973,"prodYearEnd":1979,"coupeTypes":[9],"folderName":"kadett-c-city"}',
			'{"prodYearStart":1973,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"kadett-c-coupe"}',
			'{"prodYearStart":1979,"prodYearEnd":1984,"coupeTypes":[10,9],"folderName":"kadett-d"}',
			'{"prodYearStart":1979,"prodYearEnd":1984,"coupeTypes":[10,9],"folderName":"kadett-d-caravan"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[9,10,7],"folderName":"kadett-e"}',
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[10,2],"folderName":"kadett-e-cabrio"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[10,3,9],"folderName":"kadett-e-caravan"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[10,9],"folderName":"kadett-e-cc"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"kadett-e-combo"}',
		],
		'MANTA': [
			'{"prodYearStart":1970,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"manta-a"}',
			'{"prodYearStart":1975,"prodYearEnd":1988,"coupeTypes":[10,4],"folderName":"manta-b"}',
			'{"prodYearStart":1975,"prodYearEnd":1988,"coupeTypes":[10,9],"folderName":"manta-b-cc"}',
		],
		'MERIVA': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[5],"folderName":"meriva-b"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[5],"folderName":"meriva-t3000"}',
		],
		'MOKKA': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"mokka"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"mokka-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"mokka-restyling"}',
		],
		'MONTEREY': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"monterey-a"}',
			'{"prodYearStart":1998,"prodYearEnd":1999,"coupeTypes":[1],"folderName":"monterey-b"}',
		],
		'MONZA': [
			'{"prodYearStart":1978,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"monza-a"}',
		],
		'OMEGA': [
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[7,10,4],"folderName":"omega-a"}',
			'{"prodYearStart":1984,"prodYearEnd":1994,"coupeTypes":[10,3],"folderName":"omega-a-caravan"}',
			'{"prodYearStart":1994,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"omega-b"}',
			'{"prodYearStart":1994,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"omega-b-caravan"}',
		],
		'REKORD': [
			'{"prodYearStart":1972,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"rekord-d"}',
			'{"prodYearStart":1972,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"rekord-d-caravan"}',
			'{"prodYearStart":1972,"prodYearEnd":1977,"coupeTypes":[10,4],"folderName":"rekord-d-coupe"}',
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10,7],"folderName":"rekord-e"}',
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10,7],"folderName":"rekord-e-caravan"}',
		],
		'SENATOR': [
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[10,7],"folderName":"senator-a"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[7,10],"folderName":"senator-b"}',
		],
		'SIGNUM': [
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"signum"}',
		],
		'SINTRA': [
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[5],"folderName":"sintra"}',
		],
		'SPEEDSTER': [
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[2],"folderName":"speedster"}',
		],
		'TIGRA': [
			'{"prodYearStart":1994,"prodYearEnd":2001,"coupeTypes":[4],"folderName":"tigra-a"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[2],"folderName":"tigra-b"}',
		],
		'VECTRA': [
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[7,10],"folderName":"vectra-a"}',
			'{"prodYearStart":1988,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"vectra-a-cc"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"vectra-b"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[3,10],"folderName":"vectra-b-caravan"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[9,3,10],"folderName":"vectra-b-cc"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"vectra-c"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"vectra-c-caravan"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"vectra-c-cc"}',
		],
		'ZAFIRA': [
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"zafira-a-t3000"}',
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[5],"folderName":"zafira-b"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[5],"folderName":"zafira-c"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[5],"folderName":"zafira-c-restyling"}',
		],
	},
	'PEUGEOT': {
		'1007': [
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[5],"folderName":"1007"}',
		],
		'104': [
			'{"prodYearStart":1972,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"104"}',
			'{"prodYearStart":1973,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"104-coupe"}',
		],
		'106': [
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[9,10],"folderName":"106-i-1ac"}',
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"106-ii-1"}',
		],
		'107': [
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"107"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"107-restyling"}',
		],
		'2008': [
			'{"prodYearStart":2014,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"2008"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"2008-ii"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"2008-restyling"}',
		],
		'204': [
			'{"prodYearStart":1965,"prodYearEnd":1977,"coupeTypes":[7,10],"folderName":"204"}',
			'{"prodYearStart":1968,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"204-break"}',
		],
		'205': [
			'{"prodYearStart":1983,"prodYearEnd":1987,"coupeTypes":[10,4],"folderName":"205-i-741ac"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[10,2],"folderName":"205-i-cabrio-741b20d"}',
			'{"prodYearStart":1984,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"205-ii-20ac"}',
		],
		'206': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[9],"folderName":"206"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[2],"folderName":"206-cc"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7,10],"folderName":"206-sedan"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"206-sw"}',
		],
		'207': [
			'{"prodYearStart":2006,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"207"}',
			'{"prodYearStart":2009,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"207-cc"}',
			'{"prodYearStart":2006,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"207-sw"}',
		],
		'208': [
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"208"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"208-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"208-restyling"}',
		],
		'3008': [
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"3008"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"3008-ii"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"3008-ii-restyling"}',
			'{"prodYearStart":2014,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"3008-restyling"}',
		],
		'301': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"301"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[7],"folderName":"301-restyling"}',
		],
		'304': [
			'{"prodYearStart":1969,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"304"}',
			'{"prodYearStart":1970,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"304-break"}',
			'{"prodYearStart":1970,"prodYearEnd":1976,"coupeTypes":[10],"folderName":"304-cabrio"}',
			'{"prodYearStart":1970,"prodYearEnd":1975,"coupeTypes":[10],"folderName":"304-coupe"}',
		],
		'305': [
			'{"prodYearStart":1977,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"305-i-581a"}',
			'{"prodYearStart":1980,"prodYearEnd":1982,"coupeTypes":[10],"folderName":"305-i-break-581d"}',
			'{"prodYearStart":1982,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"305-ii-581m"}',
			'{"prodYearStart":1982,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"305-ii-break-581e"}',
		],
		'306': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[9,7],"folderName":"306-7b"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3,10],"folderName":"306-break-7e"}',
			'{"prodYearStart":1994,"prodYearEnd":2003,"coupeTypes":[2],"folderName":"306-cabrio-7d"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[9,10],"folderName":"306-hatchback-7ac"}',
		],
		'307': [
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"307"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"307-cc"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"307-station-wagon"}',
		],
		'308': [
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"308"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[2],"folderName":"308-cc"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"308-ii"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"308-ii-restyling"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[3],"folderName":"308-ii-sw"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[9],"folderName":"308-iii"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[3],"folderName":"308-restyling-ii-se"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[3],"folderName":"308-sw"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"308-sw-facelift-2011"}',
		],
		'309': [
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[10,9],"folderName":"309-i-10c10a"}',
			'{"prodYearStart":1989,"prodYearEnd":1993,"coupeTypes":[9,10],"folderName":"309-ii-3c3a"}',
		],
		'4007': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"4007"}',
		],
		'4008': [
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"4008"}',
		],
		'405': [
			'{"prodYearStart":1987,"prodYearEnd":1994,"coupeTypes":[10,7],"folderName":"405-i-15b"}',
			'{"prodYearStart":1988,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"405-i-break-15e"}',
			'{"prodYearStart":1992,"prodYearEnd":1995,"coupeTypes":[10,7],"folderName":"405-ii-4b"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[10,3],"folderName":"405-ii-break-4e"}',
		],
		'406': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7,10],"folderName":"406-8"}',
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[3,10],"folderName":"406-break-8"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[10,4],"folderName":"406-coupe-8"}',
		],
		'407': [
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"407"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[4],"folderName":"407-coupe"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[3],"folderName":"407-sw"}',
		],
		'5008': [
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"5008"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"5008-ii"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"5008-ii-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[5],"folderName":"5008-restyling"}',
		],
		'504': [
			'{"prodYearStart":1968,"prodYearEnd":1986,"coupeTypes":[7,10],"folderName":"504"}',
			'{"prodYearStart":1971,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"504-break"}',
			'{"prodYearStart":1974,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"504-cabrio"}',
			'{"prodYearStart":1974,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"504-coupe"}',
			'{"prodYearStart":1980,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"504-pick-up"}',
		],
		'505': [
			'{"prodYearStart":1979,"prodYearEnd":1993,"coupeTypes":[10,7],"folderName":"505-551a"}',
			'{"prodYearStart":1980,"prodYearEnd":1994,"coupeTypes":[7,10,3],"folderName":"505-break-551d"}',
		],
		'508': [
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[7],"folderName":"508"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[7],"folderName":"508-ii"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[3],"folderName":"508-ii-sw"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[1],"folderName":"508-rxh"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"508-sedan-restyling"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[3],"folderName":"508-sw"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"508-sw-restyling"}',
		],
		'604': [
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10,9],"folderName":"604"}',
		],
		'605': [
			'{"prodYearStart":1989,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"605-6b"}',
		],
		'607': [
			'{"prodYearStart":2000,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"607"}',
		],
		'806': [
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"806-221"}',
		],
		'807': [
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"807"}',
		],
		'BIPPER': [
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[5],"folderName":"bipper-tepee"}',
		],
		'ION': [
			'{"prodYearStart":2010,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"ion"}',
		],
		'PARTNER': [
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"partner"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[5],"folderName":"partner-ii"}',
		],
		'RCZ': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[4],"folderName":"rcz"}',
		],
		'RIFTER': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[5],"folderName":"rifter"}',
		],
		'TRAVELER': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[5],"folderName":"traveler-i"}',
		],
	},
	'PLYMOUTH': {
		'ACCLAIM': [
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"acclaim"}',
		],
		'BREEZE': [
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"breeze"}',
		],
		'GRAND_VOYAGER': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[5],"folderName":"grand-voyager"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[5],"folderName":"grand-voyager-ii"}',
		],
		'LASER': [
			'{"prodYearStart":1989,"prodYearEnd":0,"coupeTypes":[4],"folderName":"laser"}',
		],
		'NEON': [
			'{"prodYearStart":1993,"prodYearEnd":2001,"coupeTypes":[4,7],"folderName":"neon"}',
		],
		'PROWLER': [
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"prowler"}',
		],
		'SUNDANCE': [
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[9],"folderName":"sundance"}',
		],
		'VOYAGER': [
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[5],"folderName":"voyager"}',
			'{"prodYearStart":1995,"prodYearEnd":2001,"coupeTypes":[5],"folderName":"voyager-ii"}',
		],
	},
	'PONTIAC': {
		'AZTEC': [
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[1],"folderName":"aztec"}',
		],
		'BONNEVILLE': [
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[7],"folderName":"bonneville"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[7],"folderName":"bonneville-h"}',
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"bonneville-ii"}',
		],
		'FIERO': [
			'{"prodYearStart":1984,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"fiero"}',
		],
		'FIREBIRD': [
			'{"prodYearStart":1982,"prodYearEnd":1992,"coupeTypes":[4],"folderName":"firebird-iii"}',
			'{"prodYearStart":1993,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"firebird-iv"}',
			'{"prodYearStart":1994,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"firebird-iv-cabrio"}',
		],
		'G6': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"g6"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[2],"folderName":"g6-convertible"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[4],"folderName":"g6-coupe"}',
		],
		'GRAND_AM': [
			'{"prodYearStart":1992,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"grand-am-coupe-h"}',
			'{"prodYearStart":1985,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"grand-am-h"}',
		],
		'GRAND_PRIX': [
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[4],"folderName":"grand-prix-coupe-vi-w"}',
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[4],"folderName":"grand-prix-iv-lj"}',
			'{"prodYearStart":1988,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"grand-prix-v-w"}',
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"grand-prix-vi-w"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"grand-prix-_ix_"}',
		],
		'GTO': [
			'{"prodYearStart":2004,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"gto"}',
		],
		'LEMANS': [
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"lemans"}',
		],
		'SOLSTICE': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[2],"folderName":"solstice"}',
		],
		'SUNBIRD': [
			'{"prodYearStart":1975,"prodYearEnd":1985,"coupeTypes":[4],"folderName":"sunbird"}',
		],
		'SUNFIRE': [
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"sunfire-cabrio"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"sunfire-coupe"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"sunfire-sedan"}',
		],
		'TORRENT': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"torrent"}',
		],
		'TRANS_SPORT': [
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[5],"folderName":"trans-sport"}',
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[5],"folderName":"trans-sport-ii"}',
		],
		'VIBE': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[3],"folderName":"vibe"}',
		],
	},
	'PORSCHE': {
		'911': [
			'{"prodYearStart":1963,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"911"}',
			'{"prodYearStart":1988,"prodYearEnd":1993,"coupeTypes":[4,10],"folderName":"911-964"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[4],"folderName":"911-991"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[4],"folderName":"911-991-facelift"}',
			'{"prodYearStart":1993,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"911-993"}',
			'{"prodYearStart":1997,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"911-996"}',
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[4],"folderName":"911-997"}',
			'{"prodYearStart":1982,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"911-cabrio"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[2],"folderName":"911-cabrio-964"}',
			'{"prodYearStart":1994,"prodYearEnd":1997,"coupeTypes":[2,10],"folderName":"911-cabrio-993"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"911-cabrio-996"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"911-cabrio-997"}',
			'{"prodYearStart":1964,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"911-targa"}',
			'{"prodYearStart":1995,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"911-targa-993"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"911-targa-996"}',
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[10],"folderName":"911-turbo-996"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[4],"folderName":"911-turbo-997"}',
		],
		'924': [
			'{"prodYearStart":1975,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"924"}',
		],
		'928': [
			'{"prodYearStart":1977,"prodYearEnd":1995,"coupeTypes":[10,4],"folderName":"928"}',
		],
		'944': [
			'{"prodYearStart":1981,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"944"}',
			'{"prodYearStart":1988,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"944-cabrio"}',
		],
		'968': [
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"968"}',
			'{"prodYearStart":1991,"prodYearEnd":1995,"coupeTypes":[2],"folderName":"968-cabrio"}',
		],
		'BOXSTER': [
			'{"prodYearStart":1996,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"boxster-986"}',
			'{"prodYearStart":2004,"prodYearEnd":2006,"coupeTypes":[4],"folderName":"boxster-987"}',
		],
		'CAYENNE': [
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"cayene-958-facelift"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"cayenne-955"}',
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"cayenne-957-facelift"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"cayenne-958"}',
		],
		'CAYMAN': [
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[4],"folderName":"cayman"}',
		],
		'MACAN': [
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"macan"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"macan-restyling"}',
		],
		'PANAMERA': [
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"panamera"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[4],"folderName":"panamera-i-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"panamera-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"panamera-sport-turismo"}',
		],
	},
	'PROTON': {
	},
	'RENAULT': {
		'11': [
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10,9],"folderName":"11-bc37"}',
		],
		'12': [
			'{"prodYearStart":1969,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"12"}',
			'{"prodYearStart":1970,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"12-variable"}',
		],
		'14': [
			'{"prodYearStart":1976,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"14-121"}',
		],
		'16': [
			'{"prodYearStart":1965,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"16-115"}',
		],
		'18': [
			'{"prodYearStart":1978,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"18-134"}',
			'{"prodYearStart":1979,"prodYearEnd":1986,"coupeTypes":[10,3],"folderName":"18-variable-135"}',
		],
		'19': [
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"19-europa"}',
			'{"prodYearStart":1988,"prodYearEnd":1992,"coupeTypes":[9,10],"folderName":"19-i-bc53"}',
			'{"prodYearStart":1991,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"19-i-cabriolet-d53"}',
			'{"prodYearStart":1989,"prodYearEnd":1992,"coupeTypes":[10,7],"folderName":"19-i-chamade-l53"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"19-ii-bc53"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[2],"folderName":"19-ii-cabriolet-d53"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"19-ii-chamade-l53"}',
		],
		'20': [
			'{"prodYearStart":1975,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"20-127"}',
		],
		'21': [
			'{"prodYearStart":1988,"prodYearEnd":1994,"coupeTypes":[7],"folderName":"21-b48"}',
			'{"prodYearStart":1983,"prodYearEnd":1994,"coupeTypes":[3],"folderName":"21-combi-k48"}',
			'{"prodYearStart":1987,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"21-hatchback-l48"}',
		],
		'25': [
			'{"prodYearStart":1984,"prodYearEnd":1993,"coupeTypes":[9,10],"folderName":"25-b29"}',
		],
		'30': [
			'{"prodYearStart":1975,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"30-127"}',
		],
		'4': [
			'{"prodYearStart":1962,"prodYearEnd":1991,"coupeTypes":[10,9],"folderName":"4"}',
		],
		'5': [
			'{"prodYearStart":1972,"prodYearEnd":1990,"coupeTypes":[10,9],"folderName":"5"}',
		],
		'9': [
			'{"prodYearStart":1981,"prodYearEnd":1989,"coupeTypes":[7,10,9],"folderName":"9-l42"}',
		],
		'ARKANA': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"arkana"}',
		],
		'AVANTIME': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[5],"folderName":"avantime"}',
		],
		'CAPTUR': [
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"captur"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"captur-ii"}',
			'{"prodYearStart":2017,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"capture-restyling"}',
		],
		'CLIO': [
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[9],"folderName":"clio-i-bc575357"}',
			'{"prodYearStart":1990,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"clio-ii-bcsb0"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"clio-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"clio-iv"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"clio-iv-restyling"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[9],"folderName":"clio-renaultsport-197-iii"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"clio-sport-coupe-ii"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"clio-v"}',
		],
		'ESPACE': [
			'{"prodYearStart":1984,"prodYearEnd":1992,"coupeTypes":[10,5],"folderName":"espace-i-j1113"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[0,5],"folderName":"espace-ii-j63"}',
			'{"prodYearStart":1996,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"espace-iii-je"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"espace-iv"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[5],"folderName":"espace-iv-restyling-2"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[5],"folderName":"espace-v"}',
		],
		'FLUENCE': [
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"fluence"}',
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"fluence-facelift-2012"}',
		],
		'FUEGO': [
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10,4],"folderName":"fuego-136"}',
		],
		'KADJAR': [
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"kadjar"}',
		],
		'KANGOO': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[5],"folderName":"kangoo-express-fc"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[5],"folderName":"kangoo-family"}',
			'{"prodYearStart":1998,"prodYearEnd":2000,"coupeTypes":[5,6],"folderName":"kangoo-passenger-kc"}',
		],
		'KOLEOS': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"koleos"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"koleos-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"koleos-restyling"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"koleos-restyling-ii"}',
		],
		'LAGUNA': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"laguna-b56"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"laguna-coupe"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"laguna-grandtour-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[3],"folderName":"laguna-grandtour-iii"}',
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"laguna-grandtour-k56"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"laguna-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"laguna-iii"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"laguna-iii-restyling"}',
		],
		'LATITUDE': [
			'{"prodYearStart":2010,"prodYearEnd":0,"coupeTypes":[7],"folderName":"latitude"}',
		],
		'MEGANE': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"megane-cabriolet-i-ea"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[2],"folderName":"megane-cc-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2],"folderName":"megane-cc-iii"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[7,10],"folderName":"megane-classic-i-la"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"megane-classic-ii"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"megane-coach-i-da"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[4],"folderName":"megane-coach-ii"}',
			'{"prodYearStart":0,"prodYearEnd":2012,"coupeTypes":[4,9],"folderName":"megane-coupe-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[4],"folderName":"megane-coupe-iii-version-2012"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[4],"folderName":"megane-coupe-monaco-gp"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[3],"folderName":"megane-grandetour-iii"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[3,10],"folderName":"megane-grandtour-i-ka"}',
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"megane-grandtour-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[3],"folderName":"megane-grandtour-iii-version-2012"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"megane-i-ba"}',
			'{"prodYearStart":2002,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"megane-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[9],"folderName":"megane-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"megane-iii-version-2012"}',
			'{"prodYearStart":2015,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"megane-iv"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[4],"folderName":"megane-rs-275-trophy"}',
		],
		'MODUS': [
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"modus"}',
		],
		'RAPID': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[6],"folderName":"rapid"}',
		],
		'SAFRANE': [
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"safrane-i-b54"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"safrane-ii-b54"}',
		],
		'SCENIC': [
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[10],"folderName":"grand-scenic"}',
			'{"prodYearStart":2012,"prodYearEnd":2013,"coupeTypes":[5],"folderName":"grand-scenic-collection-2012"}',
			'{"prodYearStart":2012,"prodYearEnd":2013,"coupeTypes":[5],"folderName":"scenic-collection-2012"}',
			'{"prodYearStart":0,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"scenic-i-ja"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[5],"folderName":"scenic-ii"}',
			'{"prodYearStart":2010,"prodYearEnd":2012,"coupeTypes":[5],"folderName":"scenic-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[5],"folderName":"scenic-rx-ja"}',
		],
		'SYMBOL': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"symbol-i"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"symbol-i-restyling"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"symbol-ii"}',
		],
		'TALISMAN': [
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"talisman"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[3],"folderName":"talisman-combi"}',
		],
		'TWINGO': [
			'{"prodYearStart":1993,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"twingo-c06"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"twingo-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"twingo-ii-facelift"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[9],"folderName":"twingo-iii"}',
		],
		'TWIZY': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[4],"folderName":"twixy"}',
		],
		'VEL_SATIS': [
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"vel-satis"}',
		],
		'WIND': [
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[2],"folderName":"wind"}',
		],
		'ZOE': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[9],"folderName":"zoe"}',
		],
	},
	'ROLLS_ROYCE': {
		'GHOST': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[7],"folderName":"ghost"}',
		],
		'PHANTOM': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"phantom"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[7],"folderName":"phantom-extended-wheelbase"}',
		],
		'SILVER_SERAPH': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[7],"folderName":"silver-seraph"}',
		],
		'SILVER_SPUR': [
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"silver-spur"}',
		],
	},
	'ROVER': {
		'200': [
			'{"prodYearStart":1993,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"200-cabrio-xw"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[4,10],"folderName":"200-coupe-xw"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"200-rf"}',
			'{"prodYearStart":1985,"prodYearEnd":1989,"coupeTypes":[7,10],"folderName":"200-xh"}',
			'{"prodYearStart":1989,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"200-xw"}',
		],
		'25': [
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[9],"folderName":"25-rf"}',
		],
		'400': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"400-hatchback-rt"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"400-rt"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[3,10,7],"folderName":"400-tourer-xw"}',
			'{"prodYearStart":1990,"prodYearEnd":1995,"coupeTypes":[7],"folderName":"400-xw"}',
		],
		'45': [
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[9],"folderName":"45-hatchback-rt"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[7],"folderName":"45-rt"}',
		],
		'600': [
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"600-rh"}',
		],
		'75': [
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"75-rj"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"75-tourer"}',
		],
		'800': [
			'{"prodYearStart":1986,"prodYearEnd":1999,"coupeTypes":[10,7],"folderName":"800"}',
			'{"prodYearStart":1992,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"800-coupe"}',
			'{"prodYearStart":1988,"prodYearEnd":1999,"coupeTypes":[10,9],"folderName":"800-hatchback"}',
		],
		'MAESTRO': [
			'{"prodYearStart":1983,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"maestro"}',
		],
		'MONTEGO': [
			'{"prodYearStart":1984,"prodYearEnd":1994,"coupeTypes":[7,10],"folderName":"montego"}',
			'{"prodYearStart":1984,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"montego-estate-xe"}',
		],
	},
	'SAAB': {
		'9_3': [
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[2],"folderName":"9-3-cabriolet-i"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[2],"folderName":"9-3-cabriolet-ii-e"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"9-3-i"}',
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"9-3-sedan-ii-e"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[3],"folderName":"9-3-sport-combi-ii-e"}',
		],
		'9_5': [
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"9-5"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"9-5-wagon"}',
		],
		'9_7X': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[1],"folderName":"9-7x"}',
		],
		'900': [
			'{"prodYearStart":1979,"prodYearEnd":1993,"coupeTypes":[10,9],"folderName":"900-i"}',
			'{"prodYearStart":1986,"prodYearEnd":1994,"coupeTypes":[2],"folderName":"900-i-cabriolet"}',
			'{"prodYearStart":1978,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"900-i-combi-coupe"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[9],"folderName":"900-ii"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[2],"folderName":"900-ii-cabriolet"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[4],"folderName":"900-ii-combi-coupe"}',
		],
		'9000': [
			'{"prodYearStart":1985,"prodYearEnd":1998,"coupeTypes":[10,7],"folderName":"9000"}',
			'{"prodYearStart":1984,"prodYearEnd":1998,"coupeTypes":[10,9],"folderName":"9000-hatchback"}',
		],
	},
	'SATURN': {
		'ASTRA': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[10],"folderName":"astra"}',
		],
		'AURA': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"aura-xr"}',
		],
		'OUTLOOK': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"outlook"}',
		],
		'SKY': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[2],"folderName":"sky"}',
		],
		'VUE': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"vue-i"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"vue-ii"}',
		],
	},
	'SCION': {
		'TC': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[4],"folderName":"tc"}',
		],
		'XA': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[9],"folderName":"xa"}',
		],
		'XB': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"xb-i"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[5],"folderName":"xb-ii"}',
		],
	},
	'SEAT': {
		'ALHAMBRA': [
			'{"prodYearStart":1996,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"alhambra-7ms"}',
		],
		'ALTEA': [
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[5],"folderName":"altea-5p"}',
			'{"prodYearStart":2009,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"altea-freetrack"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[5],"folderName":"altea-xl"}',
		],
		'ARONA': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"arona"}',
		],
		'AROSA': [
			'{"prodYearStart":1997,"prodYearEnd":0,"coupeTypes":[9],"folderName":"arosa-6h"}',
		],
		'ATECA': [
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"ateca"}',
		],
		'CORDOBA': [
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[4],"folderName":"cordoba-coupe-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2003,"coupeTypes":[4],"folderName":"cordoba-coupe-ii"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[10,7],"folderName":"cordoba-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"cordoba-ii"}',
			'{"prodYearStart":2003,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"cordoba-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[3,10],"folderName":"cordoba-vario"}',
			'{"prodYearStart":1996,"prodYearEnd":1999,"coupeTypes":[3,10],"folderName":"cordoba-vario-6k5"}',
		],
		'EXEO': [
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"exeo"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"exeo-st"}',
		],
		'FURA': [
			'{"prodYearStart":1982,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"fura-025a"}',
		],
		'IBIZA': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ibiza-cupra-iv"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ibiza-fr"}',
			'{"prodYearStart":1984,"prodYearEnd":1993,"coupeTypes":[10,9],"folderName":"ibiza-i-021a"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[9,10],"folderName":"ibiza-ii-6k1"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"ibiza-ii-facelift"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"ibiza-iii"}',
			'{"prodYearStart":2008,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"ibiza-iv"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ibiza-sc"}',
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ibiza-st"}',
		],
		'LEON': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[9,10],"folderName":"leon-cupra-i"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"leon-cupra-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[9],"folderName":"leon-cupra-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"leon-i-1m"}',
			'{"prodYearStart":0,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"leon-ii-1p"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"leon-iii"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"leon-iii-restyling"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"leon-iii-st"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[9],"folderName":"leon-iv"}',
		],
		'MALAGA': [
			'{"prodYearStart":1985,"prodYearEnd":1993,"coupeTypes":[7],"folderName":"malaga-023a"}',
		],
		'MARBELLA': [
			'{"prodYearStart":1986,"prodYearEnd":1998,"coupeTypes":[9,10],"folderName":"marbella-28"}',
		],
		'MII': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"mii"}',
		],
		'RONDA': [
			'{"prodYearStart":1982,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"ronda-022a"}',
		],
		'TOLEDO': [
			'{"prodYearStart":1991,"prodYearEnd":1999,"coupeTypes":[7,10],"folderName":"toledo-i-1l"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"toledo-ii-1m2"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"toledo-iii-5p"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"toledo-iv"}',
		],
	},
	'SKODA': {
		'100': [
			'{"prodYearStart":1970,"prodYearEnd":1977,"coupeTypes":[10],"folderName":"100"}',
		],
		'130': [
			'{"prodYearStart":1985,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"130"}',
		],
		'CITIGO': [
			'{"prodYearStart":2011,"prodYearEnd":2017,"coupeTypes":[9,10],"folderName":"citigo-hatchback-3d"}',
			'{"prodYearStart":2011,"prodYearEnd":2017,"coupeTypes":[9,10],"folderName":"citigo-hatchback-5d"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"citigo-restyling-3d"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[9],"folderName":"citigo-restyling-5d"}',
		],
		'ENYAQ': [
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1,9],"folderName":"enyaq"}',
		],
		'FABIA': [
			'{"prodYearStart":1999,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"fabia-i-6y"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"fabia-i-combi-6y"}',
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[9],"folderName":"fabia-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2010,"coupeTypes":[3],"folderName":"fabia-ii-combi"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"fabia-iii"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"fabia-iii-combi"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[9],"folderName":"fabia-iii-restyling"}',
			'{"prodYearStart":1999,"prodYearEnd":2007,"coupeTypes":[7,9],"folderName":"fabia-sedan-i-6y"}',
		],
		'FAVORIT': [
			'{"prodYearStart":1989,"prodYearEnd":2000,"coupeTypes":[10,9],"folderName":"favorit-781"}',
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[6,3,10],"folderName":"favorit-forman-785"}',
		],
		'FELICIA': [
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[9],"folderName":"felicia-i-791"}',
			'{"prodYearStart":1995,"prodYearEnd":1998,"coupeTypes":[10,3],"folderName":"felicia-i-combi-795"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"felicia-i-fun-797"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"felicia-ii"}',
			'{"prodYearStart":1998,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"felicia-ii-combi"}',
		],
		'KAMIQ': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[1],"folderName":"kamiq"}',
		],
		'KAROQ': [
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"karog"}',
		],
		'KODIAQ': [
			'{"prodYearStart":2016,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"kodiaq"}',
		],
		'OCTAVIA': [
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[3,10],"folderName":"octavia-i-combi-tour-1u5"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"octavia-i-tour-1u"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"octavia-ii-1z3"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[3],"folderName":"octavia-ii-combi-1z5"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"octavia-ii-fl-1z3"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[3,9],"folderName":"octavia-ii-fl-combi-1z5"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[3],"folderName":"octavia-iii-combi"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"octavia-iii-liftback"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"octavia-iii-restyling-liftback"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[3],"folderName":"octavia-iii-scout"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[3],"folderName":"octavia-iv-combi"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[7],"folderName":"octavia-iv-liftback"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"octavia-rs-iii"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[3],"folderName":"octavia-scout"}',
		],
		'RAPID': [
			'{"prodYearStart":1983,"prodYearEnd":1991,"coupeTypes":[10,4],"folderName":"rapid-120g130g135g"}',
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"rapid-2012"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[7],"folderName":"rapid-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":2017,"coupeTypes":[7],"folderName":"rapid-liftback"}',
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"rapid-restyling"}',
		],
		'ROOMSTER': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[5],"folderName":"roomster"}',
		],
		'SCALA': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"skala"}',
		],
		'SUPERB': [
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"superb-combi"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"superb-i"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"superb-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"superb-iii-combi"}',
			'{"prodYearStart":2015,"prodYearEnd":2019,"coupeTypes":[7],"folderName":"superb-iii-liftback"}',
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[7],"folderName":"superb-iii-restyling"}',
		],
		'YETI': [
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"yeti"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"yeti-restyling"}',
		],
	},
	'SMART': {
		'FORFOUR': [
			'{"prodYearStart":2004,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"forfour"}',
		],
		'FORTWO': [
			'{"prodYearStart":1999,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"fortwo-cabrio"}',
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"fortwo-coupe"}',
			'{"prodYearStart":2007,"prodYearEnd":2015,"coupeTypes":[2],"folderName":"fortwo-ii-cabrio"}',
			'{"prodYearStart":2007,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"fortwo-ii-coupe"}',
		],
		'ROADSTER': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[2],"folderName":"roadster-cabrio"}',
			'{"prodYearStart":2002,"prodYearEnd":2005,"coupeTypes":[4],"folderName":"roadster-coupe"}',
		],
	},
	'SSANGYONG': {
		'ACTYON': [
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[1],"folderName":"actyon"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"actyon-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":0,"coupeTypes":[1],"folderName":"actyon-ii-restyling"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[6],"folderName":"actyon-sports"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[6],"folderName":"actyon-sports-ii"}',
		],
		'KORANDO': [
			'{"prodYearStart":1997,"prodYearEnd":1999,"coupeTypes":[10],"folderName":"korando-cabrio-kj"}',
			'{"prodYearStart":1988,"prodYearEnd":1996,"coupeTypes":[1],"folderName":"korando-k4"}',
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[1],"folderName":"korando-kj"}',
		],
		'KYRON': [
			'{"prodYearStart":2005,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"kyron"}',
		],
		'MUSSO': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[1],"folderName":"musso-fj"}',
		],
		'REXTON': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"rexton"}',
			'{"prodYearStart":2006,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"rexton-ii"}',
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[1],"folderName":"rexton-iii"}',
		],
	},
	'SUBARU': {
		'BAJA': [
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[6],"folderName":"baja"}',
		],
		'BRZ': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[4],"folderName":"brz"}',
		],
		'FORESTER': [
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[1],"folderName":"forester-i-sf"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[1],"folderName":"forester-ii"}',
			'{"prodYearStart":2008,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"forester-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"forester-iv-sj"}',
			'{"prodYearStart":2015,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"forester-iv-sj-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[3],"folderName":"forester-iv-sj-restyling-ii"}',
			'{"prodYearStart":1018,"prodYearEnd":2021,"coupeTypes":[1,9],"folderName":"forester-v"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"forester-v-restyling"}',
		],
		'IMPREZA': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[4,10],"folderName":"impreza-coupe-i-gfc"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[7,10],"folderName":"impreza-i-gc"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"impreza-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"impreza-iii-hatchback"}',
			'{"prodYearStart":2003,"prodYearEnd":20011,"coupeTypes":[7],"folderName":"impreza-iii-sedan"}',
			'{"prodYearStart":0,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"impreza-iv-hatchback"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"impreza-iv-sedan"}',
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"impreza-station-wagon-i-gf"}',
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[3,10],"folderName":"impreza-station-wagon-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"impreza-v"}',
		],
		'JUSTY': [
			'{"prodYearStart":1984,"prodYearEnd":1995,"coupeTypes":[9,10],"folderName":"justy-i-kad"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"justy-ii-jmams"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[9],"folderName":"justy-iii-nh-g3x"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[9],"folderName":"justy-iv"}',
		],
		'LEGACY': [
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[7],"folderName":"legacy-i-bc"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[3],"folderName":"legacy-i-station-wagon-sw-bjf"}',
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"legacy-ii-bdbg"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"legacy-ii-station-wagon-sw-bdbg"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"legacy-iii-bebh"}',
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[3],"folderName":"legacy-iii-station-wagon-sw-bebh"}',
			'{"prodYearStart":2003,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"legacy-iv"}',
			'{"prodYearStart":2003,"prodYearEnd":2006,"coupeTypes":[3],"folderName":"legacy-iv-station-wagon-sw"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"legacy-v"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"legacy-v-station-wagon-sw"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"legacy-vi"}',
		],
		'LEONE': [
			'{"prodYearStart":1979,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"leone-i-ab"}',
			'{"prodYearStart":1979,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"leone-i-hatchback"}',
			'{"prodYearStart":1979,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"leone-i-station-wagon"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[10,7],"folderName":"leone-ii"}',
			'{"prodYearStart":1984,"prodYearEnd":1994,"coupeTypes":[10],"folderName":"leone-ii-station-wagon"}',
		],
		'LEVORG': [
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[3],"folderName":"levorg"}',
		],
		'LIBERO': [
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[5],"folderName":"libero-bus-e10e12"}',
		],
		'OUTBACK': [
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[3],"folderName":"outback-i"}',
			'{"prodYearStart":1999,"prodYearEnd":2003,"coupeTypes":[3],"folderName":"outback-ii-bebh"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"outback-iii-blbp"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"outback-iv"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"outback-v"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"outback-v-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"outback-vi"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"outback-vi-restyling"}',
		],
		'SVX': [
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[4],"folderName":"svx-cx"}',
		],
		'TREZIA': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"trezia"}',
		],
		'VIVIO': [
			'{"prodYearStart":1992,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"vivio"}',
		],
		'XT': [
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[4],"folderName":"xt-coupe"}',
		],
		'XV': [
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"xv"}',
			'{"prodYearStart":2018,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"xv-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"xv-ii-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"xv-restyling"}',
		],
	},
	'SUZUKI': {
		'ALTO': [
			'{"prodYearStart":1982,"prodYearEnd":1985,"coupeTypes":[10,9],"folderName":"alto-i-ss80"}',
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"alto-ii-ec"}',
			'{"prodYearStart":1994,"prodYearEnd":1998,"coupeTypes":[10],"folderName":"alto-iii-ef"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[9],"folderName":"alto-iv-ej"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"alto-vii"}',
		],
		'BALENO': [
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"baleno-combi-eg"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"baleno-eg"}',
			'{"prodYearStart":1995,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"baleno-hatchback"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[9],"folderName":"baleno-ii"}',
		],
		'FORENZA': [
			'{"prodYearStart":2003,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"forenza"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[3],"folderName":"forenza-wagon"}',
		],
		'GRAND_VITARA': [
			'{"prodYearStart":2000,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"grand-vitara-cabrio"}',
			'{"prodYearStart":1997,"prodYearEnd":2005,"coupeTypes":[1,10],"folderName":"grand-vitara-ftgt"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[1],"folderName":"grand-vitara-iii"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[1],"folderName":"grand-vitara-xl-7-ht"}',
		],
		'IGNIS': [
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"ignis"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[9],"folderName":"ignis-iii"}',
		],
		'JIMNY': [
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"jimny-3th"}',
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[2],"folderName":"jimny-cabrio-3th"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"jimny-cabrio-fj"}',
			'{"prodYearStart":1998,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"jimny-fj"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"jimny-iv-jb64"}',
		],
		'KIZASHI': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"kizashi"}',
		],
		'LIANA': [
			'{"prodYearStart":2002,"prodYearEnd":0,"coupeTypes":[7],"folderName":"liana-sedan-i"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[7],"folderName":"liana-sedan-ii"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[3],"folderName":"liana-wagon-i"}',
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[3],"folderName":"liana-wagon-ii"}',
		],
		'RENO': [
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[9],"folderName":"reno"}',
		],
		'SAMURAI': [
			'{"prodYearStart":1982,"prodYearEnd":1995,"coupeTypes":[10,1],"folderName":"samurai-sj"}',
		],
		'SIDEKICK': [
			'{"prodYearStart":1989,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"sidekick"}',
		],
		'SPLASH': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[9],"folderName":"splash"}',
		],
		'SWIFT': [
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[9],"folderName":"new-swift"}',
			'{"prodYearStart":1989,"prodYearEnd":2000,"coupeTypes":[2],"folderName":"swift-cabrio-sf413"}',
			'{"prodYearStart":1983,"prodYearEnd":1989,"coupeTypes":[9,10],"folderName":"swift-i-aa"}',
			'{"prodYearStart":1989,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"swift-ii-ahaj"}',
			'{"prodYearStart":1989,"prodYearEnd":2000,"coupeTypes":[9],"folderName":"swift-ii-hatchback"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[10,7],"folderName":"swift-iii"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[9],"folderName":"swift-iii-hatchback"}',
			'{"prodYearStart":2004,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"swift-iv"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[9],"folderName":"swift-v"}',
		],
		'SX4': [
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"sx4"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"sx4-facelift"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"sx4-ii"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[1],"folderName":"sx4-ii-restyling"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"sx4-sedan"}',
		],
		'VITARA': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"vitar-ii-restyling"}',
			'{"prodYearStart":1988,"prodYearEnd":1999,"coupeTypes":[2],"folderName":"vitara-cabrio-etta"}',
			'{"prodYearStart":1989,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"vitara-etta"}',
			'{"prodYearStart":2014,"prodYearEnd":2019,"coupeTypes":[1],"folderName":"vitara-ii"}',
		],
		'WAGON_R': [
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[5],"folderName":"wagon-r"}',
		],
		'X_90': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"x-90-el"}',
		],
	},
	'TALBOT': {
		'HORIZON': [
			'{"prodYearStart":1978,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"horizon"}',
		],
		'MURENA': [
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"murena"}',
		],
		'SAMBA': [
			'{"prodYearStart":1981,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"samba-51a"}',
			'{"prodYearStart":1981,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"samba-cabrio-51e"}',
		],
		'SOLARA': [
			'{"prodYearStart":1980,"prodYearEnd":1986,"coupeTypes":[10,7],"folderName":"solara"}',
		],
	},
	'TESLA': {
		'MODEL_S': [
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"model-s"}',
		],
		'ROADSTER': [
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[10],"folderName":"roadster"}',
		],
	},
	'TOYOTA': {
		'4RUNNER': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[1],"folderName":"4runner-i-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2003,"coupeTypes":[1],"folderName":"4runner-iii"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[3],"folderName":"4runner-iv"}',
		],
		'ALPHARD': [
			'{"prodYearStart":2002,"prodYearEnd":2005,"coupeTypes":[5],"folderName":"alphard-i"}',
			'{"prodYearStart":2005,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"alphard-ii"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[5],"folderName":"alphard-iii"}',
		],
		'AURIS': [
			'{"prodYearStart":2007,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"auris"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"auris-facelift-2010"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[9],"folderName":"auris-ii"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[9],"folderName":"auris-ii-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"auris-touring-ii-restyling"}',
		],
		'AVALON': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"avalon"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[7],"folderName":"avalon-ii"}',
		],
		'AVENSIS': [
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[9],"folderName":"avensis-hatch-ii"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[9],"folderName":"avensis-hatch-t22"}',
			'{"prodYearStart":2002,"prodYearEnd":2008,"coupeTypes":[7],"folderName":"avensis-ii"}',
			'{"prodYearStart":2009,"prodYearEnd":2011,"coupeTypes":[9],"folderName":"avensis-iii"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"avensis-iii-restyling"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"avensis-t22"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[5],"folderName":"avensis-verso"}',
			'{"prodYearStart":2002,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"avensis-wagon-ii"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[3],"folderName":"avensis-wagon-iii-restyling"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[3],"folderName":"avensis-wagon-t22"}',
		],
		'AYGO': [
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"aygo"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"aygo-facelift-2009"}',
		],
		'CAMRY': [
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"camry-hatchback-i"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"camry-i"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"camry-ii"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"camry-iii"}',
			'{"prodYearStart":1996,"prodYearEnd":2002,"coupeTypes":[7],"folderName":"camry-iv"}',
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[2],"folderName":"camry-solara-convertible-i-mark-v"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[2],"folderName":"camry-solara-convertible-ii"}',
			'{"prodYearStart":1998,"prodYearEnd":2004,"coupeTypes":[4],"folderName":"camry-solara-i-mark-v"}',
			'{"prodYearStart":2004,"prodYearEnd":2008,"coupeTypes":[4],"folderName":"camry-solara-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"camry-v"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"camry-vi"}',
			'{"prodYearStart":1986,"prodYearEnd":1991,"coupeTypes":[10],"folderName":"camry-wagon-ii"}',
			'{"prodYearStart":1992,"prodYearEnd":1996,"coupeTypes":[3],"folderName":"camry-wagon-iii"}',
		],
		'CARINA': [
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[9],"folderName":"carina-e-hatch-t19"}',
			'{"prodYearStart":1992,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"carina-e-t19"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"carina-e-wagon-t19"}',
			'{"prodYearStart":1989,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"carina-ed"}',
			'{"prodYearStart":1973,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"carina-i-ta1"}',
			'{"prodYearStart":1978,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"carina-i-ta4lta6l"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"carina-ii-hatch-t15"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"carina-ii-hatch-t17"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"carina-ii-t15"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[10,7],"folderName":"carina-ii-t17"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10,7],"folderName":"carina-ii-wagon-t17"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"carina-t21"}',
			'{"prodYearStart":1978,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"carina-wagon-ta4k"}',
		],
		'CELICA': [
			'{"prodYearStart":1987,"prodYearEnd":1990,"coupeTypes":[10],"folderName":"celica-cabrio-t16"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[2],"folderName":"celica-cabrio-t18"}',
			'{"prodYearStart":1994,"prodYearEnd":1999,"coupeTypes":[2],"folderName":"celica-cabrio-t20"}',
			'{"prodYearStart":1985,"prodYearEnd":1990,"coupeTypes":[10,4],"folderName":"celica-t16"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[4],"folderName":"celica-t18"}',
			'{"prodYearStart":1993,"prodYearEnd":1999,"coupeTypes":[4,10],"folderName":"celica-t20"}',
			'{"prodYearStart":1999,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"celica-t23"}',
			'{"prodYearStart":1973,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"celica-ta2"}',
			'{"prodYearStart":1977,"prodYearEnd":1986,"coupeTypes":[10],"folderName":"celica-ta60ra40ra6"}',
		],
		'COROLLA': [
			'{"prodYearStart":2006,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"corolla-300nmc"}',
			'{"prodYearStart":1992,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"corolla-ceres"}',
			'{"prodYearStart":2000,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"corolla-compact"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"corolla-compact-e10"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"corolla-compact-e11"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"corolla-compact-e9"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10,4],"folderName":"corolla-coupe-e8c"}',
			'{"prodYearStart":1975,"prodYearEnd":1980,"coupeTypes":[4],"folderName":"corolla-coupe-ke"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[7],"folderName":"corolla-e10"}',
			'{"prodYearStart":1997,"prodYearEnd":2006,"coupeTypes":[7,3],"folderName":"corolla-e11"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[7],"folderName":"corolla-e12"}',
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"corolla-e7"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"corolla-e8"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[10],"folderName":"corolla-e9"}',
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"corolla-fielder"}',
			'{"prodYearStart":1985,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"corolla-fx-compact-e"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[9],"folderName":"corolla-hatch-e10"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[9],"folderName":"corolla-hatch-e11"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[9],"folderName":"corolla-hatch-e12"}',
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"corolla-hatch-e7"}',
			'{"prodYearStart":1983,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"corolla-hatch-e8"}',
			'{"prodYearStart":1987,"prodYearEnd":1993,"coupeTypes":[10,9],"folderName":"corolla-hatch-e9"}',
			'{"prodYearStart":1976,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"corolla-hatch-kete"}',
			'{"prodYearStart":1972,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"corolla-ke"}',
			'{"prodYearStart":1991,"prodYearEnd":2000,"coupeTypes":[4,7],"folderName":"corolla-levin"}',
			'{"prodYearStart":2001,"prodYearEnd":2006,"coupeTypes":[9],"folderName":"corolla-runx"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[5],"folderName":"corolla-spacio-e11"}',
			'{"prodYearStart":2001,"prodYearEnd":2005,"coupeTypes":[10],"folderName":"corolla-spacio-e12"}',
			'{"prodYearStart":2001,"prodYearEnd":2008,"coupeTypes":[5],"folderName":"corolla-verso-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"corolla-verso-iii"}',
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[3],"folderName":"corolla-wagon-e10"}',
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[3],"folderName":"corolla-wagon-e11"}',
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"corolla-wagon-e12"}',
			'{"prodYearStart":1979,"prodYearEnd":1987,"coupeTypes":[10],"folderName":"corolla-wagon-e7"}',
			'{"prodYearStart":1987,"prodYearEnd":1992,"coupeTypes":[3],"folderName":"corolla-wagon-e9"}',
			'{"prodYearStart":2013,"prodYearEnd":2015,"coupeTypes":[7],"folderName":"corolla-xi-e160-e170"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[7],"folderName":"corolla-xi-e160-restyling"}',
		],
		'CRESSIDA': [
			'{"prodYearStart":1977,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"cressida-rx3"}',
			'{"prodYearStart":1977,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"cressida-wagon-rx3"}',
			'{"prodYearStart":1981,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"cressida-wagon-x6"}',
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"cressida-x6"}',
		],
		'CROWN': [
			'{"prodYearStart":1988,"prodYearEnd":1999,"coupeTypes":[7],"folderName":"crown"}',
			'{"prodYearStart":1991,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"crown-athlete"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[3],"folderName":"crown-estate"}',
			'{"prodYearStart":1991,"prodYearEnd":2004,"coupeTypes":[7,10],"folderName":"crown-majesta"}',
			'{"prodYearStart":1980,"prodYearEnd":1985,"coupeTypes":[10],"folderName":"crown-s1"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[7],"folderName":"crown-s11"}',
			'{"prodYearStart":1987,"prodYearEnd":1999,"coupeTypes":[3],"folderName":"crown-wagon-gs130"}',
			'{"prodYearStart":1980,"prodYearEnd":1983,"coupeTypes":[10],"folderName":"crown-wagon-s1"}',
			'{"prodYearStart":1999,"prodYearEnd":0,"coupeTypes":[3],"folderName":"crown-wagon-s11"}',
		],
		'FJ_CRUISER': [
			'{"prodYearStart":2006,"prodYearEnd":0,"coupeTypes":[1],"folderName":"fj-cruiser"}',
		],
		'HARRIER': [
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[1],"folderName":"harrier"}',
		],
		'HIGHLANDER': [
			'{"prodYearStart":2001,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"highlander-i"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[1],"folderName":"highlander-ii"}',
		],
		'HILUX': [
			'{"prodYearStart":1991,"prodYearEnd":2005,"coupeTypes":[6],"folderName":"hilux-pick-up"}',
			'{"prodYearStart":1989,"prodYearEnd":2002,"coupeTypes":[6,1],"folderName":"hilux-surf"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[6],"folderName":"hilux-viii"}',
		],
		'IQ': [
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[9,10],"folderName":"iq"}',
		],
		'LAND_CRUISER': [
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[1],"folderName":"land-cruiser-100-j10"}',
			'{"prodYearStart":1981,"prodYearEnd":1989,"coupeTypes":[10],"folderName":"land-cruiser-100-j6"}',
			'{"prodYearStart":1984,"prodYearEnd":1997,"coupeTypes":[10,1],"folderName":"land-cruiser-100-j7"}',
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[1],"folderName":"land-cruiser-100-j8"}',
			'{"prodYearStart":1998,"prodYearEnd":2002,"coupeTypes":[1],"folderName":"land-cruiser-100-j9"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[1],"folderName":"land-cruiser-105"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[1,3],"folderName":"land-cruiser-120-prado"}',
			'{"prodYearStart":2009,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"land-cruiser-150-prado"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"land-cruiser-200"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"land-cruiser-200-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"land-cruiser-200-restyling-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"land-cruiser-300"}',
			'{"prodYearStart":1974,"prodYearEnd":1981,"coupeTypes":[1],"folderName":"land-cruiser-40"}',
			'{"prodYearStart":1984,"prodYearEnd":2004,"coupeTypes":[1],"folderName":"land-cruiser-70-hzj70"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[10],"folderName":"land-cruiser-71-hzj71"}',
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[10],"folderName":"land-cruiser-71-lj71g"}',
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"land-cruiser-76-hzj76"}',
			'{"prodYearStart":2007,"prodYearEnd":0,"coupeTypes":[10],"folderName":"land-cruiser-78-hzj78"}',
			'{"prodYearStart":2005,"prodYearEnd":2012,"coupeTypes":[1],"folderName":"land-cruiser-79-hzj79"}',
			'{"prodYearStart":1990,"prodYearEnd":1997,"coupeTypes":[1],"folderName":"land-cruiser-80"}',
			'{"prodYearStart":1987,"prodYearEnd":2002,"coupeTypes":[10,1],"folderName":"land-cruiser-90-prado"}',
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[1,10],"folderName":"land-cruiser-hardtop"}',
		],
		'MATRIX': [
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[3],"folderName":"matrix-i"}',
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[9],"folderName":"matrix-ii"}',
		],
		'PASEO': [
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"paseo-cabrio-_l5_"}',
			'{"prodYearStart":1991,"prodYearEnd":1996,"coupeTypes":[4],"folderName":"paseo-l4"}',
			'{"prodYearStart":1996,"prodYearEnd":2000,"coupeTypes":[4],"folderName":"paseo-l5"}',
		],
		'PICNIC': [
			'{"prodYearStart":2001,"prodYearEnd":2004,"coupeTypes":[10],"folderName":"picnic"}',
			'{"prodYearStart":1996,"prodYearEnd":2001,"coupeTypes":[5],"folderName":"picnic-xm1"}',
		],
		'PREVIA': [
			'{"prodYearStart":2006,"prodYearEnd":2019,"coupeTypes":[5],"folderName":"previa"}',
			'{"prodYearStart":1990,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"previa-cr"}',
		],
		'PRIUS': [
			'{"prodYearStart":1997,"prodYearEnd":2001,"coupeTypes":[7],"folderName":"prius-nhw10"}',
			'{"prodYearStart":2000,"prodYearEnd":2003,"coupeTypes":[7],"folderName":"prius-nhw11-us-spec"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"prius-nhw20"}',
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[7],"folderName":"prius-zvw30"}',
		],
		'RAV4': [
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[10],"folderName":"rav-4-i-cabrio-xa"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[1],"folderName":"rav-4-i-xa"}',
			'{"prodYearStart":2000,"prodYearEnd":2006,"coupeTypes":[1],"folderName":"rav-4-ii"}',
			'{"prodYearStart":2006,"prodYearEnd":2009,"coupeTypes":[1],"folderName":"rav-4-iii"}',
			'{"prodYearStart":2012,"prodYearEnd":2015,"coupeTypes":[1],"folderName":"rav-4-iv"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"rav-4-restyling"}',
		],
		'SEQUOIA': [
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"sequoia-i"}',
			'{"prodYearStart":2008,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"sequoia-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"sequoia-ii-restyling"}',
		],
		'SIENNA': [
			'{"prodYearStart":1997,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"sienna"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[5],"folderName":"sienna-ii"}',
		],
		'STARLET': [
			'{"prodYearStart":1978,"prodYearEnd":1984,"coupeTypes":[9,10],"folderName":"starlet-i-sw-kp6"}',
			'{"prodYearStart":1989,"prodYearEnd":1996,"coupeTypes":[9],"folderName":"starlet-ii-p8"}',
			'{"prodYearStart":1996,"prodYearEnd":0,"coupeTypes":[9],"folderName":"starlet-iii-p9"}',
		],
		'SUPRA': [
			'{"prodYearStart":1986,"prodYearEnd":1993,"coupeTypes":[10,4],"folderName":"supra-a7"}',
			'{"prodYearStart":1993,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"supra-a8"}',
		],
		'TACOMA': [
			'{"prodYearStart":2001,"prodYearEnd":2004,"coupeTypes":[6],"folderName":"tacoma"}',
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[6],"folderName":"tacoma-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"tacoma-ii-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"tacoma-iii"}',
		],
		'TERCEL': [
			'{"prodYearStart":1994,"prodYearEnd":0,"coupeTypes":[10,7],"folderName":"tercel-ac52"}',
			'{"prodYearStart":1982,"prodYearEnd":1988,"coupeTypes":[10,3],"folderName":"tercel-al25"}',
			'{"prodYearStart":1989,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"tercel-el41"}',
			'{"prodYearStart":1979,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"tercel-l1l2"}',
		],
		'TUNDRA': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[6],"folderName":"tundra"}',
		],
		'URBAN_CRUISER': [
			'{"prodYearStart":2009,"prodYearEnd":0,"coupeTypes":[9],"folderName":"urban-cruiser"}',
		],
		'VENZA': [
			'{"prodYearStart":2008,"prodYearEnd":0,"coupeTypes":[1],"folderName":"venza"}',
		],
		'VERSO': [
			'{"prodYearStart":2012,"prodYearEnd":0,"coupeTypes":[0],"folderName":"verso"}',
		],
		'YARIS': [
			'{"prodYearStart":2014,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"yaris-iii-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[9],"folderName":"yaris-iii-restyling-ii"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"yaris-p1"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"yaris-p2"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"yaris-p3"}',
			'{"prodYearStart":1999,"prodYearEnd":2006,"coupeTypes":[5],"folderName":"yaris-verso-p2"}',
		],
	},
	'VW': {
		'ARTEON': [
			'{"prodYearStart":2017,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"arteon-i"}',
		],
		'BORA': [
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"bora-1j2"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"bora-variant-1j6"}',
		],
		'CADDY': [
			'{"prodYearStart":2010,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"caddi-iii-restyling"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"caddy"}',
			'{"prodYearStart":2015,"prodYearEnd":2020,"coupeTypes":[5],"folderName":"caddy-iv"}',
			'{"prodYearStart":2004,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"caddy-maxi-life"}',
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[5],"folderName":"caddy-v"}',
		],
		'CORRADO': [
			'{"prodYearStart":1987,"prodYearEnd":1995,"coupeTypes":[4],"folderName":"corrado-53i"}',
		],
		'DERBY': [
			'{"prodYearStart":1977,"prodYearEnd":1981,"coupeTypes":[10],"folderName":"derby-86"}',
			'{"prodYearStart":1981,"prodYearEnd":1984,"coupeTypes":[10,4],"folderName":"derby-86c"}',
		],
		'EOS': [
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"eos"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[2,10],"folderName":"eos-i-restyling"}',
		],
		'FOX': [
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[9],"folderName":"fox"}',
		],
		'GOLF': [
			'{"prodYearStart":1975,"prodYearEnd":1983,"coupeTypes":[9],"folderName":"golf-i-17"}',
			'{"prodYearStart":1979,"prodYearEnd":1994,"coupeTypes":[10,2],"folderName":"golf-i-cabrio-155"}',
			'{"prodYearStart":1983,"prodYearEnd":1992,"coupeTypes":[10,9],"folderName":"golf-ii-19e"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[9,10],"folderName":"golf-iii-1hx"}',
			'{"prodYearStart":1993,"prodYearEnd":1998,"coupeTypes":[2,10],"folderName":"golf-iii-cabrio1e"}',
			'{"prodYearStart":1992,"prodYearEnd":1999,"coupeTypes":[9,3,10],"folderName":"golf-iii-variant-1hx0"}',
			'{"prodYearStart":1997,"prodYearEnd":2006,"coupeTypes":[9,10],"folderName":"golf-iv-1j1"}',
			'{"prodYearStart":1998,"prodYearEnd":0,"coupeTypes":[2],"folderName":"golf-iv-cabrio-1j"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[3,10],"folderName":"golf-iv-variant-1j5"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"golf-sportsvan"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"golf-v"}',
			'{"prodYearStart":2004,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"golf-v-plus"}',
			'{"prodYearStart":2003,"prodYearEnd":2009,"coupeTypes":[3],"folderName":"golf-v-variant"}',
			'{"prodYearStart":2008,"prodYearEnd":2012,"coupeTypes":[9],"folderName":"golf-vi"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[5],"folderName":"golf-vi-plus"}',
			'{"prodYearStart":2009,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"golf-vi-variant"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"golf-vii"}',
			'{"prodYearStart":2017,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"golf-vii-restyling"}',
		],
		'ID_3': [
			'{"prodYearStart":2019,"prodYearEnd":0,"coupeTypes":[9],"folderName":"id-3"}',
		],
		'ID_4': [
			'{"prodYearStart":2020,"prodYearEnd":0,"coupeTypes":[1],"folderName":"id-4"}',
		],
		'ID_5': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"id-5"}',
		],
		'ID_6': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"id-6"}',
		],
		'JETTA': [
			'{"prodYearStart":1979,"prodYearEnd":1983,"coupeTypes":[10,7],"folderName":"jetta-i-17"}',
			'{"prodYearStart":1984,"prodYearEnd":1991,"coupeTypes":[7,10],"folderName":"jetta-ii-19e"}',
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"jetta-iii"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"jetta-iv"}',
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[3],"folderName":"jetta-iv-wagon"}',
			'{"prodYearStart":2005,"prodYearEnd":2011,"coupeTypes":[7],"folderName":"jetta-v"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7],"folderName":"jetta-vi"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"jetta-vi-restyling"}',
		],
		'LUPO': [
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"lupo-6x"}',
		],
		'MULTIVAN': [
			'{"prodYearStart":1998,"prodYearEnd":2003,"coupeTypes":[5],"folderName":"multivan-t4"}',
			'{"prodYearStart":2003,"prodYearEnd":0,"coupeTypes":[5],"folderName":"multivan-t5"}',
			'{"prodYearStart":2009,"prodYearEnd":2015,"coupeTypes":[5],"folderName":"multivan-t5-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[5],"folderName":"multivan-t6"}',
		],
		'NEW_BEETLE': [
			'{"prodYearStart":2011,"prodYearEnd":0,"coupeTypes":[9],"folderName":"beetle-2011"}',
			'{"prodYearStart":1997,"prodYearEnd":2005,"coupeTypes":[9],"folderName":"new-beetle-9c"}',
			'{"prodYearStart":2005,"prodYearEnd":2010,"coupeTypes":[2],"folderName":"new-beetle-convertible"}',
		],
		'PASSAT': [
			'{"prodYearStart":1973,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"passat-b1"}',
			'{"prodYearStart":1980,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"passat-b2"}',
			'{"prodYearStart":1988,"prodYearEnd":1996,"coupeTypes":[7,10,3],"folderName":"passat-b3-b4"}',
			'{"prodYearStart":1996,"prodYearEnd":2005,"coupeTypes":[7],"folderName":"passat-b5"}',
			'{"prodYearStart":2005,"prodYearEnd":0,"coupeTypes":[7],"folderName":"passat-b6"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[7],"folderName":"passat-b7"}',
			'{"prodYearStart":2014,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"passat-b8"}',
			'{"prodYearStart":2008,"prodYearEnd":2011,"coupeTypes":[4],"folderName":"passat-cc"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"passat-cc-restyling"}',
			'{"prodYearStart":1985,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"passat-hatchback-b2"}',
			'{"prodYearStart":1973,"prodYearEnd":1980,"coupeTypes":[10],"folderName":"passat-variant-b1"}',
			'{"prodYearStart":1980,"prodYearEnd":1989,"coupeTypes":[10,7],"folderName":"passat-variant-b2"}',
			'{"prodYearStart":1988,"prodYearEnd":1997,"coupeTypes":[3,7,10],"folderName":"passat-variant-b3b4"}',
			'{"prodYearStart":1997,"prodYearEnd":2005,"coupeTypes":[3,10],"folderName":"passat-variant-b5"}',
			'{"prodYearStart":2005,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"passat-variant-b6"}',
			'{"prodYearStart":2011,"prodYearEnd":2015,"coupeTypes":[3,7],"folderName":"passat-variant-b7"}',
			'{"prodYearStart":2014,"prodYearEnd":0,"coupeTypes":[3],"folderName":"passsat-variant-b8"}',
		],
		'PHAETON': [
			'{"prodYearStart":2002,"prodYearEnd":2010,"coupeTypes":[7],"folderName":"phaeton"}',
			'{"prodYearStart":2010,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"phaeton-facelift"}',
		],
		'POLO': [
			'{"prodYearStart":1975,"prodYearEnd":1981,"coupeTypes":[9],"folderName":"polo-i-86"}',
			'{"prodYearStart":1985,"prodYearEnd":1988,"coupeTypes":[10],"folderName":"polo-i-classic-86"}',
			'{"prodYearStart":1981,"prodYearEnd":1994,"coupeTypes":[9],"folderName":"polo-ii-86c"}',
			'{"prodYearStart":1981,"prodYearEnd":1994,"coupeTypes":[10,4],"folderName":"polo-ii-coupe-86c"}',
			'{"prodYearStart":1994,"prodYearEnd":2000,"coupeTypes":[9,10],"folderName":"polo-iii-6n6kv"}',
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[7],"folderName":"polo-iii-classic"}',
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"polo-iii-variant"}',
			'{"prodYearStart":1999,"prodYearEnd":2005,"coupeTypes":[9,10],"folderName":"polo-iv-9n"}',
			'{"prodYearStart":2005,"prodYearEnd":2009,"coupeTypes":[9],"folderName":"polo-iv-9n3"}',
			'{"prodYearStart":2004,"prodYearEnd":0,"coupeTypes":[9],"folderName":"polo-iv-fun"}',
			'{"prodYearStart":2009,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"polo-v"}',
			'{"prodYearStart":2014,"prodYearEnd":2017,"coupeTypes":[9],"folderName":"polo-v-restyling"}',
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[9],"folderName":"polo-vi"}',
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[9],"folderName":"polo-vi-restyling"}',
		],
		'SANTANA': [
			'{"prodYearStart":1981,"prodYearEnd":1984,"coupeTypes":[10],"folderName":"santana-32b"}',
		],
		'SCIROCCO': [
			'{"prodYearStart":2008,"prodYearEnd":2014,"coupeTypes":[9],"folderName":"scirocco-3rd"}',
			'{"prodYearStart":1974,"prodYearEnd":1980,"coupeTypes":[4],"folderName":"scirocco-53"}',
			'{"prodYearStart":1980,"prodYearEnd":1992,"coupeTypes":[4],"folderName":"scirocco-53b"}',
		],
		'SHARAN': [
			'{"prodYearStart":1995,"prodYearEnd":2000,"coupeTypes":[5],"folderName":"sharan-7m"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[5],"folderName":"sharan-ii"}',
		],
		'T_CROSS': [
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[1],"folderName":"t-cross"}',
		],
		'T_ROC': [
			'{"prodYearStart":2017,"prodYearEnd":2021,"coupeTypes":[1],"folderName":"t-roc"}',
		],
		'TAIGO': [
			'{"prodYearStart":2021,"prodYearEnd":0,"coupeTypes":[1],"folderName":"taigo"}',
		],
		'TARO': [
			'{"prodYearStart":1989,"prodYearEnd":1997,"coupeTypes":[10],"folderName":"taro"}',
		],
		'TIGUAN': [
			'{"prodYearStart":2007,"prodYearEnd":2011,"coupeTypes":[1],"folderName":"tiguan"}',
			'{"prodYearStart":2011,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"tiguan-i-restyling"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[1],"folderName":"tiguan-ii"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"tiguan-ii-restyling"}',
		],
		'TOUAREG': [
			'{"prodYearStart":2002,"prodYearEnd":2007,"coupeTypes":[1],"folderName":"touareg-7l"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"touareg-7p5"}',
			'{"prodYearStart":2014,"prodYearEnd":2018,"coupeTypes":[1],"folderName":"touareg-ii-restyling"}',
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[10],"folderName":"touareg-iii"}',
		],
		'TOURAN': [
			'{"prodYearStart":2003,"prodYearEnd":2010,"coupeTypes":[5],"folderName":"touran-1t"}',
			'{"prodYearStart":2010,"prodYearEnd":2014,"coupeTypes":[5],"folderName":"touran-2010"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[5],"folderName":"touran-iii"}',
		],
		'UP': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"up-hatchback-3d"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"up-hatchback-5d"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[9],"folderName":"up-i-restyling-5d"}',
		],
		'VENTO': [
			'{"prodYearStart":1991,"prodYearEnd":1998,"coupeTypes":[10,7],"folderName":"vento-1hx0"}',
		],
	},
	'VOLVO': {
		'164': [
			'{"prodYearStart":1968,"prodYearEnd":1974,"coupeTypes":[10],"folderName":"164"}',
		],
		'240': [
			'{"prodYearStart":1974,"prodYearEnd":1993,"coupeTypes":[10,3],"folderName":"240-combi-p245"}',
			'{"prodYearStart":1974,"prodYearEnd":1993,"coupeTypes":[10,7],"folderName":"240-p242p244"}',
		],
		'66': [
			'{"prodYearStart":1975,"prodYearEnd":1978,"coupeTypes":[10],"folderName":"66"}',
			'{"prodYearStart":1975,"prodYearEnd":1979,"coupeTypes":[10],"folderName":"66-combi"}',
		],
		'740': [
			'{"prodYearStart":1983,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"740-744"}',
			'{"prodYearStart":1985,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"740-combi-745"}',
		],
		'760': [
			'{"prodYearStart":1981,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"760-704764"}',
			'{"prodYearStart":1986,"prodYearEnd":1992,"coupeTypes":[10],"folderName":"760-kombi-704765"}',
		],
		'850': [
			'{"prodYearStart":1992,"prodYearEnd":1997,"coupeTypes":[3,10],"folderName":"850-combi-lw"}',
			'{"prodYearStart":1991,"prodYearEnd":1997,"coupeTypes":[7,10],"folderName":"850-ls"}',
		],
		'940': [
			'{"prodYearStart":1988,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"940-944"}',
			'{"prodYearStart":1990,"prodYearEnd":1998,"coupeTypes":[3],"folderName":"940-combi-945"}',
		],
		'960': [
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[7],"folderName":"960-964"}',
			'{"prodYearStart":1990,"prodYearEnd":1996,"coupeTypes":[3],"folderName":"960-kombi-965"}',
		],
		'C30': [
			'{"prodYearStart":0,"prodYearEnd":0,"coupeTypes":[4],"folderName":"c30"}',
		],
		'C70': [
			'{"prodYearStart":1998,"prodYearEnd":2005,"coupeTypes":[2],"folderName":"c70-convertible"}',
			'{"prodYearStart":1997,"prodYearEnd":2002,"coupeTypes":[4],"folderName":"c70-coupe"}',
			'{"prodYearStart":200,"prodYearEnd":2013,"coupeTypes":[4],"folderName":"c70-coupe-cabrio-ii"}',
		],
		'S40': [
			'{"prodYearStart":2004,"prodYearEnd":2012,"coupeTypes":[7],"folderName":"s40-ii"}',
			'{"prodYearStart":1995,"prodYearEnd":2004,"coupeTypes":[7],"folderName":"s40-vs"}',
		],
		'S60': [
			'{"prodYearStart":2000,"prodYearEnd":2009,"coupeTypes":[7],"folderName":"s60"}',
			'{"prodYearStart":2001,"prodYearEnd":0,"coupeTypes":[7],"folderName":"s60-awd"}',
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[7],"folderName":"s60-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":2018,"coupeTypes":[7],"folderName":"s60-ii-restyling"}',
		],
		'S70': [
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[7,10],"folderName":"s70"}',
		],
		'S80': [
			'{"prodYearStart":1998,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"s80"}',
			'{"prodYearStart":2006,"prodYearEnd":2016,"coupeTypes":[7],"folderName":"s80-ii"}',
			'{"prodYearStart":2002,"prodYearEnd":2006,"coupeTypes":[7],"folderName":"s80-limousine"}',
		],
		'S90': [
			'{"prodYearStart":1996,"prodYearEnd":1998,"coupeTypes":[7],"folderName":"s90"}',
			'{"prodYearStart":2016,"prodYearEnd":2020,"coupeTypes":[7],"folderName":"s90-ii"}',
		],
		'V40': [
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[9],"folderName":"v40-2012"}',
			'{"prodYearStart":1995,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"v40-combi-vw"}',
			'{"prodYearStart":2012,"prodYearEnd":2016,"coupeTypes":[1],"folderName":"v40-cross-country"}',
			'{"prodYearStart":2016,"prodYearEnd":2019,"coupeTypes":[9],"folderName":"v40-ii-restyling"}',
		],
		'V50': [
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"v50"}',
			'{"prodYearStart":2007,"prodYearEnd":2012,"coupeTypes":[3],"folderName":"v50-ii"}',
		],
		'V60': [
			'{"prodYearStart":2010,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"v60"}',
			'{"prodYearStart":2013,"prodYearEnd":2016,"coupeTypes":[3],"folderName":"v60-2013-facelift"}',
			'{"prodYearStart":2015,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"v60-cross-country"}',
			'{"prodYearStart":2018,"prodYearEnd":0,"coupeTypes":[3],"folderName":"v60-ii"}',
			'{"prodYearStart":2013,"prodYearEnd":2018,"coupeTypes":[3],"folderName":"v60-restyling"}',
		],
		'V70': [
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3,10],"folderName":"v70-i"}',
			'{"prodYearStart":2000,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"v70-ii"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"v70-iii"}',
			'{"prodYearStart":2000,"prodYearEnd":0,"coupeTypes":[3],"folderName":"v70-xc"}',
		],
		'V90': [
			'{"prodYearStart":1997,"prodYearEnd":2000,"coupeTypes":[3],"folderName":"v90-combi"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[3],"folderName":"v90-cross-country"}',
			'{"prodYearStart":2016,"prodYearEnd":0,"coupeTypes":[3],"folderName":"v90-ii-combi"}',
		],
		'XC40': [
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"xc40"}',
		],
		'XC60': [
			'{"prodYearStart":2005,"prodYearEnd":2013,"coupeTypes":[1],"folderName":"xc60"}',
			'{"prodYearStart":2013,"prodYearEnd":2017,"coupeTypes":[1],"folderName":"xc60-2014-facelift"}',
			'{"prodYearStart":2017,"prodYearEnd":0,"coupeTypes":[1],"folderName":"xc60-ii"}',
		],
		'XC70': [
			'{"prodYearStart":2000,"prodYearEnd":2004,"coupeTypes":[3],"folderName":"xc-70-i"}',
			'{"prodYearStart":2004,"prodYearEnd":2007,"coupeTypes":[3],"folderName":"xc70-i-restyling"}',
			'{"prodYearStart":2007,"prodYearEnd":2013,"coupeTypes":[3],"folderName":"xc70-ii"}',
		],
		'XC90': [
			'{"prodYearStart":2002,"prodYearEnd":2014,"coupeTypes":[3,1],"folderName":"xc90"}',
			'{"prodYearStart":2006,"prodYearEnd":2014,"coupeTypes":[1],"folderName":"xc90-i-restyling"}',
			'{"prodYearStart":2015,"prodYearEnd":0,"coupeTypes":[1],"folderName":"xc90-ii"}',
		],
	},
}

