export const mobileSearchModels = [
    //["AC", ""],
    ["Abarth", "124", "595"],
    ["Acura", "ILX", "Integra", "Mdx", "NSX", "Rdx", "Rl", "Rsx", "Slx", "TLX", "Tl", "Tsx", "ZDX"],
    //["Aixam", "400", "505", "600"],["Alfa Romeo", "145", "146", "147", "155", "156", "156 sportwagon", "159", "159 sportwagon", "164", "166", "33", "4C", "75", "76", "8C Competizione", "90", "Alfetta", "Berlina", "Brera", "Crosswagon q4", "Giulia", "Giulietta", "Gt", "Gtv", "MiTo", "Spider", "Sprint", "Stelvio", "Sud", "Tonale"],
    ["Alpina", "B10", "B11", "B12", "B3", "B4", "B5", "B6", "B7", "B8", "B9", "C1", "C2", "D3", "D4", "D5", "Roadster", "XD3"],
    //["Aro", "10", "24", "242", "243", "244", "246", "32", "320", "324", "328", "33"],["Asia", "Rocsta"],
    ["Aston martin", ".", "DBS", "DBX", "Db7", "Db9", "Rapide", "V12 Vantage", "V8 Vantage", "Vanquish"],
    ["Audi", "100", "200", "50", "60", "80", "90",
        "A1", "A2", "A3", "A4", "A4 Allroad", "A5", "A6", "A6 Allroad", "A7", "A8", "Allroad",
        "Cabriolet", "Coupe", "E-Tron",
        "Q2", "Q3", "Q4", "Q5", "Q7", "Q8", "Quattro",
        "R8", "RSQ8", "Rs3", "Rs4", "Rs5", "Rs6", "Rs7",
        "S1", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "SQ5", "SQ7", "SQ8",
        "Tt"],
    //["Austin", "Allegro", "Ambassador", "Maestro", "Maxi", "Metro", "Mg", "Mini", "Montego", "Princess"]
    ["BMW",
        //"1",
        "114", "116", "118", "120", "123", "125", "128", "130", "135", "140", "1500", "1600", "1602", "1800",
        //"2",
        "2 Active Tourer", "2 Gran Coupe", "2 Gran Tourer", "2000", "2002", "216", "218", "220", "220 d", "225", "228", "230", "235", "240", "2800",
        //"3",
        "315", "316", "318", "320", "323", "324", "325", "328", "330", "335", "340", "3gt",
        //"4",
        "418", "420", "425", "428", "430", "435", "440",
        //"5",
        "5 Gran Turismo", "501", "518", "520", "523", "524", "525", "528", "530", "530E", "535", "540", "545", "550",
        //"6",
        "6 GT", "620", "628", "630", "633", "635", "640", "645", "650",
        "7", "700", "721", "723", "725", "728", "730", "732", "733", "735", "740", "745", "750", "760",
        "840", "850",
        "Izetta",
        //"M",
         "M Coupе", "M135", "M140", "M2", "M3", "M4", "M5", "M6", "M8",
        "X1", "X2", "X3", "X4", "X5", "X5M", "X6", "X7",
        "Z1", "Z3", "Z4", "Z8",
        "i3", "i4", "i7", "i8", "iX", "iX3"],
    ["Bentley", "Arnage", "Azure", "Bentayga", "Continental", "Continental gt", "Flying Spur", "GT Convertible", "Mulsanne", "T-series"],
    ["Berliner", "Coupe"],["Bertone", "Freeclimber"],
    //["Borgward", "Hansa"],
    //["Brilliance", "BC3", "BS2 ", "BS4", "BS6", "Dolphin", "FRV", "FSV", "H220", "H230", "H320", "H330", "H530", "V3", "V5"],
    ["Bugatti", "Chiron", "Veyron"],
    //["Buick", "Century", "Electra", "Enclave", "Invicta", "Park avenue", "Regal", "Rendezvous", "Riviera", "Skylark", "Skyline"],
    ["Cadillac", "ATS", "Allante", "BLS", "Brougham", "CT6", "Cts", "DTS", "Deville", "Eldorado", "Escalade", "Fleetwood", "STS", "Seville", "Srx", "XT4", "XT5", "XTS", "Xlr"],
    //["Carbodies", "Taxi"],
    //["Chery", "Tigo 4", "Tigo 7 Pro", "Tigo 8 pro", "Tigo 8 pro Max"],
    ["Chevrolet", "Alero", "Astro", "Avalanche", "Aveo", "Beretta", "Blazer", "Bolt", "Camaro", "Caprice", "Captiva", "Cavalier", "Cobalt", "Colorado", "Corvette", "Cruze", "Diamante", "El Camino", "Epica", "Equinox", "Evanda", "Gmc", "Hhr", "Impala", "Kalos", "Lacetti", "Lumina", "Malibu", "Matiz", "Niva", "Nova", "Nubira", "Orlando", "Rezzo", "Silverado", "Spark", "Ssr", "Suburban", "Tacuma", "Tahoe", "Tracker", "Trailblazer", "Transsport", "Traverse", "Trax", "Volt"],
    ["Chrysler", "200", "300c", "300m", "Cherokee", "Crossfire", "Daytona", "Es", "Gr.voyager", "Grand cherokee", "Gts", "Interpid", "Lebaron", "Neon", "New yorker", "Pacifica", "Pt cruiser", "Saratoga", "Sebring", "Stratus", "Town and Country", "Vision", "Voyager", "Wrangler"],
    ["Citroen", "2cv", "Ax", "Axel", "Berlingo", "Bx", "C - Zero", "C-Crosser", "C-Elysee", "C1", "C15", "C2", "C3", "C3 Aircross", "C3 Picasso", "C3 pluriel", "C4", "C4 AIRCROSS", "C4 Cactus", "C4 Picasso", "C5", "C5 Aircross", "C5X", "C6", "C8", "Cx", "DS 3 Crossback", "DS 4 Crossback", "DS 7 Crossback", "DS3", "DS4", "DS5", "DS7", "Ds", "E-Mehari", "Evasion", "Grand C4 Picasso", "Gsa", "Gx", "Jumpy", "Ln", "Nemo", "Oltcit", "Saxo", "Spacetourer", "Visa", "Xantia", "Xm", "Xsara", "Xsara picasso", "Zx"],
    ["Corvette", "C06 Convertible", "C06 Coupe", "Powa", "Z06"],
    ["Cupra", "Ateca", "Born", "Formentor"],
    ["DONGFENG", "580", "E3", "IX5"],
    ["DS", "DS 3", "DS 3 Crossback", "DS 4", "DS 4 Crossback", "DS 5", "DS 7", "DS 7 Crossback", "DS 9"],
    ["Dacia", "1100", "1300", "1304", "1307", "1310", "1350", "Dokker", "Duster", "Jogger", "Liberta", "Lodgy", "Logan", "Nova", "Pickup", "Sandero", "Solenza", "Spring"],
    ["Daewoo", "Ace", "Chairman", "Cielo", "Espero", "Evanda", "Fso", "Kalos", "Korando", "Lacetti", "Lanos", "Leganza", "Magnus", "Matiz", "Musso", "Nexia", "Nubira", "Prince", "Racer", "Rezzo", "Super", "Tacuma", "Tico"],
    ["Daihatsu", "Applause", "Charade", "Charmant", "Copen", "Cuore", "Feroza", "Gran move", "Hijet", "Materia", "Move", "Rocky", "Sharade", "Sirion", "Taft", "Terios", "Trevis", "Wildcat", "Yrv"],
    ["Daimler", "Double six", "Six", "Sovereign"],
    ["Datsun", "Bluebird", "Cherry", "Stanza"],
    ["Dkw", "F102"],
    ["Dodge", "Avenger", "Caliber", "Caravan", "Challenger", "Charger", "Coronet", "Dakota", "Dart", "Daytona", "Durango", "Interpid", "Journey", "Magnum", "Neon", "Nitro", "RAM 1500", "RAM 2500", "RAM 3500", "Ram", "Shadow", "Stealth", "Stratus", "Viper"],
    ["Dr", "1", "2", "3", "4", "5", "6", "DR4", "DR6"],
    ["Eagle", "Premire", "Talon", "Vision"],
    ["FSO", "Polonez"],
    ["Ferrari", "296GTB", "308", "348", "360 modena", "360 spider", "458 Italia", "488", "599", "812 GTS", "812 Superfast", "California", "Enzo", "F12berlinetta", "F430", "F456m", "F575m maranello", "F612 scaglietti", "F8", "FF", "GTC4Lusso", "LaFerrari", "Mondial 8", "Portofino", "Roma", "SF 90", "Testarossa"],
    ["Fiat", "1100", "124", "125", "126", "127", "128", "131", "132", "1400", "1500", "1800", "500", "500L", "500X", "600", "650", "750", "Albea", "Argenta", "Barchetta", "Bertone", "Brava", "Bravo", "Campagnola", "Cinquecento", "Coupe", "Croma", "Doblo", "Duna", "Fiorino", "Freemont", "Fullback", "Idea", "Linea", "Marea", "Multipla", "Palio", "Panda", "Punto", "Qubo", "Regata", "Ritmo", "Scudo", "Sedici", "Seicento", "Siena", "Stilo", "Strada", "Tempra", "Tipo", "Topolino", "Ulysse", "Uno"],
    ["Ford", "12m", "15m", "17m", "20m", "Aerostar", "B-Max", "Bronco", "C-max",
        "Capri", "Connect", "Consul", "Cortina", "Cosworth", "Cougar", "Countur", "Courier", "Crown victoria",
        "EcoSport", "Ecoline", "Edge", "Escape", "Escort", "Everest", "Excursion", "Expedition", "Explorer",
        "F150", "F250", "F350", "F450", "F550", "F650", "F750",
        "Fiesta", "Flex", "Focus", "Fusion", "Galaxy", "Granada",
        "Ka", "Kuga", "Maverick", "Mercury", "Mondeo", "Mustang", "Orion",
        "Probe", "Puma", "Ranger", "Raptor", "Rs",
        "S-Max", "Scorpio", "Sierra", "Sportka", "Streetka",
        "Taunus", "Taurus", "Thunderbird", "Windstar", "Zephyr"],
    // ["GOUPIL", "GEM E2", "GEM E4", "GEM E6"],
    ["Gaz", "469", "69"],
    ["Geely", "Atlas", "Atlas Pro", "Coolray", "Tugella"],
    ["Genesis", "G70", "G80", "G90", "GV60", "GV70", "GV80"],
    ["Geo", "Metro", "Prizm", "Storm", "Tracker"],
    ["Gmc", "Acadia", "Canyon", "Envoy", "Jimmy", "Saturn", "Savana", "Sierra", "Sonoma", "Terrain", "Tracker", "Typhoon", "Yukon"],
    ["Gonow", "Gonow"],
    ["Great Wall", "Haval H2", "Haval H6", "Hover Cuv", "Hover H5", "Hover H6", "Poer", "Safe", "Steed 3", "Steed 5", "Steed 6", "Steed 7", "Voleex C10", "Voleex C20", "Voleex C30"],
    // ["Haval", "Dargo", "F7x", "H2", "H6", "Jolion"],
    // ["Heinkel", "Тrojan"],
    // ["Hillman", "Avenger", "Forteen", "Hawk", "Hunter", "Husky", "Imp", "Minx", "Wizard"],
    ["Honda", "Accord", "Cbr", "Cbx", "City", "Civic", "Civic ballade", "Concerto", "Cr-v", "Crosstour", "Crx", "Crz", "Electric", "Element", "Elysion", "Fit", "Fr-v", "Hr-v", "Insight", "Integra", "Jazz", "Legend", "Logo", "Nsx", "Odyssey", "Passport", "Pilot", "Prelude", "Quintet", "Ridgeline", "S2000", "Shuttle", "Stream"],
    // ["Hummer", "H1", "H2", "H3"],
    ["Hyundai", "Accent", "Atos", "Bayon", "Coupe", "Elantra", "Equus", "Excel", "Galloper", "Genesis", "Getz", "Grace", "Grandeur", "I10", "I20", "I30", "I40", "IX35", "IX55", "Ioniq", "Ioniq 5", "Ix20", "Kona", "Landskape", "Lantra", "Matrix", "Palisade", "Pony", "Porter", "S", "Santa fe", "Santamo", "Sonata", "Sonica", "Starex", "Staria", "Stelar", "Tb", "Terracan", "Trajet", "Tucson", "Veloster ", "Veracruz", "Xg"],
    //["Ifa", "F9"],
    ["Infiniti", "Ex30", "Ex35", "Ex37", "Fx 30", "Fx 35", "Fx 37", "Fx 45", "Fx 50", "Fx45", "G", "G coupe", "G sedan", "G37", "I", "J", "M", "Q", "Q30", "Q40", "Q45", "Q50", "QX30", "QX50", "QX56", "QX60", "QX70", "QX80", "Qx", "Qx4"],
    // ["Innocenti", "Mini"],
    // ["Isuzu", "Amigo", "D-max", "Gemini", "Piazza", "Pickup", "Rodeo", "Tfs", "Trooper", "Vehi cross"],
    // ["Iveco", "Massive"],
    // ["JAC", "E-S2", "E-S4", "J7", "JS3", "JS4", "JS7", "T8 PRO"],
    // ["JAS", "E-S2"],
    ["Jaguar", "Daimler", "Daimler double six", "Daimler six", "E-pace", "F-PACE", "F-Type", "I-Pace", "S-type", "Sovereign", "Super v8", "X-type", "XE", "Xf", "Xj", "Xjr", "Xjs", "Xjsc", "Xk8", "Xkr"],
    ["Jeep", "Cherokee", "Commander", "Compass", "Grand Wagoneer", "Grand cherokee", "Patriot", "Renegade", "Wrangler"],
    ["Jpx", "Montez"],
    ["Kia", "Avella delta", "Cadenza", "Carens", "Carnival", "Ceed", "Cerato", "Clarus", "EV6", "Forte", "Joecs", "Joyce", "K5", "K7", "K8", "K9", "Magentis", "Mohave", "Morning", "Niro", "Opirus", "Optima", "Picanto", "Pride", "Pro ceed", "Quoris", "Ray", "Retona", "Rio", "Sedona", "Seltos", "Sephia", "Shuma", "Sorento", "Soul", "Spectra", "Sportage", "Stinger", "Stonic", "Telluride", "Venga", "Visto", "X-Trek", "XCeed"],
    ["Lada", "1200", "1300", "1500", "1600", "2101", "21011", "21012", "21013", "21015", "2102", "2103", "2104", "21043", "2105", "21051", "21053", "2106", "21061", "21063", "2107", "21074", "2108", "21083", "2109", "21093", "21099", "2110", "21213", "Granta", "Kalina", "Niva", "Nova", "Oka", "Priora", "Samara", "Travel", "Urban", "Vesta", "XRAY"],
    ["Laforza", "Magnum"],
    ["Lamborghini", "Aventador", "Countach", "Diablo", "Gallardo", "Huracan", "Murcielago", "Reventon", "Urus", "Veneno"],
    ["Lancia", "A112", "Ardea", "Aurelia", "Beta", "Dedra", "Delta", "Flaminia", "Flavia", "Fulvia", "Kappa", "Lybra", "Musa", "Phedra", "Prisma", "Thema", "Thesis", "Unior", "Voyager", "Y", "Y10", "Ypsilon", "Zeta"],
    ["Land Rover", "Defender", "Discovery", "Freelander", "Range Rover Evoque", "Range Rover Sport", "Range Rover Velar", "Range rover"],
    ["Landwind", "Jx6476da"],
    ["Lexus", "CT200h", "Es", "GX 460", "Gs", "Gx470", "Is", "LC", "LFA", "LS", "Lx", "NX", "RC", "RX330", "Rx", "Rx200t", "Rx300", "Rx350", "Rx400h", "Rx450", "Sc", "UX"],
    ["Lifan", "LF1010", "LF320", "LF520", "LF620", "LF6361", "LF7130", "LF7160", "X60"],
    ["Lincoln", "Continental", "Ls", "MKC", "MKS", "MTK", "Mark", "Mark Lt", "Mkx", "Mkz", "Navigator", "Town car", "Zephyr"],
    ["Lotus", "Elise", "Europe", "Evora", "Exige"],
    ["MG", "GS Exclusive"],
    ["Mahindra", "Armada", "Bolero", "Cl", "Commander", "Goa", "KUV 100", "Marshall", "Quanto", "Scorpio", "XUV 500"],
    ["Maserati", "3200 gt", "Biturbo", "Coupe gt", "Ghibli", "GranCabrio", "GranTurismo", "Gransport", "Grecale", "Levante", "MC20", "Quattroporte", "Spyder", "Zagato"],
    ["Matra", "Murena", "Rancho"],
    ["Maybach", "57", "62", "650", "S 560", "S580"],
    ["Mazda", "121", "2", "3", "323", "5", "6", "626", "929", "B2200", "B2500", "B2600", "BT-50", "CX-30", "CX-5", "CX-60", "CX-7", "CX-9", "Demio", "MX-30", "Mpv", "Mx-3", "Mx-5", "Mx-6", "Premacy", "Rx-7", "Rx-8", "Tribute", "Xedos", "СХ-3"],
    ["McLaren", "540C Coupe", "570S Coupe", "650S", "675LT", "720 S", "F1", "GT", "MP4-12C", "P1"],
    ["Mercedes-Benz",
        "110", "111", "113", "114", "115", "116", "123", "124", "126", "126-260", "150", "170", "180", "190", "200", "220", "230", "240", "250", "260", "280", "290", "300", "320", "350", "380", "420", "450", "500", "560", "600",
        //"A",
        "A 140", "A 150", "A 160", "A 170", "A 180", "A 190", "A 200", "A 210", "A 220", "A 250", "A 35", "A 45", "A45 AMG", "AMG GT", "AMG GT C", "AMG GT R", "AMG GT S", "Adenauer",
        //"B",
        "B 150", "B 160", "B 170", "B 180", "B 200", "B 220", "B 250",
        //"C",
        "C 160", "C 180", "C 200", "C 220", "C 230", "C 240", "C 250", "C 270", "C 280", "C 30 AMG", "C 300", "C 32 AMG", "C 320", "C 350", "C 36 AMG", "C 400", "C 43 AMG", "C 450 AMG", "C 55 AMG", "C 63 AMG",
        //"CL",
        "CL 230", "CL 320", "CL 420", "CL 500", "CL 55 AMG", "CL 600", "CL 63 AMG", "CL 65 AMG",
        //"CLA",
        "CLA 180", "CLA 200", "CLA 220", "CLA 250", "CLA 45 AMG",
        //"CLC",
        "CLC 160", "CLC 180", "CLC 200", "CLC 220", "CLC 230", "CLC 250", "CLC 350",
        //"CLK",
        "CLK 55 AMG", "CLK 63 AMG",
        //"CLS",
        "CLS 250", "CLS 300", "CLS 320", "CLS 350", "CLS 400", "CLS 450", "CLS 500", "CLS 53 AMG", "CLS 55", "CLS 55 AMG", "CLS 550", "CLS 63", "CLS 63 AMG",
        "Citan",
        //"E",
        "E 200", "E 220", "E 230", "E 240", "E 250", "E 260", "E 270", "E 280", "E 290", "E 300", "E 320", "E 350", "E 36 AMG", "E 400", "E 420", "E 43 AMG", "E 430", "E 450", "E 50 AMG", "E 500", "E 53 AMG", "E 55", "E 55 AMG", "E 60", "E 60 AMG", "E 63 AMG",
        "EQA", "EQB", "EQC", "EQE", "EQS", "EQV",
        //"G",
        "G 230", "G 240", "G 250", "G 270", "G 280", "G 290", "G 300", "G 320", "G 350", "G 36 AMG", "G 400", "G 500", "G 55 AMG", "G 63 AMG", "G 65 AMG", "GL", "GL 320", "GL 350", "GL 420", "GL 450", "GL 500", "GL 55 AMG", "GL 63 AMG",
        //"GLA",
        "GLA 180", "GLA 200", "GLA 220", "GLA 250", "GLA 45 AMG",
        "GLB",
        //"GLC",
        "GLC 220", "GLC 250", "GLC 300", "GLC 350", "GLC 400", "GLC 43 AMG", "GLC 63 AMG",
        //"GLE",
        "GLE 250", "GLE 350", "GLE 400", "GLE 43 AMG", "GLE 450 AMG", "GLE 500", "GLE 53 4MATIC", "GLE 580", "GLE 63 AMG", "GLE 63 S AMG", "GLE Coupe",
        "GLK",
        "GLS", "GLS 350", "GLS 400", "GLS 450", "GLS 500", "GLS 600", "GLS 63 AMG", "GLS580",
        "GT", "GTS",
        //"ML",
        "ML 230", "ML 250", "ML 270", "ML 280", "ML 300", "ML 320", "ML 350", "ML 400", "ML 420", "ML 430", "ML 450", "ML 500", "ML 55 AMG", "ML 63 AMG", "Maybach",
        //"R",
        "R 280", "R 300", "R 320", "R 350", "R 500", "R 63 AMG",
        //"S",
        "S 250", "S 280", "S 300", "S 320", "S 350", "S 400", "S 420", "S 430", "S 450", "S 500", "S 55 AMG", "S 550", "S 560", "S 600", "S 63", "S 63 AMG", "S 65", "S 65 AMG", "S580", "S680",
         //"SL",
         "SL 400", "SL 43 AMG", "SL 500", "SL 53 AMG", "SL 55 AMG", "SL 60 AMG", "SL 600", "SL 63 AMG", "SL 65 AMG", "SLC", "SLK", "SLK 32 AMG", "SLK 55 AMG",
         "SLR", "SLS", "SLS AMG",
        "T-класа", "Unimog", "Vaneo", "Viano", "X-Klasse"],
    ["Mercury", "Marauder", "Milan", "Monarch", "Mountaineer", "Villager"],
    ["Mg", "Mga", "Mgb", "Mgf", "Tf", "Zr", "Zs", "Zt", "Zt-t"],
    ["Microcar", "MC1", "MC2", "MGO"],
    ["Mini", "Clubman", "Cooper", "Cooper cabrio", "Cooper s", "Cooper s cabrio", "Countryman", "Coupe", "D one", "John Cooper Works", "One", "One cabrio", "Paceman"],
    ["Mitsubishi", "3000 gt", "ASX", "Attrage", "Carisma", "Colt", "Cordia", "Eclipse", "Eclipse Cross", "Galant", "Grandis", "I-MiEV", "L200", "Lancer", "Mirage", "Montero", "Outlander", "Pajero", "Pajero pinin", "Pajero sport", "RVR", "Sapporo", "Sigma", "Space gear", "Space runner", "Space star", "Space wagon", "Starion", "Tredia"],
    // ["Morgan", "Aero8"],
    ["Moskvich", "1360", "1361", "1500", "2136", "2138", "2140", "2141", "21412", "21417", "2142", "2715", "401", "403", "407", "408", "412", "426", "427", "503", "Aleko", "Иж"],
    ["Nissan", "100 nx", "200 sx", "240 z", "280 z", "300 zx", "350z", "370Z",
        "Acura", "Almera", "Almera tino", "Altima", "Ariya", "Armada", "Bluebird",
        "Cedric", "Cherry", "Cube", "Figaro", "Frontier",
        "Gt-r", "Juke", "Kubistar", "Laurel", "Leaf ", "Maxima", "Micra", "Murano",
        "NP300", "Navara", "Note", "Pathfinder", "Patrol", "Pickup", "Pixo", "Prairie", "Primera", "Pulsar",
        "Qashqai", "Quest", "Rogue", "Sentra", "Serena", "Silvia",
        "Skyline", "Stantza", "Sunny", "Teana", "Terrano", "Tiida", "Titan crew cab", "Titan king", "Versa",
        "X-trail", "Xterra", "e-NV200"],
    // ["Oldsmobile", "Achieva", "Alero", "Aurora", "Bravada", "Cutlass", "Firenza", "Intrigue", "Regency", "Silhouette", "Toronado"],
    ["Opel", "Adam", "Admiral", "Agila", "Ampera", "Antara", "Ascona", "Astra",
        "Calibra", "Campo", "Cascada", "Combo", "Commodore", "Corsa", "Crossland X",
        "Diplomat", "Frontera", "Grandland X", "Gt", "Insignia",
        "Kadett", "Kapitaen", "Karl", "Manta", "Meriva", "Mokka", "Monterey", "Monza",
        "Omega", "Rekord", "Senator", "Signum", "Sintra", "Speedster", "Tigra", "Vectra", "Zafira"],
    // ["Perodua", "Kancil", "Kelisa", "Kembara", "Kenari", "Nippa", "Rusa"],
    ["Peugeot", "1007", "104", "106", "107", "108", "2008", "202", "204", "205", "206", "207", "208", "3008", "301", "304", "305", "306", "307", "308", "309", "4007", "4008", "402", "403", "404", "405", "406", "407", "5008", "504", "505", "508", "604", "605", "607", "806", "807", "Bipper", "Expert", "Partner", "RCZ", "Range", "Rifter", "Traveler", "iOn"],
    ["Pgo", "Cevennes", "Speedster"],
    ["Plymouth", "Acclaim", "Barracuda", "Breeze", "Colt", "Grand voyager", "Horizon", "Laser", "Neon", "Prowler", "Reliant", "Road runner", "Sundance", "Volare", "Voyager"],
    ["Polestar", "Polestar Electric"],
    ["Polonez", "Pickup", "Polestar"],
    ["Pontiac", "Aztec", "Bonneville", "Fiero", "Firebird", "G6", "Grand am", "Grand prix", "Gto", "Lemans", "Solstice", "Sunbird", "Sunfire", "Tempest", "Torrent", "Trans am", "Trans sport", "Vibe"],
    ["Porsche", "356 Speedster", "911", "918 Spyder", "924", "928", "935", "944", "956", "968", "991", "993", "996", "Boxster", "Carrera", "Cayenne", "Cayman", "Macan", "Panamera", "Taycan"],
    ["Proton", "400", "Arena", "Persone", "Satria"],
    // ["Qoros", "3"],
    ["Renault", "10", "11", "12", "14", "16", "18", "19", "20", "21", "25", "29", "30", "4", "5", "8", "9",
        "Alpine", "Arkana", "Avantime", "Bakara", "Bulgar", "Captur", "Caravelle", "Chamade", "Clio",
        "Duster", "Espace", "Express", "Floride", "Fluence", "Fuego", "Grand espace", "Grand scenic",
        "Kadjar", "Kangoo", "Koleos", "Laguna", "Laguna Coupe", "Latitude",
        "Megane", "Modus", "Nevada", "Rapid", "Safrane", "Scenic", "Scenic rx4", "Symbol",
        "Talisman", "Twingo", "Twizy", "Vel satis", "Wind", "Zoe"],
    ["Rieju", "Century", "MR"],
    ["Rolls-Royce", "Cullinan", "Dawn", "Ghost", "Phantom", "Rieju", "Silver Cloud", "Silver Seraph", "Silver Spur", "Wraith"],
    ["Rover", "111", "114", "200", "213", "214", "216", "220", "25", "400", "414", "416", "418", "420", "45", "600", "618", "620", "623", "75", "800", "820", "825", "827", "City", "Estate", "Maestro", "Metro", "Mini", "Montego", "Streetwise"],
    ["SECMA", "F16", "F440DCI", "Fun Buggy", "Fun Extreem", "Fun Lander", "Fun Quad"],
    ["SH auto", "Ceo"],
    ["Saab", "9-3", "9-4X", "9-5", "9-7x", "900", "9000"],
    ["Samand", "LX"],
    ["Santana", "PS10"],
    ["Saturn", "Astra", "Aura", "Outlook", "Sky", "Vue"],
    ["Scion", "Tc", "Xa", "Xb"],
    ["Seat", "Alhambra", "Altea", "Arona", "Arosa", "Ateca", "Cordoba", "Cupra", "Exeo", "Fura", "Ibiza", "Inka", "Leon", "Malaga", "Marbella", "Mii", "Ronda", "Tarraco", "Terra", "Toledo", "Vario"],
    //["Shatenet", "Stella"],
    //["Shuanghuan", "Ceo", "Noble"],
    //["Simca", "1307", "1308", "1309", "1510", "9", "9 Sport", "Aront", "Chrysler", "Horizon", "Shambord", "Solara", "Special", "Versail"],
    ["Skoda", "100", "1000", "105", "120", "125", "130", "135", "136", "Citigo", "Enyaq", "Fabia", "Favorit", "Felicia", "Forman", "Kamiq", "Karoq", "Kodiaq", "Octavia", "Praktik", "Rapid", "Roomster", "Scala", "Superb", "Yeti"],
    ["Smart", "Forfour", "Fortwo", "Mc", "Roadster"],
    ["Ssang yong", "Chairman", "Rodius", "Tivoli"],
    ["SsangYong", "Actyon", "Actyon Sports", "Korando", "Korando Sports", "Kyron", "Musso", "Rexton", "Tivoli", "XLV"],
    ["Subaru", "1800", "Ascent", "B10 Tribeka", "B9 tribeca", "BRZ", "Baja", "E12", "Forester", "G3x justy", "Impreza", "Justy", "Legacy", "Leone", "Levorg", "Libero", "Outback", "Svx", "Trezia", "Vivio", "XT", "XV"],
    ["Suzuki", "Across", "Alto", "Baleno", "Celerio", "Forenza", "Grand vitara", "Ignis", "Jimny", "Kizashi", "Liana", "Maruti", "Reno", "SX4", "SX4 S-Cross", "Samurai", "Santana", "Sg", "Sidekick", "Sj", "Splash", "Swace", "Swift", "Vitara", "Wagon r", "X-90", "XL-7"],
    ["Talbot", "1100", "1310", "Horizon", "Matra", "Murena", "Samba", "Simka", "Solara"],
    //["Tata", "Aria", "Estate", "Indica", "Mint", "Nano", "Safari", "Sierra", "Sumo", "Telcoline", "Xenon"],
    //["Tavria", ".", "Dana", "Kombi", "Slavuta"],
    //["Tazzari", "Zero"],
    //["Tempo", "Gurkha", "Judo"],
    ["Terberg", "Fl2850", "Sl3000"],
    ["Tesla", "Model 3", "Model S", "Model X", "Model Y", "Roadster", "Roadster Sport"],
    //["Tofas", "Dogan", "Kartal", "Sahin"],
    ["Toyota", "4runner", "Alphard", "Auris", "Avalon", "Avensis", "Avensis verso", "Aygo", "C-HR", "Camry", "Carina", "Celica", "Corolla", "Corolla Matrix", "Corolla verso", "Cressida", "Crown", "Fj cruiser", "GT86", "Harrier", "Highlander", "Hilux", "IQ", "Land cruiser", "Matrix", "Mr2", "Paseo", "Picnic", "Previa", "Prius", "Proace City", "Proace City Verso", "Rav4", "Scion", "Sequoia", "Sienna", "Starlet", "Supra", "Tacoma", "Tercel", "Tundra", "Urban Cruiser", "Venza", "Verso", "Verso S", "Yaris", "Yaris verso", "bZ4X"],
    //["Trabant", "600", "601", "Combi", "T 1.1"],
    //["Triumph", "Acclaim", "Dolomite", "Herald", "Spitfire", "Stag", "Tr6", "Tr7"],
    //["Uaz", "2206", "23602 Cargo", "3303", "3741", "390945", "390995", "452", "460", "469", "669", "69", "Expedition", "Hunter", "Patriot", "Pickup", "Профи"],
    //["VROMOS", "Kiwi", "Rhea"],
    ["VW", "1200", "1300", "1302", "1303", "1500", "1600", "Alltrack", "Amarok", "Arteon", "Atlas", "Bora", "CC", "Caddy", "Corrado", "Derby", "Eos", "Fox", "Golf", "Golf Plus", "Golf Variant", "ID.3", "ID.4", "ID.5", "ID.6", "Jetta", "K 70", "Karmann-ghia", "Lupo", "Multivan", "New beetle", "Passat", "Phaeton", "Polo", "Rabbit", "Santana", "Scirocco", "Sharan", "Sportsvan", "T-Cross", "T-Roc", "Taigo", "Taro", "Tiguan", "Touareg", "Touran", "Up", "Vento"],
    //["Volga", "22", "24", "3102", "3110", "3111", "M 20", "M 21", "Siber", "VS"],
    ["Volvo", "142", "144", "145", "164", "1800 es", "240", "244", "245", "262 c", "264", "340", "343", "344", "345", "360", "440", "460", "480", "66", "740", "744", "745", "760", "765", "770", "780", "850", "940", "960", "C30", "C70", "P 1800", "S40", "S60", "S70", "S80", "S90", "V40", "V40 Cross Country", "V50", "V60", "V60 Cross Country", "V70", "V90", "V90 Cross Country", "XC40", "XC60", "Xc70", "Xc90"],
    // ["Warszawa", "223", "232"],
    // ["Wartburg", "1.3", "311", "312", "353"],
    // ["Wiesmann", "Gt", "Mf3", "Mf4", "Mf5"],
    // ["Xinkai", "1021d", "1021ls", "1021s", "2021d", "2021s"],
    // ["Xinshun", "XS-D055"],
    // ["Yogomo", "MA"],
    // ["Zastava", "600", "750", "Florida", "Gt 55", "Koral", "Miami", "Yugo 45"],
    // ["Zaz", "1102", "1103", "1105", "965", "966", "968", "Tavria"],
    // ["Победа", "М"],
    // ["София", "С"],
    // ["Чайка", "М"]
]

export default mobileSearchModels;