import { Link } from 'react-router-dom';

export function About(props: any) {
  return (
    <div>
      <h1>About</h1>
      <p>This is the About page. {props.content}</p>
      <Link to="/not-implemented" className="explore-button">
        Not Implemented page
      </Link>
    </div>
  );
}
