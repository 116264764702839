// React
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Firebase
import { getStorage, ref, getDownloadURL, listAll  } from "firebase/storage";

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';

// Custom components
import Chart from '../chart/Chart';
import { ExpensesCalculator } from '../calculator/Calculator';

// Mappings
import * as mobileMap from '../../mappings/mobile_mappings';
import * as adMap from '../../mappings/ad_mappings';

// Styles
import './Information.css';

function reviver(key: any, value: any) {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

function findBrandKey(make: any) {
  let makeUpper = make.toUpperCase().replaceAll('-', '_');
  if (mobileMap.MOBILE_BRANDS[makeUpper as keyof Object]) {
    return makeUpper;
  }

  console.error('cant find brand');
  return '';
}

function findModelKey(makeKey: any, model: any) {
  let modelUpper = model.toUpperCase().replaceAll(' ', '_');
  if (mobileMap.MOBILE_MODELS[makeKey as keyof Object][modelUpper as keyof Object]) {
    return modelUpper;
  }

  for (const o in mobileMap.MOBILE_VARIATIONS[makeKey as keyof Object]) {
    const variations : any = mobileMap.MOBILE_VARIATIONS[makeKey as keyof Object][o as keyof Object];
    for (let i = 0; i < variations.length; i++) {
      if (model == variations[i]) {
        return o;
      }
    }
  }

  console.error('Cannot find model');
  return '';
}

export function Information(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  let [average, setAverage] = useState('');
  let [plotData, setPlotData] = useState(new Array());
  let [info, setInfo] = useState(new Map());
  let [activeEngine, setActiveEngine] = useState({});
  let [activeGeneration, setActiveGeneration] = useState('overall');

  let obj = {
    make: searchParams.get('make'),
    model: searchParams.get('model'),
  }

  const SetActiveEngineWrap = (generationKey: any) => {
    setActiveGeneration(generationKey);
    setActiveEngine(info.get(generationKey));

    if (!generationKey) {
      let mostRecentMean = plotData[plotData.length - 1].data.get('overall');
      setAverage(Math.round(mostRecentMean.mean).toLocaleString());
      return;
    }

    let gen = generationKey.split('/')[0];
    let variationKey = generationKey.split('/')[1];
    let genMap = plotData[plotData.length - 1].data.get(gen);
    let variaton = genMap.get(variationKey);

    let total = 0;
    let count = 0;
    for (const [k, value] of variaton.engineGearboxSplitInfo) {
      total += value.total;
      count += value.count;
    }
    setAverage(Math.round(total / count).toLocaleString());
  };

  const images = [
    'mazda_mx5.jpg',
    'mazda_mx5.jpg'
  ];

  const storage = getStorage();

  useEffect(()=>{
    let promises: any[] = [];
    let means: any[] = [];

    listAll(ref(storage, `prices/`)).then((res)=>{
      res.prefixes.forEach((folderRef) => {
        //console.log(folderRef.name);
        let promise = getDownloadURL(ref(storage, `prices/${folderRef.name}/${obj.make}/${obj.model}/filtered_data_v2.json`))
        .then((url) => {
          //console.log(`DOWLOADING: ${url}`);
          return fetch(url);
        })
        .then((response) => {
          return response.text()
        })
        .then((data) => {
          //console.log(folderRef.name);
          // Folder name format mobile-<day>-<month>-<year>_<hour>-<minutes>
          const splitDateString = folderRef.name.split('_')[0].split('-');
          let mapObj = JSON.parse(data, reviver);
          means.push({
            date: new Date(Number(splitDateString[3]), Number(splitDateString[2]) - 1, Number(splitDateString[1])),
            data: mapObj,
            folderName: folderRef.name
          });
        })
        .catch((error) => {
          console.error('Error:', error);
        });

        promises.push(promise);
      });
      return Promise.all(promises);
    }).then((res)=>{
      if (!means.length) {
        return;
      }

      means.sort(function(a, b) {
        return a.date - b.date;
      });

      let mostRecentMean = means[means.length - 1].data.get('overall');
      setAverage(Math.round(mostRecentMean.mean).toLocaleString());

      while (means.length < 12) {
        means.unshift(means[0])
      }
      setPlotData(means);

      const brandkey = findBrandKey(obj.make);
      const modelKey = findModelKey(brandkey, obj.model);

      const lastData = means[means.length - 1].data;
      let generations = [...lastData.keys()];
      generations = generations.slice(0, -1);

      for (let i = 0; i < generations.length; i++) {
        let variations = [...lastData.get(generations[i]).keys()];
        for (let j = 0; j < variations.length; j++) {
          const path = [
            'data/',
            adMap.DATA_BRANDS[brandkey as keyof Object],
            adMap.DATA_MODELS[brandkey as keyof Object][modelKey as keyof Object],
            generations[i],
            variations[j],
            'parsed.json'
          ]
          const filename = path.join('/');
          getDownloadURL(ref(storage, filename))
              .then((url) => {
                //console.log(`DOWLOADING: ${url}`);
                return fetch(url);
              })
              .then((response) => {
                return response.text()
              })
              .then((data) => {
                let dataInfo = JSON.parse(data);
                let k = `${generations[i]}/${variations[j]}`;

                if (dataInfo && !dataInfo.combinedConsumption) {
                  let extraUrban = 0;
                  if (dataInfo.extraUrbanConsumption) {
                      extraUrban = Number(dataInfo.extraUrbanConsumption.split(' ')[0]);
                  }

                  let urban = 0;
                  if (dataInfo.urbanConsumption) {
                      urban = Number(dataInfo.urbanConsumption.split(' ')[0]);
                  }

                  dataInfo.combinedConsumption = `${(extraUrban + urban) / 2} Liters/100 km`;
              }


                if (!dataInfo.emissionStandard) {
                  dataInfo.emissionStandard = 'EURO IV';
                }

                setInfo(new Map(info.set(k, dataInfo)));
              })
              .catch((error) => {
                console.error('Error:', error);
              });
        }
      }
    })
  }, []);

  return (
    <div>
      <div style={{display: 'block', width: '100%', textAlign: 'center'}}>Information page {obj.make} {obj.model}.</div>
      <div style={{display: 'block', width: '100%', textAlign: 'center'}}>Current average is {average} лв.</div>

      <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Carousel style={{width: '70%', margin: 15}}>
          {images.map((link: string, index: number)=>{
              return  <Carousel.Item key={index}>
                        <img className="d-block w-100" src={link} alt={link} />
                      </Carousel.Item>
          })}
        </Carousel>
        <Chart make={obj.make} model={obj.model} data={plotData} generation={activeGeneration}/>
      </Container>
      <div>Details:</div>
      <Container style={{display: 'flex'}}>
        <Container style={{width: '50%', margin: 0, fontSize: 15}}>
          <Accordion defaultActiveKey="0"
                      onSelect={(k)=>{ SetActiveEngineWrap(k)}
            }>
              {[...info.keys()].map((k: string, i: number) => {
                  let obj = info.get(k);
                  return <Accordion.Item eventKey={k} key={i}>
                          <Accordion.Header>Engine (mod. {obj.productionStartYear}): {obj.engine}</Accordion.Header>
                          <Accordion.Body>
                              <p>Production years: {obj.productionStartYear} - {obj.productionEndYear}</p>
                              <p>Power (hp) is: {obj.power} {"\n"}</p>
                              <p>Fuel system: {obj.fuelType}, {obj.fuelSystem}</p>
                              <p>Weight is: {obj.weight}</p>
                              <p>Dimensions: L: {obj.length}, W: {obj.width}, H: {obj.height}</p>
                              <p>Urban consumption is: {obj.urbanConsumption}</p>
                              <p>Extra urban consumption is: {obj.extraUrbanConsumption}</p>
                              <p>Combined consumption is: {obj.combinedConsumption}</p>
                              <p>Emission standard: {obj.emissionStandard}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                })}
          </Accordion>
        </Container>
        <Container style={{width: '50%', margin: 0, fontSize: 15}}>
          <ExpensesCalculator engine={activeEngine} currentPrice={average}/>
        </Container>
      </Container>
    </div>
  );
}