export const MOBILE_BRANDS : {[key: string] : string} = {
	ABARTH: 'Abarth',
	ACURA: 'Acura',
	ALFA_ROMEO: 'Alfa Romeo',
	ALPINA: 'Alpina',
	ASTON_MARTIN: 'Aston martin',
	AUDI: 'Audi',
	BMW: 'BMW',
	BYD: 'BYD',
	BENTLEY: 'Bentley',
	BERLINER: 'Berliner',
	BERTONE: 'Bertone',
	BUGATTI: 'Bugatti',
	CADILLAC: 'Cadillac',
	CHEVROLET: 'Chevrolet',
	CHRYSLER: 'Chrysler',
	CITROEN: 'Citroen',
	CORVETTE: 'Corvette',
	CUPRA: 'Cupra',
	DONGFENG: 'DONGFENG',
	DS: 'DS',
	DACIA: 'Dacia',
	DAEWOO: 'Daewoo',
	DAIHATSU: 'Daihatsu',
	DAIMLER: 'Daimler',
	DATSUN: 'Datsun',
	DKW: 'Dkw',
	DODGE: 'Dodge',
	DR: 'Dr',
	EAGLE: 'Eagle',
	FSO: 'FSO',
	FERRARI: 'Ferrari',
	FIAT: 'Fiat',
	FORD: 'Ford',
	GAZ: 'Gaz',
	GEELY: 'Geely',
	GENESIS: 'Genesis',
	GEO: 'Geo',
	GMC: 'Gmc',
	GONOW: 'Gonow',
	GREAT_WALL: 'Great Wall',
	HONDA: 'Honda',
	HYUNDAI: 'Hyundai',
	INFINITI: 'Infiniti',
	JAGUAR: 'Jaguar',
	JEEP: 'Jeep',
	JPX: 'Jpx',
	KIA: 'Kia',
	LADA: 'Lada',
	LAFORZA: 'Laforza',
	LAMBORGHINI: 'Lamborghini',
	LANCIA: 'Lancia',
	LAND_ROVER: 'Land Rover',
	LANDWIND: 'Landwind',
	LEXUS: 'Lexus',
	LIFAN: 'Lifan',
	LINCOLN: 'Lincoln',
	LOTUS: 'Lotus',
	MAHINDRA: 'Mahindra',
	MASERATI: 'Maserati',
	MATRA: 'Matra',
	MAYBACH: 'Maybach',
	MAZDA: 'Mazda',
	MCLAREN: 'McLaren',
	MERCEDES_BENZ: 'Mercedes-Benz',
	MERCURY: 'Mercury',
	MG: 'Mg',
	MICROCAR: 'Microcar',
	MINI: 'Mini',
	MITSUBISHI: 'Mitsubishi',
	MOSKVICH: 'Moskvich',
	NISSAN: 'Nissan',
	OPEL: 'Opel',
	PEUGEOT: 'Peugeot',
	PGO: 'Pgo',
	PLYMOUTH: 'Plymouth',
	POLESTAR: 'Polestar',
	POLONEZ: 'Polonez',
	PONTIAC: 'Pontiac',
	PORSCHE: 'Porsche',
	PROTON: 'Proton',
	RENAULT: 'Renault',
	RIEJU: 'Rieju',
	ROLLS_ROYCE: 'Rolls-Royce',
	ROVER: 'Rover',
	SECMA: 'SECMA',
	SH_AUTO: 'SH auto',
	SAAB: 'Saab',
	SAMAND: 'Samand',
	SANTANA: 'Santana',
	SATURN: 'Saturn',
	SCION: 'Scion',
	SEAT: 'Seat',
	SKODA: 'Skoda',
	SMART: 'Smart',
	SSANG_YONG: 'Ssang yong',
	SSANGYONG: 'SsangYong',
	SUBARU: 'Subaru',
	SUZUKI: 'Suzuki',
	TALBOT: 'Talbot',
	TERBERG: 'Terberg',
	TESLA: 'Tesla',
	TOYOTA: 'Toyota',
	VW: 'VW',
	VOLVO: 'Volvo',
}

export const MOBILE_MODELS : { [key: string] : {[key: string] : string} } = {
	ABARTH: {
		'124': '124',
		'595': '595',
	},
	ACURA: {
		'ILX': 'ILX',
		'INTEGRA': 'Integra',
		'MDX': 'Mdx',
		'NSX': 'NSX',
		'RDX': 'Rdx',
		'RL': 'Rl',
		'RSX': 'Rsx',
		'SLX': 'Slx',
		'TLX': 'TLX',
		'TL': 'Tl',
		'TSX': 'Tsx',
		'ZDX': 'ZDX',
	},
	ALFA_ROMEO: {
		'145': '145',
		'146': '146',
		'147': '147',
		'155': '155',
		'156': '156',
		'156_SPORTWAGON': '156 sportwagon',
		'159': '159',
		'159_SPORTWAGON': '159 sportwagon',
		'164': '164',
		'166': '166',
		'33': '33',
		'4C': '4C',
		'75': '75',
		'76': '76',
		'8C_COMPETIZIONE': '8C Competizione',
		'90': '90',
		'ALFETTA': 'Alfetta',
		'BERLINA': 'Berlina',
		'BRERA': 'Brera',
		'CROSSWAGON_Q4': 'Crosswagon q4',
		'GIULIA': 'Giulia',
		'GIULIETTA': 'Giulietta',
		'GT': 'Gt',
		'GTV': 'Gtv',
		'MITO': 'MiTo',
		'SPIDER': 'Spider',
		'SPRINT': 'Sprint',
		'STELVIO': 'Stelvio',
		'SUD': 'Sud',
		'TONALE': 'Tonale',
	},
	ALPINA: {
		'B10': 'B10',
		'B11': 'B11',
		'B12': 'B12',
		'B3': 'B3',
		'B4': 'B4',
		'B5': 'B5',
		'B6': 'B6',
		'B7': 'B7',
		'B8': 'B8',
		'B9': 'B9',
		'C1': 'C1',
		'C2': 'C2',
		'D3': 'D3',
		'D4': 'D4',
		'D5': 'D5',
		'ROADSTER': 'Roadster',
		'XD3': 'XD3',
	},
	ASTON_MARTIN: {
		'_': '.',
		'DBS': 'DBS',
		'DBX': 'DBX',
		'DB7': 'Db7',
		'DB9': 'Db9',
		'RAPIDE': 'Rapide',
		'V12_VANTAGE': 'V12 Vantage',
		'V8_VANTAGE': 'V8 Vantage',
		'VANQUISH': 'Vanquish',
	},
	AUDI: {
		'100': '100',
		'200': '200',
		'50': '50',
		'60': '60',
		'80': '80',
		'90': '90',
		'A1': 'A1',
		'A2': 'A2',
		'A3': 'A3',
		'A4': 'A4',
		'A4_ALLROAD': 'A4 Allroad',
		'A5': 'A5',
		'A6': 'A6',
		'A6_ALLROAD': 'A6 Allroad',
		'A7': 'A7',
		'A8': 'A8',
		'ALLROAD': 'Allroad',
		'CABRIOLET': 'Cabriolet',
		'COUPE': 'Coupe',
		'E_TRON': 'E-Tron',
		'Q2': 'Q2',
		'Q3': 'Q3',
		'Q4': 'Q4',
		'Q5': 'Q5',
		'Q7': 'Q7',
		'Q8': 'Q8',
		'QUATTRO': 'Quattro',
		'R8': 'R8',
		'RSQ8': 'RSQ8',
		'RS3': 'Rs3',
		'RS4': 'Rs4',
		'RS5': 'Rs5',
		'RS6': 'Rs6',
		'RS7': 'Rs7',
		'S1': 'S1',
		'S2': 'S2',
		'S3': 'S3',
		'S4': 'S4',
		'S5': 'S5',
		'S6': 'S6',
		'S7': 'S7',
		'S8': 'S8',
		'SQ5': 'SQ5',
		'SQ7': 'SQ7',
		'SQ8': 'SQ8',
		'TT': 'Tt',
	},
	BMW: {
		'1_ER': '1',
		'1500': '1500',
		'1600': '1600',
		'1602': '1602',
		'1800': '1800',
		'2_ACTIVE_TOURER': '2 Active Tourer',
		'2_GRAN_COUPE': '2 Gran Coupe',
		'2_GRAN_TOURER': '2 Gran Tourer',
		'2000': '2000',
		'2002': '2002',
		'2_ER': '2',
		'220_D': '220 d',
		'2800': '2800',
		'3_ER': '3',
		'4_ER': '4',
		'5_GRAN_TURISMO': '5 Gran Turismo',
		'5_ER': '5',
		'530E': '530E',
		'6_GT': '6 GT',
		'6_ER': '6',
		'7_ER': '7',
		'8_ER': '8',
		'IZETTA': 'Izetta',
		'M_COUPЕ': 'M Coupе',
		'M135': 'M135',
		'M140': 'M140',
		'M2': 'M2',
		'M3': 'M3',
		'M4': 'M4',
		'M5': 'M5',
		'M6': 'M6',
		'M8': 'M8',
		'X1': 'X1',
		'X2': 'X2',
		'X3': 'X3',
		'X4': 'X4',
		'X5': 'X5',
		'X5M': 'X5M',
		'X6': 'X6',
		'X7': 'X7',
		'Z1': 'Z1',
		'Z3': 'Z3',
		'Z4': 'Z4',
		'Z8': 'Z8',
		'I3': 'i3',
		'I4': 'i4',
		'I7': 'i7',
		'I8': 'i8',
		'IX': 'iX',
		'IX3': 'iX3',
	},
	BYD: {
		'HAN': 'HAN',
	},
	BENTLEY: {
		'ARNAGE': 'Arnage',
		'AZURE': 'Azure',
		'BENTAYGA': 'Bentayga',
		'CONTINENTAL': 'Continental',
		'CONTINENTAL_GT': 'Continental gt',
		'FLYING_SPUR': 'Flying Spur',
		'GT_CONVERTIBLE': 'GT Convertible',
		'MULSANNE': 'Mulsanne',
		'T_SERIES': 'T-series',
	},
	BERLINER: {
		'COUPE': 'Coupe',
	},
	BERTONE: {
		'FREECLIMBER': 'Freeclimber',
	},
	BUGATTI: {
		'CHIRON': 'Chiron',
		'VEYRON': 'Veyron',
	},
	CADILLAC: {
		'ATS': 'ATS',
		'ALLANTE': 'Allante',
		'BLS': 'BLS',
		'BROUGHAM': 'Brougham',
		'CT6': 'CT6',
		'CTS': 'Cts',
		'DTS': 'DTS',
		'DEVILLE': 'Deville',
		'ELDORADO': 'Eldorado',
		'ESCALADE': 'Escalade',
		'FLEETWOOD': 'Fleetwood',
		'STS': 'STS',
		'SEVILLE': 'Seville',
		'SRX': 'Srx',
		'XT4': 'XT4',
		'XT5': 'XT5',
		'XTS': 'XTS',
		'XLR': 'Xlr',
	},
	CHEVROLET: {
		'ALERO': 'Alero',
		'ASTRO': 'Astro',
		'AVALANCHE': 'Avalanche',
		'AVEO': 'Aveo',
		'BERETTA': 'Beretta',
		'BLAZER': 'Blazer',
		'BOLT': 'Bolt',
		'CAMARO': 'Camaro',
		'CAPRICE': 'Caprice',
		'CAPTIVA': 'Captiva',
		'CAVALIER': 'Cavalier',
		'COBALT': 'Cobalt',
		'COLORADO': 'Colorado',
		'CORVETTE': 'Corvette',
		'CRUZE': 'Cruze',
		'DIAMANTE': 'Diamante',
		'EL_CAMINO': 'El Camino',
		'EPICA': 'Epica',
		'EQUINOX': 'Equinox',
		'EVANDA': 'Evanda',
		'GMC': 'Gmc',
		'HHR': 'Hhr',
		'IMPALA': 'Impala',
		'KALOS': 'Kalos',
		'LACETTI': 'Lacetti',
		'LUMINA': 'Lumina',
		'MALIBU': 'Malibu',
		'MATIZ': 'Matiz',
		'NIVA': 'Niva',
		'NOVA': 'Nova',
		'NUBIRA': 'Nubira',
		'ORLANDO': 'Orlando',
		'REZZO': 'Rezzo',
		'SILVERADO': 'Silverado',
		'SPARK': 'Spark',
		'SSR': 'Ssr',
		'SUBURBAN': 'Suburban',
		'TACUMA': 'Tacuma',
		'TAHOE': 'Tahoe',
		'TRACKER': 'Tracker',
		'TRAILBLAZER': 'Trailblazer',
		'TRANSSPORT': 'Transsport',
		'TRAVERSE': 'Traverse',
		'TRAX': 'Trax',
		'VOLT': 'Volt',
	},
	CHRYSLER: {
		'200': '200',
		'300C': '300c',
		'300M': '300m',
		'CHEROKEE': 'Cherokee',
		'CROSSFIRE': 'Crossfire',
		'DAYTONA': 'Daytona',
		'ES': 'Es',
		'GR_VOYAGER': 'Gr.voyager',
		'GRAND_CHEROKEE': 'Grand cherokee',
		'GTS': 'Gts',
		'INTERPID': 'Interpid',
		'LEBARON': 'Lebaron',
		'NEON': 'Neon',
		'NEW_YORKER': 'New yorker',
		'PACIFICA': 'Pacifica',
		'PT_CRUISER': 'Pt cruiser',
		'SARATOGA': 'Saratoga',
		'SEBRING': 'Sebring',
		'STRATUS': 'Stratus',
		'TOWN_AND_COUNTRY': 'Town and Country',
		'VISION': 'Vision',
		'VOYAGER': 'Voyager',
		'WRANGLER': 'Wrangler',
	},
	CITROEN: {
		'2CV': '2cv',
		'AX': 'Ax',
		'AXEL': 'Axel',
		'BERLINGO': 'Berlingo',
		'BX': 'Bx',
		'C___ZERO': 'C - Zero',
		'C_CROSSER': 'C-Crosser',
		'C_ELYSEE': 'C-Elysee',
		'C1': 'C1',
		'C15': 'C15',
		'C2': 'C2',
		'C3': 'C3',
		'C3_AIRCROSS': 'C3 Aircross',
		'C3_PICASSO': 'C3 Picasso',
		'C3_PLURIEL': 'C3 pluriel',
		'C4': 'C4',
		'C4_AIRCROSS': 'C4 AIRCROSS',
		'C4_CACTUS': 'C4 Cactus',
		'C4_PICASSO': 'C4 Picasso',
		'C5': 'C5',
		'C5_AIRCROSS': 'C5 Aircross',
		'C5X': 'C5X',
		'C6': 'C6',
		'C8': 'C8',
		'CX': 'Cx',
		'DS_3_CROSSBACK': 'DS 3 Crossback',
		'DS_4_CROSSBACK': 'DS 4 Crossback',
		'DS_7_CROSSBACK': 'DS 7 Crossback',
		'DS3': 'DS3',
		'DS4': 'DS4',
		'DS5': 'DS5',
		'DS7': 'DS7',
		'DS': 'Ds',
		'E_MEHARI': 'E-Mehari',
		'EVASION': 'Evasion',
		'GRAND_C4_PICASSO': 'Grand C4 Picasso',
		'GSA': 'Gsa',
		'GX': 'Gx',
		'JUMPY': 'Jumpy',
		'LN': 'Ln',
		'NEMO': 'Nemo',
		'OLTCIT': 'Oltcit',
		'SAXO': 'Saxo',
		'SPACETOURER': 'Spacetourer',
		'VISA': 'Visa',
		'XANTIA': 'Xantia',
		'XM': 'Xm',
		'XSARA': 'Xsara',
		'XSARA_PICASSO': 'Xsara picasso',
		'ZX': 'Zx',
	},
	CORVETTE: {
		'C06_CONVERTIBLE': 'C06 Convertible',
		'C06_COUPE': 'C06 Coupe',
		'POWA': 'Powa',
		'Z06': 'Z06',
	},
	CUPRA: {
		'ATECA': 'Ateca',
		'BORN': 'Born',
		'FORMENTOR': 'Formentor',
	},
	DONGFENG: {
		'580': '580',
		'E3': 'E3',
		'IX5': 'IX5',
	},
	DS: {
		'DS_3': 'DS 3',
		'DS_3_CROSSBACK': 'DS 3 Crossback',
		'DS_4': 'DS 4',
		'DS_4_CROSSBACK': 'DS 4 Crossback',
		'DS_5': 'DS 5',
		'DS_7': 'DS 7',
		'DS_7_CROSSBACK': 'DS 7 Crossback',
		'DS_9': 'DS 9',
	},
	DACIA: {
		'1100': '1100',
		'1300': '1300',
		'1304': '1304',
		'1307': '1307',
		'1310': '1310',
		'1350': '1350',
		'DOKKER': 'Dokker',
		'DUSTER': 'Duster',
		'JOGGER': 'Jogger',
		'LIBERTA': 'Liberta',
		'LODGY': 'Lodgy',
		'LOGAN': 'Logan',
		'NOVA': 'Nova',
		'PICKUP': 'Pickup',
		'SANDERO': 'Sandero',
		'SOLENZA': 'Solenza',
		'SPRING': 'Spring',
	},
	DAEWOO: {
		'ACE': 'Ace',
		'CHAIRMAN': 'Chairman',
		'CIELO': 'Cielo',
		'ESPERO': 'Espero',
		'EVANDA': 'Evanda',
		'FSO': 'Fso',
		'KALOS': 'Kalos',
		'KORANDO': 'Korando',
		'LACETTI': 'Lacetti',
		'LANOS': 'Lanos',
		'LEGANZA': 'Leganza',
		'MAGNUS': 'Magnus',
		'MATIZ': 'Matiz',
		'MUSSO': 'Musso',
		'NEXIA': 'Nexia',
		'NUBIRA': 'Nubira',
		'PRINCE': 'Prince',
		'RACER': 'Racer',
		'REZZO': 'Rezzo',
		'SUPER': 'Super',
		'TACUMA': 'Tacuma',
		'TICO': 'Tico',
	},
	DAIHATSU: {
		'APPLAUSE': 'Applause',
		'CHARADE': 'Charade',
		'CHARMANT': 'Charmant',
		'COPEN': 'Copen',
		'CUORE': 'Cuore',
		'FEROZA': 'Feroza',
		'GRAN_MOVE': 'Gran move',
		'HIJET': 'Hijet',
		'MATERIA': 'Materia',
		'MOVE': 'Move',
		'ROCKY': 'Rocky',
		'SHARADE': 'Sharade',
		'SIRION': 'Sirion',
		'TAFT': 'Taft',
		'TERIOS': 'Terios',
		'TREVIS': 'Trevis',
		'WILDCAT': 'Wildcat',
		'YRV': 'Yrv',
	},
	DAIMLER: {
		'DOUBLE_SIX': 'Double six',
		'SIX': 'Six',
		'SOVEREIGN': 'Sovereign',
	},
	DATSUN: {
		'BLUEBIRD': 'Bluebird',
		'CHERRY': 'Cherry',
		'STANZA': 'Stanza',
	},
	DKW: {
		'F102': 'F102',
	},
	DODGE: {
		'AVENGER': 'Avenger',
		'CALIBER': 'Caliber',
		'CARAVAN': 'Caravan',
		'CHALLENGER': 'Challenger',
		'CHARGER': 'Charger',
		'CORONET': 'Coronet',
		'DAKOTA': 'Dakota',
		'DART': 'Dart',
		'DAYTONA': 'Daytona',
		'DURANGO': 'Durango',
		'INTERPID': 'Interpid',
		'JOURNEY': 'Journey',
		'MAGNUM': 'Magnum',
		'NEON': 'Neon',
		'NITRO': 'Nitro',
		'RAM_1500': 'RAM 1500',
		'RAM_2500': 'RAM 2500',
		'RAM_3500': 'RAM 3500',
		'RAM': 'Ram',
		'SHADOW': 'Shadow',
		'STEALTH': 'Stealth',
		'STRATUS': 'Stratus',
		'VIPER': 'Viper',
	},
	DR: {
		'1': '1',
		'2': '2',
		'3': '3',
		'4': '4',
		'5': '5',
		'6': '6',
		'DR4': 'DR4',
		'DR6': 'DR6',
	},
	EAGLE: {
		'PREMIRE': 'Premire',
		'TALON': 'Talon',
		'VISION': 'Vision',
	},
	FSO: {
		'POLONEZ': 'Polonez',
	},
	FERRARI: {
		'296GTB': '296GTB',
		'308': '308',
		'348': '348',
		'360_MODENA': '360 modena',
		'360_SPIDER': '360 spider',
		'458_ITALIA': '458 Italia',
		'488': '488',
		'599': '599',
		'812_GTS': '812 GTS',
		'812_SUPERFAST': '812 Superfast',
		'CALIFORNIA': 'California',
		'ENZO': 'Enzo',
		'F12BERLINETTA': 'F12berlinetta',
		'F430': 'F430',
		'F456M': 'F456m',
		'F575M_MARANELLO': 'F575m maranello',
		'F612_SCAGLIETTI': 'F612 scaglietti',
		'F8': 'F8',
		'FF': 'FF',
		'GTC4LUSSO': 'GTC4Lusso',
		'LAFERRARI': 'LaFerrari',
		'MONDIAL_8': 'Mondial 8',
		'PORTOFINO': 'Portofino',
		'ROMA': 'Roma',
		'SF_90': 'SF 90',
		'TESTAROSSA': 'Testarossa',
	},
	FIAT: {
		'1100': '1100',
		'124': '124',
		'125': '125',
		'126': '126',
		'127': '127',
		'128': '128',
		'131': '131',
		'132': '132',
		'1400': '1400',
		'1500': '1500',
		'1800': '1800',
		'500': '500',
		'500L': '500L',
		'500X': '500X',
		'600': '600',
		'650': '650',
		'750': '750',
		'ALBEA': 'Albea',
		'ARGENTA': 'Argenta',
		'BARCHETTA': 'Barchetta',
		'BERTONE': 'Bertone',
		'BRAVA': 'Brava',
		'BRAVO': 'Bravo',
		'CAMPAGNOLA': 'Campagnola',
		'CINQUECENTO': 'Cinquecento',
		'COUPE': 'Coupe',
		'CROMA': 'Croma',
		'DOBLO': 'Doblo',
		'DUNA': 'Duna',
		'FIORINO': 'Fiorino',
		'FREEMONT': 'Freemont',
		'FULLBACK': 'Fullback',
		'IDEA': 'Idea',
		'LINEA': 'Linea',
		'MAREA': 'Marea',
		'MULTIPLA': 'Multipla',
		'PALIO': 'Palio',
		'PANDA': 'Panda',
		'PUNTO': 'Punto',
		'QUBO': 'Qubo',
		'REGATA': 'Regata',
		'RITMO': 'Ritmo',
		'SCUDO': 'Scudo',
		'SEDICI': 'Sedici',
		'SEICENTO': 'Seicento',
		'SIENA': 'Siena',
		'STILO': 'Stilo',
		'STRADA': 'Strada',
		'TEMPRA': 'Tempra',
		'TIPO': 'Tipo',
		'TOPOLINO': 'Topolino',
		'ULYSSE': 'Ulysse',
		'UNO': 'Uno',
	},
	FORD: {
		'12M': '12m',
		'15M': '15m',
		'17M': '17m',
		'20M': '20m',
		'AEROSTAR': 'Aerostar',
		'B_MAX': 'B-Max',
		'BRONCO': 'Bronco',
		'C_MAX': 'C-max',
		'CAPRI': 'Capri',
		'CONNECT': 'Connect',
		'CONSUL': 'Consul',
		'CORTINA': 'Cortina',
		'COSWORTH': 'Cosworth',
		'COUGAR': 'Cougar',
		'COUNTUR': 'Countur',
		'COURIER': 'Courier',
		'CROWN_VICTORIA': 'Crown victoria',
		'ECOSPORT': 'EcoSport',
		'ECOLINE': 'Ecoline',
		'EDGE': 'Edge',
		'ESCAPE': 'Escape',
		'ESCORT': 'Escort',
		'EVEREST': 'Everest',
		'EXCURSION': 'Excursion',
		'EXPEDITION': 'Expedition',
		'EXPLORER': 'Explorer',
		'F150': 'F150',
		'F250': 'F250',
		'F350': 'F350',
		'F450': 'F450',
		'F550': 'F550',
		'F650': 'F650',
		'F750': 'F750',
		'FIESTA': 'Fiesta',
		'FLEX': 'Flex',
		'FOCUS': 'Focus',
		'FUSION': 'Fusion',
		'GALAXY': 'Galaxy',
		'GRANADA': 'Granada',
		'KA': 'Ka',
		'KUGA': 'Kuga',
		'MAVERICK': 'Maverick',
		'MERCURY': 'Mercury',
		'MONDEO': 'Mondeo',
		'MUSTANG': 'Mustang',
		'ORION': 'Orion',
		'PROBE': 'Probe',
		'PUMA': 'Puma',
		'RANGER': 'Ranger',
		'RAPTOR': 'Raptor',
		'RS': 'Rs',
		'S_MAX': 'S-Max',
		'SCORPIO': 'Scorpio',
		'SIERRA': 'Sierra',
		'SPORTKA': 'Sportka',
		'STREETKA': 'Streetka',
		'TAUNUS': 'Taunus',
		'TAURUS': 'Taurus',
		'THUNDERBIRD': 'Thunderbird',
		'WINDSTAR': 'Windstar',
		'ZEPHYR': 'Zephyr',
	},
	GAZ: {
		'469': '469',
		'69': '69',
	},
	GEELY: {
		'ATLAS': 'Atlas',
		'ATLAS_PRO': 'Atlas Pro',
		'COOLRAY': 'Coolray',
		'TUGELLA': 'Tugella',
	},
	GENESIS: {
		'G70': 'G70',
		'G80': 'G80',
		'G90': 'G90',
		'GV60': 'GV60',
		'GV70': 'GV70',
		'GV80': 'GV80',
	},
	GEO: {
		'METRO': 'Metro',
		'PRIZM': 'Prizm',
		'STORM': 'Storm',
		'TRACKER': 'Tracker',
	},
	GMC: {
		'ACADIA': 'Acadia',
		'CANYON': 'Canyon',
		'ENVOY': 'Envoy',
		'JIMMY': 'Jimmy',
		'SATURN': 'Saturn',
		'SAVANA': 'Savana',
		'SIERRA': 'Sierra',
		'SONOMA': 'Sonoma',
		'TERRAIN': 'Terrain',
		'TRACKER': 'Tracker',
		'TYPHOON': 'Typhoon',
		'YUKON': 'Yukon',
	},
	GONOW: {
		'GONOW': 'Gonow',
	},
	GREAT_WALL: {
		'HAVAL_H2': 'Haval H2',
		'HAVAL_H6': 'Haval H6',
		'HOVER_CUV': 'Hover Cuv',
		'HOVER_H5': 'Hover H5',
		'HOVER_H6': 'Hover H6',
		'POER': 'Poer',
		'SAFE': 'Safe',
		'STEED_3': 'Steed 3',
		'STEED_5': 'Steed 5',
		'STEED_6': 'Steed 6',
		'STEED_7': 'Steed 7',
		'VOLEEX_C10': 'Voleex C10',
		'VOLEEX_C20': 'Voleex C20',
		'VOLEEX_C30': 'Voleex C30',
	},
	HONDA: {
		'ACCORD': 'Accord',
		'CBR': 'Cbr',
		'CBX': 'Cbx',
		'CITY': 'City',
		'CIVIC': 'Civic',
		'CIVIC_BALLADE': 'Civic ballade',
		'CONCERTO': 'Concerto',
		'CR_V': 'Cr-v',
		'CROSSTOUR': 'Crosstour',
		'CRX': 'Crx',
		'CRZ': 'Crz',
		'ELECTRIC': 'Electric',
		'ELEMENT': 'Element',
		'ELYSION': 'Elysion',
		'FIT': 'Fit',
		'FR_V': 'Fr-v',
		'HR_V': 'Hr-v',
		'INSIGHT': 'Insight',
		'INTEGRA': 'Integra',
		'JAZZ': 'Jazz',
		'LEGEND': 'Legend',
		'LOGO': 'Logo',
		'NSX': 'Nsx',
		'ODYSSEY': 'Odyssey',
		'PASSPORT': 'Passport',
		'PILOT': 'Pilot',
		'PRELUDE': 'Prelude',
		'QUINTET': 'Quintet',
		'RIDGELINE': 'Ridgeline',
		'S2000': 'S2000',
		'SHUTTLE': 'Shuttle',
		'STREAM': 'Stream',
	},
	HYUNDAI: {
		'ACCENT': 'Accent',
		'ATOS': 'Atos',
		'BAYON': 'Bayon',
		'COUPE': 'Coupe',
		'ELANTRA': 'Elantra',
		'EQUUS': 'Equus',
		'EXCEL': 'Excel',
		'GALLOPER': 'Galloper',
		'GENESIS': 'Genesis',
		'GETZ': 'Getz',
		'GRACE': 'Grace',
		'GRANDEUR': 'Grandeur',
		'I10': 'I10',
		'I20': 'I20',
		'I30': 'I30',
		'I40': 'I40',
		'IX35': 'IX35',
		'IX55': 'IX55',
		'IONIQ': 'Ioniq',
		'IONIQ_5': 'Ioniq 5',
		'IX20': 'Ix20',
		'KONA': 'Kona',
		'LANDSKAPE': 'Landskape',
		'LANTRA': 'Lantra',
		'MATRIX': 'Matrix',
		'PALISADE': 'Palisade',
		'PONY': 'Pony',
		'PORTER': 'Porter',
		'S': 'S',
		'SANTA_FE': 'Santa fe',
		'SANTAMO': 'Santamo',
		'SONATA': 'Sonata',
		'SONICA': 'Sonica',
		'STAREX': 'Starex',
		'STARIA': 'Staria',
		'STELAR': 'Stelar',
		'TB': 'Tb',
		'TERRACAN': 'Terracan',
		'TRAJET': 'Trajet',
		'TUCSON': 'Tucson',
		'VELOSTER_': 'Veloster ',
		'VERACRUZ': 'Veracruz',
		'XG': 'Xg',
	},
	INFINITI: {
		'EX30': 'Ex30',
		'EX35': 'Ex35',
		'EX37': 'Ex37',
		'FX_30': 'Fx 30',
		'FX_35': 'Fx 35',
		'FX_37': 'Fx 37',
		'FX_45': 'Fx 45',
		'FX_50': 'Fx 50',
		'FX45': 'Fx45',
		'G': 'G',
		'G_COUPE': 'G coupe',
		'G_SEDAN': 'G sedan',
		'G37': 'G37',
		'I': 'I',
		'J': 'J',
		'M': 'M',
		'Q': 'Q',
		'Q30': 'Q30',
		'Q40': 'Q40',
		'Q45': 'Q45',
		'Q50': 'Q50',
		'QX30': 'QX30',
		'QX50': 'QX50',
		'QX56': 'QX56',
		'QX60': 'QX60',
		'QX70': 'QX70',
		'QX80': 'QX80',
		'QX': 'Qx',
		'QX4': 'Qx4',
	},
	JAGUAR: {
		'DAIMLER': 'Daimler',
		'DAIMLER_DOUBLE_SIX': 'Daimler double six',
		'DAIMLER_SIX': 'Daimler six',
		'E_PACE': 'E-pace',
		'F_PACE': 'F-PACE',
		'F_TYPE': 'F-Type',
		'I_PACE': 'I-Pace',
		'S_TYPE': 'S-type',
		'SOVEREIGN': 'Sovereign',
		'SUPER_V8': 'Super v8',
		'X_TYPE': 'X-type',
		'XE': 'XE',
		'XF': 'Xf',
		'XJ': 'Xj',
		'XJR': 'Xjr',
		'XJS': 'Xjs',
		'XJSC': 'Xjsc',
		'XK8': 'Xk8',
		'XKR': 'Xkr',
	},
	JEEP: {
		'CHEROKEE': 'Cherokee',
		'COMMANDER': 'Commander',
		'COMPASS': 'Compass',
		'GRAND_WAGONEER': 'Grand Wagoneer',
		'GRAND_CHEROKEE': 'Grand cherokee',
		'PATRIOT': 'Patriot',
		'RENEGADE': 'Renegade',
		'WRANGLER': 'Wrangler',
	},
	JPX: {
		'MONTEZ': 'Montez',
	},
	KIA: {
		'AVELLA_DELTA': 'Avella delta',
		'CADENZA': 'Cadenza',
		'CARENS': 'Carens',
		'CARNIVAL': 'Carnival',
		'CEED': 'Ceed',
		'CERATO': 'Cerato',
		'CLARUS': 'Clarus',
		'EV6': 'EV6',
		'FORTE': 'Forte',
		'JOECS': 'Joecs',
		'JOYCE': 'Joyce',
		'K5': 'K5',
		'K7': 'K7',
		'K8': 'K8',
		'K9': 'K9',
		'MAGENTIS': 'Magentis',
		'MOHAVE': 'Mohave',
		'MORNING': 'Morning',
		'NIRO': 'Niro',
		'OPIRUS': 'Opirus',
		'OPTIMA': 'Optima',
		'PICANTO': 'Picanto',
		'PRIDE': 'Pride',
		'PRO_CEED': 'Pro ceed',
		'QUORIS': 'Quoris',
		'RAY': 'Ray',
		'RETONA': 'Retona',
		'RIO': 'Rio',
		'SEDONA': 'Sedona',
		'SELTOS': 'Seltos',
		'SEPHIA': 'Sephia',
		'SHUMA': 'Shuma',
		'SORENTO': 'Sorento',
		'SOUL': 'Soul',
		'SPECTRA': 'Spectra',
		'SPORTAGE': 'Sportage',
		'STINGER': 'Stinger',
		'STONIC': 'Stonic',
		'TELLURIDE': 'Telluride',
		'VENGA': 'Venga',
		'VISTO': 'Visto',
		'X_TREK': 'X-Trek',
		'XCEED': 'XCeed',
	},
	LADA: {
		'1200': '1200',
		'1300': '1300',
		'1500': '1500',
		'1600': '1600',
		'2101': '2101',
		'21011': '21011',
		'21012': '21012',
		'21013': '21013',
		'21015': '21015',
		'2102': '2102',
		'2103': '2103',
		'2104': '2104',
		'21043': '21043',
		'2105': '2105',
		'21051': '21051',
		'21053': '21053',
		'2106': '2106',
		'21061': '21061',
		'21063': '21063',
		'2107': '2107',
		'21074': '21074',
		'2108': '2108',
		'21083': '21083',
		'2109': '2109',
		'21093': '21093',
		'21099': '21099',
		'2110': '2110',
		'21213': '21213',
		'GRANTA': 'Granta',
		'KALINA': 'Kalina',
		'NIVA': 'Niva',
		'NOVA': 'Nova',
		'OKA': 'Oka',
		'PRIORA': 'Priora',
		'SAMARA': 'Samara',
		'TRAVEL': 'Travel',
		'URBAN': 'Urban',
		'VESTA': 'Vesta',
		'XRAY': 'XRAY',
	},
	LAFORZA: {
		'MAGNUM': 'Magnum',
	},
	LAMBORGHINI: {
		'AVENTADOR': 'Aventador',
		'COUNTACH': 'Countach',
		'DIABLO': 'Diablo',
		'GALLARDO': 'Gallardo',
		'HURACAN': 'Huracan',
		'MURCIELAGO': 'Murcielago',
		'REVENTON': 'Reventon',
		'URUS': 'Urus',
		'VENENO': 'Veneno',
	},
	LANCIA: {
		'A112': 'A112',
		'ARDEA': 'Ardea',
		'AURELIA': 'Aurelia',
		'BETA': 'Beta',
		'DEDRA': 'Dedra',
		'DELTA': 'Delta',
		'FLAMINIA': 'Flaminia',
		'FLAVIA': 'Flavia',
		'FULVIA': 'Fulvia',
		'KAPPA': 'Kappa',
		'LYBRA': 'Lybra',
		'MUSA': 'Musa',
		'PHEDRA': 'Phedra',
		'PRISMA': 'Prisma',
		'THEMA': 'Thema',
		'THESIS': 'Thesis',
		'UNIOR': 'Unior',
		'VOYAGER': 'Voyager',
		'Y': 'Y',
		'Y10': 'Y10',
		'YPSILON': 'Ypsilon',
		'ZETA': 'Zeta',
	},
	LAND_ROVER: {
		'DEFENDER': 'Defender',
		'DISCOVERY': 'Discovery',
		'FREELANDER': 'Freelander',
		'RANGE_ROVER_EVOQUE': 'Range Rover Evoque',
		'RANGE_ROVER_SPORT': 'Range Rover Sport',
		'RANGE_ROVER_VELAR': 'Range Rover Velar',
		'RANGE_ROVER': 'Range rover',
	},
	LANDWIND: {
		'JX6476DA': 'Jx6476da',
	},
	LEXUS: {
		'CT': 'CT',
		'ES': 'ES',
		'GS': 'GS',
		'GX': 'GX',
		'IS': 'IS',
		'LC': 'LC',
		'LFA': 'LFA',
		'LS': 'LS',
		'LX': 'LX',
		'NX': 'NX',
		'RC': 'RC',
		'RX': 'RX',
		'SC': 'SC',
		'UX': 'UX',
	},
	LIFAN: {
		'LF1010': 'LF1010',
		'LF320': 'LF320',
		'LF520': 'LF520',
		'LF620': 'LF620',
		'LF6361': 'LF6361',
		'LF7130': 'LF7130',
		'LF7160': 'LF7160',
		'X60': 'X60',
	},
	LINCOLN: {
		'CONTINENTAL': 'Continental',
		'LS': 'Ls',
		'MKC': 'MKC',
		'MKS': 'MKS',
		'MTK': 'MTK',
		'MARK': 'Mark',
		'MARK_LT': 'Mark Lt',
		'MKX': 'Mkx',
		'MKZ': 'Mkz',
		'NAVIGATOR': 'Navigator',
		'TOWN_CAR': 'Town car',
		'ZEPHYR': 'Zephyr',
	},
	LOTUS: {
		'ELISE': 'Elise',
		'EUROPE': 'Europe',
		'EVORA': 'Evora',
		'EXIGE': 'Exige',
	},
	MAHINDRA: {
		'ARMADA': 'Armada',
		'BOLERO': 'Bolero',
		'CL': 'Cl',
		'COMMANDER': 'Commander',
		'GOA': 'Goa',
		'KUV_100': 'KUV 100',
		'MARSHALL': 'Marshall',
		'QUANTO': 'Quanto',
		'SCORPIO': 'Scorpio',
		'XUV_500': 'XUV 500',
	},
	MASERATI: {
		'3200_GT': '3200 gt',
		'BITURBO': 'Biturbo',
		'COUPE_GT': 'Coupe gt',
		'GHIBLI': 'Ghibli',
		'GRANCABRIO': 'GranCabrio',
		'GRANTURISMO': 'GranTurismo',
		'GRANSPORT': 'Gransport',
		'GRECALE': 'Grecale',
		'LEVANTE': 'Levante',
		'MC20': 'MC20',
		'QUATTROPORTE': 'Quattroporte',
		'SPYDER': 'Spyder',
		'ZAGATO': 'Zagato',
	},
	MATRA: {
		'MURENA': 'Murena',
		'RANCHO': 'Rancho',
	},
	MAYBACH: {
		'57': '57',
		'62': '62',
		'650': '650',
		'S_560': 'S 560',
		'S580': 'S580',
	},
	MAZDA: {
		'121': '121',
		'2': '2',
		'3': '3',
		'323': '323',
		'5': '5',
		'6': '6',
		'626': '626',
		'929': '929',
		'B2200': 'B2200',
		'B2500': 'B2500',
		'B2600': 'B2600',
		'BT_50': 'BT-50',
		'CX_30': 'CX-30',
		'CX_5': 'CX-5',
		'CX_60': 'CX-60',
		'CX_7': 'CX-7',
		'CX_9': 'CX-9',
		'DEMIO': 'Demio',
		'MX_30': 'MX-30',
		'MPV': 'Mpv',
		'MX_3': 'Mx-3',
		'MX_5': 'Mx-5',
		'MX_6': 'Mx-6',
		'PREMACY': 'Premacy',
		'RX_7': 'Rx-7',
		'RX_8': 'Rx-8',
		'TRIBUTE': 'Tribute',
		'XEDOS': 'Xedos',
		'СХ_3': 'СХ-3',
	},
	MCLAREN: {
		'540C_COUPE': '540C Coupe',
		'570S_COUPE': '570S Coupe',
		'650S': '650S',
		'675LT': '675LT',
		'720_S': '720 S',
		'F1': 'F1',
		'GT': 'GT',
		'MP4_12C': 'MP4-12C',
		'P1': 'P1',
	},
	MERCEDES_BENZ: {
		'110': '110',
		'111': '111',
		'113': '113',
		'114': '114',
		'115': '115',
		'116': '116',
		'123': '123',
		'124': '124',
		'126': '126',
		'126_260': '126-260',
		'150': '150',
		'170': '170',
		'180': '180',
		'190': '190',
		'200': '200',
		'220': '220',
		'230': '230',
		'240': '240',
		'250': '250',
		'260': '260',
		'280': '280',
		'290': '290',
		'300': '300',
		'320': '320',
		'350': '350',
		'380': '380',
		'420': '420',
		'450': '450',
		'500': '500',
		'560': '560',
		'600': '600',
		'A_CLASS': 'A',
		'AMG_GT': 'AMG GT',
		'AMG_GT_C': 'AMG GT C',
		'AMG_GT_R': 'AMG GT R',
		'AMG_GT_S': 'AMG GT S',
		'B_CLASS': 'B',
		'C_CLASS': 'C',
		'CL_CLASS': 'CL',
		'CLA_CLASS': 'CLA',
		'CLC_CLASS': 'CLC',
		'CLK_CLASS': 'CLK',
		'CLS_CLASS': 'CLS',
		'CITAN': 'Citan',
		'E_CLASS': 'E',
		'EQA_CLASS': 'EQA',
		'EQB_CLASS': 'EQB',
		'EQC_CLASS': 'EQC',
		'EQE_CLASS': 'EQE',
		'EQS_CLASS': 'EQS',
		'EQV_CLASS': 'EQV',
		'G_CLASS': 'G',
		'GL_CLASS': 'GL',
		'GLA_CLASS': 'GLA',
		'GLB_CLASS': 'GLB',
		'GLC_CLASS': 'GLC',
		'GLE_CLASS': 'GLE',
		'GLK_CLASS': 'GLK',
		'GLS_CLASS': 'GLS',
		'GT': 'GT',
		'GTS': 'GTS',
		'ML_CLASS': 'ML',
		'MAYBACH': 'Maybach',
		'R_CLASS': 'R',
		'S_CLASS': 'S',
		'SL_CLASS': 'SL',
		'SLC_CLASS': 'SLC',
		'SLK_CLASS': 'SLK',
		'SLR_CLASS': 'SLR',
		'SLS_CLASS': 'SLS',
		'T_CLASS': 'T',
		'UNIMOG': 'Unimog',
		'VANEO': 'Vaneo',
		'VIANO': 'Viano',
		'X_CLASS': 'X',
	},
	MERCURY: {
		'MARAUDER': 'Marauder',
		'MILAN': 'Milan',
		'MONARCH': 'Monarch',
		'MOUNTAINEER': 'Mountaineer',
		'VILLAGER': 'Villager',
	},
	MG: {
		'MGA': 'Mga',
		'MGB': 'Mgb',
		'MGF': 'Mgf',
		'TF': 'Tf',
		'ZR': 'Zr',
		'ZS': 'Zs',
		'ZT': 'Zt',
		'ZT_T': 'Zt-t',
	},
	MICROCAR: {
		'MC1': 'MC1',
		'MC2': 'MC2',
		'MGO': 'MGO',
	},
	MINI: {
		'CLUBMAN': 'Clubman',
		'COOPER': 'Cooper',
		'COOPER_CABRIO': 'Cooper cabrio',
		'COOPER_S': 'Cooper s',
		'COOPER_S_CABRIO': 'Cooper s cabrio',
		'COUNTRYMAN': 'Countryman',
		'COUPE': 'Coupe',
		'D_ONE': 'D one',
		'JOHN_COOPER_WORKS': 'John Cooper Works',
		'ONE': 'One',
		'ONE_CABRIO': 'One cabrio',
		'PACEMAN': 'Paceman',
	},
	MITSUBISHI: {
		'3000_GT': '3000 gt',
		'ASX': 'ASX',
		'ATTRAGE': 'Attrage',
		'CARISMA': 'Carisma',
		'COLT': 'Colt',
		'CORDIA': 'Cordia',
		'ECLIPSE': 'Eclipse',
		'ECLIPSE_CROSS': 'Eclipse Cross',
		'GALANT': 'Galant',
		'GRANDIS': 'Grandis',
		'I_MIEV': 'I-MiEV',
		'L200': 'L200',
		'LANCER': 'Lancer',
		'MIRAGE': 'Mirage',
		'MONTERO': 'Montero',
		'OUTLANDER': 'Outlander',
		'PAJERO': 'Pajero',
		'PAJERO_PININ': 'Pajero pinin',
		'PAJERO_SPORT': 'Pajero sport',
		'RVR': 'RVR',
		'SAPPORO': 'Sapporo',
		'SIGMA': 'Sigma',
		'SPACE_GEAR': 'Space gear',
		'SPACE_RUNNER': 'Space runner',
		'SPACE_STAR': 'Space star',
		'SPACE_WAGON': 'Space wagon',
		'STARION': 'Starion',
		'TREDIA': 'Tredia',
	},
	MOSKVICH: {
		'1360': '1360',
		'1361': '1361',
		'1500': '1500',
		'2136': '2136',
		'2138': '2138',
		'2140': '2140',
		'2141': '2141',
		'21412': '21412',
		'21417': '21417',
		'2142': '2142',
		'2715': '2715',
		'401': '401',
		'403': '403',
		'407': '407',
		'408': '408',
		'412': '412',
		'426': '426',
		'427': '427',
		'503': '503',
		'ALEKO': 'Aleko',
		'ИЖ': 'Иж',
	},
	NISSAN: {
		'100_NX': '100 nx',
		'200_SX': '200 sx',
		'240_Z': '240 z',
		'280_Z': '280 z',
		'300_ZX': '300 zx',
		'350Z': '350z',
		'370Z': '370Z',
		'ACURA': 'Acura',
		'ALMERA': 'Almera',
		'ALMERA_TINO': 'Almera tino',
		'ALTIMA': 'Altima',
		'ARIYA': 'Ariya',
		'ARMADA': 'Armada',
		'BLUEBIRD': 'Bluebird',
		'CEDRIC': 'Cedric',
		'CHERRY': 'Cherry',
		'CUBE': 'Cube',
		'FIGARO': 'Figaro',
		'FRONTIER': 'Frontier',
		'GT_R': 'Gt-r',
		'JUKE': 'Juke',
		'KUBISTAR': 'Kubistar',
		'LAUREL': 'Laurel',
		'LEAF_': 'Leaf ',
		'MAXIMA': 'Maxima',
		'MICRA': 'Micra',
		'MURANO': 'Murano',
		'NP300': 'NP300',
		'NAVARA': 'Navara',
		'NOTE': 'Note',
		'PATHFINDER': 'Pathfinder',
		'PATROL': 'Patrol',
		'PICKUP': 'Pickup',
		'PIXO': 'Pixo',
		'PRAIRIE': 'Prairie',
		'PRIMERA': 'Primera',
		'PULSAR': 'Pulsar',
		'QASHQAI': 'Qashqai',
		'QUEST': 'Quest',
		'ROGUE': 'Rogue',
		'SENTRA': 'Sentra',
		'SERENA': 'Serena',
		'SILVIA': 'Silvia',
		'SKYLINE': 'Skyline',
		'STANTZA': 'Stantza',
		'SUNNY': 'Sunny',
		'TEANA': 'Teana',
		'TERRANO': 'Terrano',
		'TIIDA': 'Tiida',
		'TITAN_CREW_CAB': 'Titan crew cab',
		'TITAN_KING': 'Titan king',
		'VERSA': 'Versa',
		'X_TRAIL': 'X-trail',
		'XTERRA': 'Xterra',
		'E_NV200': 'e-NV200',
	},
	OPEL: {
		'ADAM': 'Adam',
		'ADMIRAL': 'Admiral',
		'AGILA': 'Agila',
		'AMPERA': 'Ampera',
		'ANTARA': 'Antara',
		'ASCONA': 'Ascona',
		'ASTRA': 'Astra',
		'CALIBRA': 'Calibra',
		'CAMPO': 'Campo',
		'CASCADA': 'Cascada',
		'COMBO': 'Combo',
		'COMMODORE': 'Commodore',
		'CORSA': 'Corsa',
		'CROSSLAND_X': 'Crossland X',
		'DIPLOMAT': 'Diplomat',
		'FRONTERA': 'Frontera',
		'GRANDLAND_X': 'Grandland X',
		'GT': 'Gt',
		'INSIGNIA': 'Insignia',
		'KADETT': 'Kadett',
		'KAPITAEN': 'Kapitaen',
		'KARL': 'Karl',
		'MANTA': 'Manta',
		'MERIVA': 'Meriva',
		'MOKKA': 'Mokka',
		'MONTEREY': 'Monterey',
		'MONZA': 'Monza',
		'OMEGA': 'Omega',
		'REKORD': 'Rekord',
		'SENATOR': 'Senator',
		'SIGNUM': 'Signum',
		'SINTRA': 'Sintra',
		'SPEEDSTER': 'Speedster',
		'TIGRA': 'Tigra',
		'VECTRA': 'Vectra',
		'ZAFIRA': 'Zafira',
	},
	PEUGEOT: {
		'1007': '1007',
		'104': '104',
		'106': '106',
		'107': '107',
		'108': '108',
		'2008': '2008',
		'202': '202',
		'204': '204',
		'205': '205',
		'206': '206',
		'207': '207',
		'208': '208',
		'3008': '3008',
		'301': '301',
		'304': '304',
		'305': '305',
		'306': '306',
		'307': '307',
		'308': '308',
		'309': '309',
		'4007': '4007',
		'4008': '4008',
		'402': '402',
		'403': '403',
		'404': '404',
		'405': '405',
		'406': '406',
		'407': '407',
		'408': '408',
		'5008': '5008',
		'504': '504',
		'505': '505',
		'508': '508',
		'604': '604',
		'605': '605',
		'607': '607',
		'806': '806',
		'807': '807',
		'BIPPER': 'Bipper',
		'EXPERT': 'Expert',
		'PARTNER': 'Partner',
		'RCZ': 'RCZ',
		'RANGE': 'Range',
		'RIFTER': 'Rifter',
		'TRAVELER': 'Traveler',
		'ION': 'iOn',
	},
	PGO: {
		'CEVENNES': 'Cevennes',
		'SPEEDSTER': 'Speedster',
	},
	PLYMOUTH: {
		'ACCLAIM': 'Acclaim',
		'BARRACUDA': 'Barracuda',
		'BREEZE': 'Breeze',
		'COLT': 'Colt',
		'GRAND_VOYAGER': 'Grand voyager',
		'HORIZON': 'Horizon',
		'LASER': 'Laser',
		'NEON': 'Neon',
		'PROWLER': 'Prowler',
		'RELIANT': 'Reliant',
		'ROAD_RUNNER': 'Road runner',
		'SUNDANCE': 'Sundance',
		'VOLARE': 'Volare',
		'VOYAGER': 'Voyager',
	},
	POLESTAR: {
		'POLESTAR_ELECTRIC': 'Polestar Electric',
	},
	POLONEZ: {
		'PICKUP': 'Pickup',
		'POLESTAR': 'Polestar',
	},
	PONTIAC: {
		'AZTEC': 'Aztec',
		'BONNEVILLE': 'Bonneville',
		'FIERO': 'Fiero',
		'FIREBIRD': 'Firebird',
		'G6': 'G6',
		'GRAND_AM': 'Grand am',
		'GRAND_PRIX': 'Grand prix',
		'GTO': 'Gto',
		'LEMANS': 'Lemans',
		'SOLSTICE': 'Solstice',
		'SUNBIRD': 'Sunbird',
		'SUNFIRE': 'Sunfire',
		'TEMPEST': 'Tempest',
		'TORRENT': 'Torrent',
		'TRANS_AM': 'Trans am',
		'TRANS_SPORT': 'Trans sport',
		'VIBE': 'Vibe',
	},
	PORSCHE: {
		'356_SPEEDSTER': '356 Speedster',
		'911': '911',
		'918_SPYDER': '918 Spyder',
		'924': '924',
		'928': '928',
		'935': '935',
		'944': '944',
		'956': '956',
		'968': '968',
		'991': '991',
		'993': '993',
		'996': '996',
		'BOXSTER': 'Boxster',
		'CARRERA': 'Carrera',
		'CAYENNE': 'Cayenne',
		'CAYMAN': 'Cayman',
		'MACAN': 'Macan',
		'PANAMERA': 'Panamera',
		'TAYCAN': 'Taycan',
	},
	PROTON: {
		'400': '400',
		'ARENA': 'Arena',
		'PERSONE': 'Persone',
		'SATRIA': 'Satria',
	},
	RENAULT: {
		'10': '10',
		'11': '11',
		'12': '12',
		'14': '14',
		'16': '16',
		'18': '18',
		'19': '19',
		'20': '20',
		'21': '21',
		'25': '25',
		'29': '29',
		'30': '30',
		'4': '4',
		'5': '5',
		'8': '8',
		'9': '9',
		'ALPINE': 'Alpine',
		'ARKANA': 'Arkana',
		'AVANTIME': 'Avantime',
		'BAKARA': 'Bakara',
		'BULGAR': 'Bulgar',
		'CAPTUR': 'Captur',
		'CARAVELLE': 'Caravelle',
		'CHAMADE': 'Chamade',
		'CLIO': 'Clio',
		'DUSTER': 'Duster',
		'ESPACE': 'Espace',
		'EXPRESS': 'Express',
		'FLORIDE': 'Floride',
		'FLUENCE': 'Fluence',
		'FUEGO': 'Fuego',
		'GRAND_ESPACE': 'Grand espace',
		'GRAND_SCENIC': 'Grand scenic',
		'KADJAR': 'Kadjar',
		'KANGOO': 'Kangoo',
		'KOLEOS': 'Koleos',
		'LAGUNA': 'Laguna',
		'LAGUNA_COUPE': 'Laguna Coupe',
		'LATITUDE': 'Latitude',
		'MEGANE': 'Megane',
		'MODUS': 'Modus',
		'NEVADA': 'Nevada',
		'RAPID': 'Rapid',
		'SAFRANE': 'Safrane',
		'SCENIC': 'Scenic',
		'SCENIC_RX4': 'Scenic rx4',
		'SYMBOL': 'Symbol',
		'TALISMAN': 'Talisman',
		'TWINGO': 'Twingo',
		'TWIZY': 'Twizy',
		'VEL_SATIS': 'Vel satis',
		'WIND': 'Wind',
		'ZOE': 'Zoe',
	},
	RIEJU: {
		'CENTURY': 'Century',
		'MR': 'MR',
	},
	ROLLS_ROYCE: {
		'CULLINAN': 'Cullinan',
		'DAWN': 'Dawn',
		'GHOST': 'Ghost',
		'PHANTOM': 'Phantom',
		'RIEJU': 'Rieju',
		'SILVER_CLOUD': 'Silver Cloud',
		'SILVER_SERAPH': 'Silver Seraph',
		'SILVER_SPUR': 'Silver Spur',
		'WRAITH': 'Wraith',
	},
	ROVER: {
		'111': '111',
		'114': '114',
		'200': '200',
		'213': '213',
		'214': '214',
		'216': '216',
		'220': '220',
		'25': '25',
		'400': '400',
		'414': '414',
		'416': '416',
		'418': '418',
		'420': '420',
		'45': '45',
		'600': '600',
		'618': '618',
		'620': '620',
		'623': '623',
		'75': '75',
		'800': '800',
		'820': '820',
		'825': '825',
		'827': '827',
		'CITY': 'City',
		'ESTATE': 'Estate',
		'MAESTRO': 'Maestro',
		'METRO': 'Metro',
		'MINI': 'Mini',
		'MONTEGO': 'Montego',
		'STREETWISE': 'Streetwise',
	},
	SECMA: {
		'F16': 'F16',
		'F440DCI': 'F440DCI',
		'FUN_BUGGY': 'Fun Buggy',
		'FUN_EXTREEM': 'Fun Extreem',
		'FUN_LANDER': 'Fun Lander',
		'FUN_QUAD': 'Fun Quad',
	},
	SH_AUTO: {
		'CEO': 'Ceo',
	},
	SAAB: {
		'9_3': '9-3',
		'9_4X': '9-4X',
		'9_5': '9-5',
		'9_7X': '9-7x',
		'900': '900',
		'9000': '9000',
	},
	SAMAND: {
		'LX': 'LX',
	},
	SANTANA: {
		'PS10': 'PS10',
	},
	SATURN: {
		'ASTRA': 'Astra',
		'AURA': 'Aura',
		'OUTLOOK': 'Outlook',
		'SKY': 'Sky',
		'VUE': 'Vue',
	},
	SCION: {
		'TC': 'Tc',
		'XA': 'Xa',
		'XB': 'Xb',
	},
	SEAT: {
		'ALHAMBRA': 'Alhambra',
		'ALTEA': 'Altea',
		'ARONA': 'Arona',
		'AROSA': 'Arosa',
		'ATECA': 'Ateca',
		'CORDOBA': 'Cordoba',
		'CUPRA': 'Cupra',
		'EXEO': 'Exeo',
		'FURA': 'Fura',
		'IBIZA': 'Ibiza',
		'INKA': 'Inka',
		'LEON': 'Leon',
		'MALAGA': 'Malaga',
		'MARBELLA': 'Marbella',
		'MII': 'Mii',
		'RONDA': 'Ronda',
		'TARRACO': 'Tarraco',
		'TERRA': 'Terra',
		'TOLEDO': 'Toledo',
		'VARIO': 'Vario',
	},
	SKODA: {
		'100': '100',
		'1000': '1000',
		'105': '105',
		'120': '120',
		'125': '125',
		'130': '130',
		'135': '135',
		'136': '136',
		'CITIGO': 'Citigo',
		'ENYAQ': 'Enyaq',
		'FABIA': 'Fabia',
		'FAVORIT': 'Favorit',
		'FELICIA': 'Felicia',
		'FORMAN': 'Forman',
		'KAMIQ': 'Kamiq',
		'KAROQ': 'Karoq',
		'KODIAQ': 'Kodiaq',
		'OCTAVIA': 'Octavia',
		'PRAKTIK': 'Praktik',
		'RAPID': 'Rapid',
		'ROOMSTER': 'Roomster',
		'SCALA': 'Scala',
		'SUPERB': 'Superb',
		'YETI': 'Yeti',
	},
	SMART: {
		'FORFOUR': 'Forfour',
		'FORTWO': 'Fortwo',
		'MC': 'Mc',
		'ROADSTER': 'Roadster',
	},
	SSANG_YONG: {
		'CHAIRMAN': 'Chairman',
		'RODIUS': 'Rodius',
		'TIVOLI': 'Tivoli',
	},
	SSANGYONG: {
		'ACTYON': 'Actyon',
		'ACTYON_SPORTS': 'Actyon Sports',
		'KORANDO': 'Korando',
		'KORANDO_SPORTS': 'Korando Sports',
		'KYRON': 'Kyron',
		'MUSSO': 'Musso',
		'REXTON': 'Rexton',
		'TIVOLI': 'Tivoli',
		'XLV': 'XLV',
	},
	SUBARU: {
		'1800': '1800',
		'ASCENT': 'Ascent',
		'B10_TRIBEKA': 'B10 Tribeka',
		'B9_TRIBECA': 'B9 tribeca',
		'BRZ': 'BRZ',
		'BAJA': 'Baja',
		'E12': 'E12',
		'FORESTER': 'Forester',
		'G3X_JUSTY': 'G3x justy',
		'IMPREZA': 'Impreza',
		'JUSTY': 'Justy',
		'LEGACY': 'Legacy',
		'LEONE': 'Leone',
		'LEVORG': 'Levorg',
		'LIBERO': 'Libero',
		'OUTBACK': 'Outback',
		'SVX': 'Svx',
		'TREZIA': 'Trezia',
		'VIVIO': 'Vivio',
		'XT': 'XT',
		'XV': 'XV',
	},
	SUZUKI: {
		'ACROSS': 'Across',
		'ALTO': 'Alto',
		'BALENO': 'Baleno',
		'CELERIO': 'Celerio',
		'FORENZA': 'Forenza',
		'GRAND_VITARA': 'Grand vitara',
		'IGNIS': 'Ignis',
		'JIMNY': 'Jimny',
		'KIZASHI': 'Kizashi',
		'LIANA': 'Liana',
		'MARUTI': 'Maruti',
		'RENO': 'Reno',
		'SX4': 'SX4',
		'SX4_S_CROSS': 'SX4 S-Cross',
		'SAMURAI': 'Samurai',
		'SANTANA': 'Santana',
		'SG': 'Sg',
		'SIDEKICK': 'Sidekick',
		'SJ': 'Sj',
		'SPLASH': 'Splash',
		'SWACE': 'Swace',
		'SWIFT': 'Swift',
		'VITARA': 'Vitara',
		'WAGON_R': 'Wagon r',
		'X_90': 'X-90',
		'XL_7': 'XL-7',
	},
	TALBOT: {
		'1100': '1100',
		'1310': '1310',
		'HORIZON': 'Horizon',
		'MATRA': 'Matra',
		'MURENA': 'Murena',
		'SAMBA': 'Samba',
		'SIMKA': 'Simka',
		'SOLARA': 'Solara',
	},
	TERBERG: {
		'FL2850': 'Fl2850',
		'SL3000': 'Sl3000',
	},
	TESLA: {
		'MODEL_3': 'Model 3',
		'MODEL_S': 'Model S',
		'MODEL_X': 'Model X',
		'MODEL_Y': 'Model Y',
		'ROADSTER': 'Roadster',
		'ROADSTER_SPORT': 'Roadster Sport',
	},
	TOYOTA: {
		'4RUNNER': '4runner',
		'ALPHARD': 'Alphard',
		'AURIS': 'Auris',
		'AVALON': 'Avalon',
		'AVENSIS': 'Avensis',
		'AVENSIS_VERSO': 'Avensis verso',
		'AYGO': 'Aygo',
		'C_HR': 'C-HR',
		'CAMRY': 'Camry',
		'CARINA': 'Carina',
		'CELICA': 'Celica',
		'COROLLA': 'Corolla',
		'COROLLA_MATRIX': 'Corolla Matrix',
		'COROLLA_VERSO': 'Corolla verso',
		'CRESSIDA': 'Cressida',
		'CROWN': 'Crown',
		'FJ_CRUISER': 'Fj cruiser',
		'GT86': 'GT86',
		'HARRIER': 'Harrier',
		'HIGHLANDER': 'Highlander',
		'HILUX': 'Hilux',
		'IQ': 'IQ',
		'LAND_CRUISER': 'Land cruiser',
		'MATRIX': 'Matrix',
		'MR2': 'Mr2',
		'PASEO': 'Paseo',
		'PICNIC': 'Picnic',
		'PREVIA': 'Previa',
		'PRIUS': 'Prius',
		'PROACE_CITY': 'Proace City',
		'PROACE_CITY_VERSO': 'Proace City Verso',
		'RAV4': 'Rav4',
		'SCION': 'Scion',
		'SEQUOIA': 'Sequoia',
		'SIENNA': 'Sienna',
		'STARLET': 'Starlet',
		'SUPRA': 'Supra',
		'TACOMA': 'Tacoma',
		'TERCEL': 'Tercel',
		'TUNDRA': 'Tundra',
		'URBAN_CRUISER': 'Urban Cruiser',
		'VENZA': 'Venza',
		'VERSO': 'Verso',
		'VERSO_S': 'Verso S',
		'YARIS': 'Yaris',
		'YARIS_VERSO': 'Yaris verso',
		'BZ4X': 'bZ4X',
	},
	VW: {
		'1200': '1200',
		'1300': '1300',
		'1302': '1302',
		'1303': '1303',
		'1500': '1500',
		'1600': '1600',
		'ALLTRACK': 'Alltrack',
		'AMAROK': 'Amarok',
		'ARTEON': 'Arteon',
		'ATLAS': 'Atlas',
		'BORA': 'Bora',
		'CC': 'CC',
		'CADDY': 'Caddy',
		'CORRADO': 'Corrado',
		'DERBY': 'Derby',
		'EOS': 'Eos',
		'FOX': 'Fox',
		'GOLF': 'Golf',
		'GOLF_PLUS': 'Golf Plus',
		'GOLF_VARIANT': 'Golf Variant',
		'ID_3': 'ID.3',
		'ID_4': 'ID.4',
		'ID_5': 'ID.5',
		'ID_6': 'ID.6',
		'JETTA': 'Jetta',
		'K_70': 'K 70',
		'KARMANN_GHIA': 'Karmann-ghia',
		'LUPO': 'Lupo',
		'MULTIVAN': 'Multivan',
		'NEW_BEETLE': 'New beetle',
		'PASSAT': 'Passat',
		'PHAETON': 'Phaeton',
		'POLO': 'Polo',
		'RABBIT': 'Rabbit',
		'SANTANA': 'Santana',
		'SCIROCCO': 'Scirocco',
		'SHARAN': 'Sharan',
		'SPORTSVAN': 'Sportsvan',
		'T_CROSS': 'T-Cross',
		'T_ROC': 'T-Roc',
		'TAIGO': 'Taigo',
		'TARO': 'Taro',
		'TIGUAN': 'Tiguan',
		'TOUAREG': 'Touareg',
		'TOURAN': 'Touran',
		'UP': 'Up',
		'VENTO': 'Vento',
	},
	VOLVO: {
		'142': '142',
		'144': '144',
		'145': '145',
		'164': '164',
		'1800_ES': '1800 es',
		'240': '240',
		'244': '244',
		'245': '245',
		'262_C': '262 c',
		'264': '264',
		'340': '340',
		'343': '343',
		'344': '344',
		'345': '345',
		'360': '360',
		'440': '440',
		'460': '460',
		'480': '480',
		'66': '66',
		'740': '740',
		'744': '744',
		'745': '745',
		'760': '760',
		'765': '765',
		'770': '770',
		'780': '780',
		'850': '850',
		'940': '940',
		'960': '960',
		'C30': 'C30',
		'C70': 'C70',
		'P_1800': 'P 1800',
		'S40': 'S40',
		'S60': 'S60',
		'S70': 'S70',
		'S80': 'S80',
		'S90': 'S90',
		'V40': 'V40',
		'V40_CROSS_COUNTRY': 'V40 Cross Country',
		'V50': 'V50',
		'V60': 'V60',
		'V60_CROSS_COUNTRY': 'V60 Cross Country',
		'V70': 'V70',
		'V90': 'V90',
		'V90_CROSS_COUNTRY': 'V90 Cross Country',
		'XC40': 'XC40',
		'XC60': 'XC60',
		'XC70': 'Xc70',
		'XC90': 'Xc90',
	},
}

export const BRAND_MODEL_MAPPING : {[key: string] : string[]} = {
	ABARTH: [
		'124',
		'595',
	],
	ACURA: [
		'ILX',
		'INTEGRA',
		'MDX',
		'NSX',
		'RDX',
		'RL',
		'RSX',
		'SLX',
		'TLX',
		'TL',
		'TSX',
		'ZDX',
	],
	ALFA_ROMEO: [
		'145',
		'146',
		'147',
		'155',
		'156',
		'156_SPORTWAGON',
		'159',
		'159_SPORTWAGON',
		'164',
		'166',
		'33',
		'4C',
		'75',
		'76',
		'8C_COMPETIZIONE',
		'90',
		'ALFETTA',
		'BERLINA',
		'BRERA',
		'CROSSWAGON_Q4',
		'GIULIA',
		'GIULIETTA',
		'GT',
		'GTV',
		'MITO',
		'SPIDER',
		'SPRINT',
		'STELVIO',
		'SUD',
		'TONALE',
	],
	ALPINA: [
		'B10',
		'B11',
		'B12',
		'B3',
		'B4',
		'B5',
		'B6',
		'B7',
		'B8',
		'B9',
		'C1',
		'C2',
		'D3',
		'D4',
		'D5',
		'ROADSTER',
		'XD3',
	],
	ASTON_MARTIN: [
		'_',
		'DBS',
		'DBX',
		'DB7',
		'DB9',
		'RAPIDE',
		'V12_VANTAGE',
		'V8_VANTAGE',
		'VANQUISH',
	],
	AUDI: [
		'100',
		'200',
		'50',
		'60',
		'80',
		'90',
		'A1',
		'A2',
		'A3',
		'A4',
		'A4_ALLROAD',
		'A5',
		'A6',
		'A6_ALLROAD',
		'A7',
		'A8',
		'ALLROAD',
		'CABRIOLET',
		'COUPE',
		'E_TRON',
		'Q2',
		'Q3',
		'Q4',
		'Q5',
		'Q7',
		'Q8',
		'QUATTRO',
		'R8',
		'RSQ8',
		'RS3',
		'RS4',
		'RS5',
		'RS6',
		'RS7',
		'S1',
		'S2',
		'S3',
		'S4',
		'S5',
		'S6',
		'S7',
		'S8',
		'SQ5',
		'SQ7',
		'SQ8',
		'TT',
	],
	BMW: [
		'1_ER',
		'1500',
		'1600',
		'1602',
		'1800',
		'2_ACTIVE_TOURER',
		'2_GRAN_COUPE',
		'2_GRAN_TOURER',
		'2000',
		'2002',
		'2_ER',
		'220_D',
		'2800',
		'3_ER',
		'4_ER',
		'5_GRAN_TURISMO',
		'5_ER',
		'530E',
		'6_GT',
		'6_ER',
		'7_ER',
		'8_ER',
		'IZETTA',
		'M_COUPЕ',
		'M135',
		'M140',
		'M2',
		'M3',
		'M4',
		'M5',
		'M6',
		'M8',
		'X1',
		'X2',
		'X3',
		'X4',
		'X5',
		'X5M',
		'X6',
		'X7',
		'Z1',
		'Z3',
		'Z4',
		'Z8',
		'I3',
		'I4',
		'I7',
		'I8',
		'IX',
		'IX3',
	],
	BYD: [
		'HAN',
	],
	BENTLEY: [
		'ARNAGE',
		'AZURE',
		'BENTAYGA',
		'CONTINENTAL',
		'CONTINENTAL_GT',
		'FLYING_SPUR',
		'GT_CONVERTIBLE',
		'MULSANNE',
		'T_SERIES',
	],
	BERLINER: [
		'COUPE',
	],
	BERTONE: [
		'FREECLIMBER',
	],
	BUGATTI: [
		'CHIRON',
		'VEYRON',
	],
	CADILLAC: [
		'ATS',
		'ALLANTE',
		'BLS',
		'BROUGHAM',
		'CT6',
		'CTS',
		'DTS',
		'DEVILLE',
		'ELDORADO',
		'ESCALADE',
		'FLEETWOOD',
		'STS',
		'SEVILLE',
		'SRX',
		'XT4',
		'XT5',
		'XTS',
		'XLR',
	],
	CHEVROLET: [
		'ALERO',
		'ASTRO',
		'AVALANCHE',
		'AVEO',
		'BERETTA',
		'BLAZER',
		'BOLT',
		'CAMARO',
		'CAPRICE',
		'CAPTIVA',
		'CAVALIER',
		'COBALT',
		'COLORADO',
		'CORVETTE',
		'CRUZE',
		'DIAMANTE',
		'EL_CAMINO',
		'EPICA',
		'EQUINOX',
		'EVANDA',
		'GMC',
		'HHR',
		'IMPALA',
		'KALOS',
		'LACETTI',
		'LUMINA',
		'MALIBU',
		'MATIZ',
		'NIVA',
		'NOVA',
		'NUBIRA',
		'ORLANDO',
		'REZZO',
		'SILVERADO',
		'SPARK',
		'SSR',
		'SUBURBAN',
		'TACUMA',
		'TAHOE',
		'TRACKER',
		'TRAILBLAZER',
		'TRANSSPORT',
		'TRAVERSE',
		'TRAX',
		'VOLT',
	],
	CHRYSLER: [
		'200',
		'300C',
		'300M',
		'CHEROKEE',
		'CROSSFIRE',
		'DAYTONA',
		'ES',
		'GR_VOYAGER',
		'GRAND_CHEROKEE',
		'GTS',
		'INTERPID',
		'LEBARON',
		'NEON',
		'NEW_YORKER',
		'PACIFICA',
		'PT_CRUISER',
		'SARATOGA',
		'SEBRING',
		'STRATUS',
		'TOWN_AND_COUNTRY',
		'VISION',
		'VOYAGER',
		'WRANGLER',
	],
	CITROEN: [
		'2CV',
		'AX',
		'AXEL',
		'BERLINGO',
		'BX',
		'C___ZERO',
		'C_CROSSER',
		'C_ELYSEE',
		'C1',
		'C15',
		'C2',
		'C3',
		'C3_AIRCROSS',
		'C3_PICASSO',
		'C3_PLURIEL',
		'C4',
		'C4_AIRCROSS',
		'C4_CACTUS',
		'C4_PICASSO',
		'C5',
		'C5_AIRCROSS',
		'C5X',
		'C6',
		'C8',
		'CX',
		'DS_3_CROSSBACK',
		'DS_4_CROSSBACK',
		'DS_7_CROSSBACK',
		'DS3',
		'DS4',
		'DS5',
		'DS7',
		'DS',
		'E_MEHARI',
		'EVASION',
		'GRAND_C4_PICASSO',
		'GSA',
		'GX',
		'JUMPY',
		'LN',
		'NEMO',
		'OLTCIT',
		'SAXO',
		'SPACETOURER',
		'VISA',
		'XANTIA',
		'XM',
		'XSARA',
		'XSARA_PICASSO',
		'ZX',
	],
	CORVETTE: [
		'C06_CONVERTIBLE',
		'C06_COUPE',
		'POWA',
		'Z06',
	],
	CUPRA: [
		'ATECA',
		'BORN',
		'FORMENTOR',
	],
	DONGFENG: [
		'580',
		'E3',
		'IX5',
	],
	DS: [
		'DS_3',
		'DS_3_CROSSBACK',
		'DS_4',
		'DS_4_CROSSBACK',
		'DS_5',
		'DS_7',
		'DS_7_CROSSBACK',
		'DS_9',
	],
	DACIA: [
		'1100',
		'1300',
		'1304',
		'1307',
		'1310',
		'1350',
		'DOKKER',
		'DUSTER',
		'JOGGER',
		'LIBERTA',
		'LODGY',
		'LOGAN',
		'NOVA',
		'PICKUP',
		'SANDERO',
		'SOLENZA',
		'SPRING',
	],
	DAEWOO: [
		'ACE',
		'CHAIRMAN',
		'CIELO',
		'ESPERO',
		'EVANDA',
		'FSO',
		'KALOS',
		'KORANDO',
		'LACETTI',
		'LANOS',
		'LEGANZA',
		'MAGNUS',
		'MATIZ',
		'MUSSO',
		'NEXIA',
		'NUBIRA',
		'PRINCE',
		'RACER',
		'REZZO',
		'SUPER',
		'TACUMA',
		'TICO',
	],
	DAIHATSU: [
		'APPLAUSE',
		'CHARADE',
		'CHARMANT',
		'COPEN',
		'CUORE',
		'FEROZA',
		'GRAN_MOVE',
		'HIJET',
		'MATERIA',
		'MOVE',
		'ROCKY',
		'SHARADE',
		'SIRION',
		'TAFT',
		'TERIOS',
		'TREVIS',
		'WILDCAT',
		'YRV',
	],
	DAIMLER: [
		'DOUBLE_SIX',
		'SIX',
		'SOVEREIGN',
	],
	DATSUN: [
		'BLUEBIRD',
		'CHERRY',
		'STANZA',
	],
	DKW: [
		'F102',
	],
	DODGE: [
		'AVENGER',
		'CALIBER',
		'CARAVAN',
		'CHALLENGER',
		'CHARGER',
		'CORONET',
		'DAKOTA',
		'DART',
		'DAYTONA',
		'DURANGO',
		'INTERPID',
		'JOURNEY',
		'MAGNUM',
		'NEON',
		'NITRO',
		'RAM_1500',
		'RAM_2500',
		'RAM_3500',
		'RAM',
		'SHADOW',
		'STEALTH',
		'STRATUS',
		'VIPER',
	],
	DR: [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'DR4',
		'DR6',
	],
	EAGLE: [
		'PREMIRE',
		'TALON',
		'VISION',
	],
	FSO: [
		'POLONEZ',
	],
	FERRARI: [
		'296GTB',
		'308',
		'348',
		'360_MODENA',
		'360_SPIDER',
		'458_ITALIA',
		'488',
		'599',
		'812_GTS',
		'812_SUPERFAST',
		'CALIFORNIA',
		'ENZO',
		'F12BERLINETTA',
		'F430',
		'F456M',
		'F575M_MARANELLO',
		'F612_SCAGLIETTI',
		'F8',
		'FF',
		'GTC4LUSSO',
		'LAFERRARI',
		'MONDIAL_8',
		'PORTOFINO',
		'ROMA',
		'SF_90',
		'TESTAROSSA',
	],
	FIAT: [
		'1100',
		'124',
		'125',
		'126',
		'127',
		'128',
		'131',
		'132',
		'1400',
		'1500',
		'1800',
		'500',
		'500L',
		'500X',
		'600',
		'650',
		'750',
		'ALBEA',
		'ARGENTA',
		'BARCHETTA',
		'BERTONE',
		'BRAVA',
		'BRAVO',
		'CAMPAGNOLA',
		'CINQUECENTO',
		'COUPE',
		'CROMA',
		'DOBLO',
		'DUNA',
		'FIORINO',
		'FREEMONT',
		'FULLBACK',
		'IDEA',
		'LINEA',
		'MAREA',
		'MULTIPLA',
		'PALIO',
		'PANDA',
		'PUNTO',
		'QUBO',
		'REGATA',
		'RITMO',
		'SCUDO',
		'SEDICI',
		'SEICENTO',
		'SIENA',
		'STILO',
		'STRADA',
		'TEMPRA',
		'TIPO',
		'TOPOLINO',
		'ULYSSE',
		'UNO',
	],
	FORD: [
		'12M',
		'15M',
		'17M',
		'20M',
		'AEROSTAR',
		'B_MAX',
		'BRONCO',
		'C_MAX',
		'CAPRI',
		'CONNECT',
		'CONSUL',
		'CORTINA',
		'COSWORTH',
		'COUGAR',
		'COUNTUR',
		'COURIER',
		'CROWN_VICTORIA',
		'ECOSPORT',
		'ECOLINE',
		'EDGE',
		'ESCAPE',
		'ESCORT',
		'EVEREST',
		'EXCURSION',
		'EXPEDITION',
		'EXPLORER',
		'F150',
		'F250',
		'F350',
		'F450',
		'F550',
		'F650',
		'F750',
		'FIESTA',
		'FLEX',
		'FOCUS',
		'FUSION',
		'GALAXY',
		'GRANADA',
		'KA',
		'KUGA',
		'MAVERICK',
		'MERCURY',
		'MONDEO',
		'MUSTANG',
		'ORION',
		'PROBE',
		'PUMA',
		'RANGER',
		'RAPTOR',
		'RS',
		'S_MAX',
		'SCORPIO',
		'SIERRA',
		'SPORTKA',
		'STREETKA',
		'TAUNUS',
		'TAURUS',
		'THUNDERBIRD',
		'WINDSTAR',
		'ZEPHYR',
	],
	GAZ: [
		'469',
		'69',
	],
	GEELY: [
		'ATLAS',
		'ATLAS_PRO',
		'COOLRAY',
		'TUGELLA',
	],
	GENESIS: [
		'G70',
		'G80',
		'G90',
		'GV60',
		'GV70',
		'GV80',
	],
	GEO: [
		'METRO',
		'PRIZM',
		'STORM',
		'TRACKER',
	],
	GMC: [
		'ACADIA',
		'CANYON',
		'ENVOY',
		'JIMMY',
		'SATURN',
		'SAVANA',
		'SIERRA',
		'SONOMA',
		'TERRAIN',
		'TRACKER',
		'TYPHOON',
		'YUKON',
	],
	GONOW: [
		'GONOW',
	],
	GREAT_WALL: [
		'HAVAL_H2',
		'HAVAL_H6',
		'HOVER_CUV',
		'HOVER_H5',
		'HOVER_H6',
		'POER',
		'SAFE',
		'STEED_3',
		'STEED_5',
		'STEED_6',
		'STEED_7',
		'VOLEEX_C10',
		'VOLEEX_C20',
		'VOLEEX_C30',
	],
	HONDA: [
		'ACCORD',
		'CBR',
		'CBX',
		'CITY',
		'CIVIC',
		'CIVIC_BALLADE',
		'CONCERTO',
		'CR_V',
		'CROSSTOUR',
		'CRX',
		'CRZ',
		'ELECTRIC',
		'ELEMENT',
		'ELYSION',
		'FIT',
		'FR_V',
		'HR_V',
		'INSIGHT',
		'INTEGRA',
		'JAZZ',
		'LEGEND',
		'LOGO',
		'NSX',
		'ODYSSEY',
		'PASSPORT',
		'PILOT',
		'PRELUDE',
		'QUINTET',
		'RIDGELINE',
		'S2000',
		'SHUTTLE',
		'STREAM',
	],
	HYUNDAI: [
		'ACCENT',
		'ATOS',
		'BAYON',
		'COUPE',
		'ELANTRA',
		'EQUUS',
		'EXCEL',
		'GALLOPER',
		'GENESIS',
		'GETZ',
		'GRACE',
		'GRANDEUR',
		'I10',
		'I20',
		'I30',
		'I40',
		'IX35',
		'IX55',
		'IONIQ',
		'IONIQ_5',
		'IX20',
		'KONA',
		'LANDSKAPE',
		'LANTRA',
		'MATRIX',
		'PALISADE',
		'PONY',
		'PORTER',
		'S',
		'SANTA_FE',
		'SANTAMO',
		'SONATA',
		'SONICA',
		'STAREX',
		'STARIA',
		'STELAR',
		'TB',
		'TERRACAN',
		'TRAJET',
		'TUCSON',
		'VELOSTER_',
		'VERACRUZ',
		'XG',
	],
	INFINITI: [
		'EX30',
		'EX35',
		'EX37',
		'FX_30',
		'FX_35',
		'FX_37',
		'FX_45',
		'FX_50',
		'FX45',
		'G',
		'G_COUPE',
		'G_SEDAN',
		'G37',
		'I',
		'J',
		'M',
		'Q',
		'Q30',
		'Q40',
		'Q45',
		'Q50',
		'QX30',
		'QX50',
		'QX56',
		'QX60',
		'QX70',
		'QX80',
		'QX',
		'QX4',
	],
	JAGUAR: [
		'DAIMLER',
		'DAIMLER_DOUBLE_SIX',
		'DAIMLER_SIX',
		'E_PACE',
		'F_PACE',
		'F_TYPE',
		'I_PACE',
		'S_TYPE',
		'SOVEREIGN',
		'SUPER_V8',
		'X_TYPE',
		'XE',
		'XF',
		'XJ',
		'XJR',
		'XJS',
		'XJSC',
		'XK8',
		'XKR',
	],
	JEEP: [
		'CHEROKEE',
		'COMMANDER',
		'COMPASS',
		'GRAND_WAGONEER',
		'GRAND_CHEROKEE',
		'PATRIOT',
		'RENEGADE',
		'WRANGLER',
	],
	JPX: [
		'MONTEZ',
	],
	KIA: [
		'AVELLA_DELTA',
		'CADENZA',
		'CARENS',
		'CARNIVAL',
		'CEED',
		'CERATO',
		'CLARUS',
		'EV6',
		'FORTE',
		'JOECS',
		'JOYCE',
		'K5',
		'K7',
		'K8',
		'K9',
		'MAGENTIS',
		'MOHAVE',
		'MORNING',
		'NIRO',
		'OPIRUS',
		'OPTIMA',
		'PICANTO',
		'PRIDE',
		'PRO_CEED',
		'QUORIS',
		'RAY',
		'RETONA',
		'RIO',
		'SEDONA',
		'SELTOS',
		'SEPHIA',
		'SHUMA',
		'SORENTO',
		'SOUL',
		'SPECTRA',
		'SPORTAGE',
		'STINGER',
		'STONIC',
		'TELLURIDE',
		'VENGA',
		'VISTO',
		'X_TREK',
		'XCEED',
	],
	LADA: [
		'1200',
		'1300',
		'1500',
		'1600',
		'2101',
		'21011',
		'21012',
		'21013',
		'21015',
		'2102',
		'2103',
		'2104',
		'21043',
		'2105',
		'21051',
		'21053',
		'2106',
		'21061',
		'21063',
		'2107',
		'21074',
		'2108',
		'21083',
		'2109',
		'21093',
		'21099',
		'2110',
		'21213',
		'GRANTA',
		'KALINA',
		'NIVA',
		'NOVA',
		'OKA',
		'PRIORA',
		'SAMARA',
		'TRAVEL',
		'URBAN',
		'VESTA',
		'XRAY',
	],
	LAFORZA: [
		'MAGNUM',
	],
	LAMBORGHINI: [
		'AVENTADOR',
		'COUNTACH',
		'DIABLO',
		'GALLARDO',
		'HURACAN',
		'MURCIELAGO',
		'REVENTON',
		'URUS',
		'VENENO',
	],
	LANCIA: [
		'A112',
		'ARDEA',
		'AURELIA',
		'BETA',
		'DEDRA',
		'DELTA',
		'FLAMINIA',
		'FLAVIA',
		'FULVIA',
		'KAPPA',
		'LYBRA',
		'MUSA',
		'PHEDRA',
		'PRISMA',
		'THEMA',
		'THESIS',
		'UNIOR',
		'VOYAGER',
		'Y',
		'Y10',
		'YPSILON',
		'ZETA',
	],
	LAND_ROVER: [
		'DEFENDER',
		'DISCOVERY',
		'FREELANDER',
		'RANGE_ROVER_EVOQUE',
		'RANGE_ROVER_SPORT',
		'RANGE_ROVER_VELAR',
		'RANGE_ROVER',
	],
	LANDWIND: [
		'JX6476DA',
	],
	LEXUS: [
		'CT',
		'ES',
		'GS',
		'GX',
		'IS',
		'LC',
		'LFA',
		'LS',
		'LX',
		'NX',
		'RC',
		'RX',
		'SC',
		'UX',
	],
	LIFAN: [
		'LF1010',
		'LF320',
		'LF520',
		'LF620',
		'LF6361',
		'LF7130',
		'LF7160',
		'X60',
	],
	LINCOLN: [
		'CONTINENTAL',
		'LS',
		'MKC',
		'MKS',
		'MTK',
		'MARK',
		'MARK_LT',
		'MKX',
		'MKZ',
		'NAVIGATOR',
		'TOWN_CAR',
		'ZEPHYR',
	],
	LOTUS: [
		'ELISE',
		'EUROPE',
		'EVORA',
		'EXIGE',
	],
	MAHINDRA: [
		'ARMADA',
		'BOLERO',
		'CL',
		'COMMANDER',
		'GOA',
		'KUV_100',
		'MARSHALL',
		'QUANTO',
		'SCORPIO',
		'XUV_500',
	],
	MASERATI: [
		'3200_GT',
		'BITURBO',
		'COUPE_GT',
		'GHIBLI',
		'GRANCABRIO',
		'GRANTURISMO',
		'GRANSPORT',
		'GRECALE',
		'LEVANTE',
		'MC20',
		'QUATTROPORTE',
		'SPYDER',
		'ZAGATO',
	],
	MATRA: [
		'MURENA',
		'RANCHO',
	],
	MAYBACH: [
		'57',
		'62',
		'650',
		'S_560',
		'S580',
	],
	MAZDA: [
		'121',
		'2',
		'3',
		'323',
		'5',
		'6',
		'626',
		'929',
		'B2200',
		'B2500',
		'B2600',
		'BT_50',
		'CX_30',
		'CX_5',
		'CX_60',
		'CX_7',
		'CX_9',
		'DEMIO',
		'MX_30',
		'MPV',
		'MX_3',
		'MX_5',
		'MX_6',
		'PREMACY',
		'RX_7',
		'RX_8',
		'TRIBUTE',
		'XEDOS',
		'СХ_3',
	],
	MCLAREN: [
		'540C_COUPE',
		'570S_COUPE',
		'650S',
		'675LT',
		'720_S',
		'F1',
		'GT',
		'MP4_12C',
		'P1',
	],
	MERCEDES_BENZ: [
		'110',
		'111',
		'113',
		'114',
		'115',
		'116',
		'123',
		'124',
		'126',
		'126_260',
		'150',
		'170',
		'180',
		'190',
		'200',
		'220',
		'230',
		'240',
		'250',
		'260',
		'280',
		'290',
		'300',
		'320',
		'350',
		'380',
		'420',
		'450',
		'500',
		'560',
		'600',
		'A_CLASS',
		'AMG_GT',
		'AMG_GT_C',
		'AMG_GT_R',
		'AMG_GT_S',
		'B_CLASS',
		'C_CLASS',
		'CL_CLASS',
		'CLA_CLASS',
		'CLC_CLASS',
		'CLK_CLASS',
		'CLS_CLASS',
		'CITAN',
		'E_CLASS',
		'EQA_CLASS',
		'EQB_CLASS',
		'EQC_CLASS',
		'EQE_CLASS',
		'EQS_CLASS',
		'EQV_CLASS',
		'G_CLASS',
		'GL_CLASS',
		'GLA_CLASS',
		'GLB_CLASS',
		'GLC_CLASS',
		'GLE_CLASS',
		'GLK_CLASS',
		'GLS_CLASS',
		'GT',
		'GTS',
		'ML_CLASS',
		'MAYBACH',
		'R_CLASS',
		'S_CLASS',
		'SL_CLASS',
		'SLC_CLASS',
		'SLK_CLASS',
		'SLR_CLASS',
		'SLS_CLASS',
		'T_CLASS',
		'UNIMOG',
		'VANEO',
		'VIANO',
		'X_CLASS',
	],
	MERCURY: [
		'MARAUDER',
		'MILAN',
		'MONARCH',
		'MOUNTAINEER',
		'VILLAGER',
	],
	MG: [
		'MGA',
		'MGB',
		'MGF',
		'TF',
		'ZR',
		'ZS',
		'ZT',
		'ZT_T',
	],
	MICROCAR: [
		'MC1',
		'MC2',
		'MGO',
	],
	MINI: [
		'CLUBMAN',
		'COOPER',
		'COOPER_CABRIO',
		'COOPER_S',
		'COOPER_S_CABRIO',
		'COUNTRYMAN',
		'COUPE',
		'D_ONE',
		'JOHN_COOPER_WORKS',
		'ONE',
		'ONE_CABRIO',
		'PACEMAN',
	],
	MITSUBISHI: [
		'3000_GT',
		'ASX',
		'ATTRAGE',
		'CARISMA',
		'COLT',
		'CORDIA',
		'ECLIPSE',
		'ECLIPSE_CROSS',
		'GALANT',
		'GRANDIS',
		'I_MIEV',
		'L200',
		'LANCER',
		'MIRAGE',
		'MONTERO',
		'OUTLANDER',
		'PAJERO',
		'PAJERO_PININ',
		'PAJERO_SPORT',
		'RVR',
		'SAPPORO',
		'SIGMA',
		'SPACE_GEAR',
		'SPACE_RUNNER',
		'SPACE_STAR',
		'SPACE_WAGON',
		'STARION',
		'TREDIA',
	],
	MOSKVICH: [
		'1360',
		'1361',
		'1500',
		'2136',
		'2138',
		'2140',
		'2141',
		'21412',
		'21417',
		'2142',
		'2715',
		'401',
		'403',
		'407',
		'408',
		'412',
		'426',
		'427',
		'503',
		'ALEKO',
		'ИЖ',
	],
	NISSAN: [
		'100_NX',
		'200_SX',
		'240_Z',
		'280_Z',
		'300_ZX',
		'350Z',
		'370Z',
		'ACURA',
		'ALMERA',
		'ALMERA_TINO',
		'ALTIMA',
		'ARIYA',
		'ARMADA',
		'BLUEBIRD',
		'CEDRIC',
		'CHERRY',
		'CUBE',
		'FIGARO',
		'FRONTIER',
		'GT_R',
		'JUKE',
		'KUBISTAR',
		'LAUREL',
		'LEAF_',
		'MAXIMA',
		'MICRA',
		'MURANO',
		'NP300',
		'NAVARA',
		'NOTE',
		'PATHFINDER',
		'PATROL',
		'PICKUP',
		'PIXO',
		'PRAIRIE',
		'PRIMERA',
		'PULSAR',
		'QASHQAI',
		'QUEST',
		'ROGUE',
		'SENTRA',
		'SERENA',
		'SILVIA',
		'SKYLINE',
		'STANTZA',
		'SUNNY',
		'TEANA',
		'TERRANO',
		'TIIDA',
		'TITAN_CREW_CAB',
		'TITAN_KING',
		'VERSA',
		'X_TRAIL',
		'XTERRA',
		'E_NV200',
	],
	OPEL: [
		'ADAM',
		'ADMIRAL',
		'AGILA',
		'AMPERA',
		'ANTARA',
		'ASCONA',
		'ASTRA',
		'CALIBRA',
		'CAMPO',
		'CASCADA',
		'COMBO',
		'COMMODORE',
		'CORSA',
		'CROSSLAND_X',
		'DIPLOMAT',
		'FRONTERA',
		'GRANDLAND_X',
		'GT',
		'INSIGNIA',
		'KADETT',
		'KAPITAEN',
		'KARL',
		'MANTA',
		'MERIVA',
		'MOKKA',
		'MONTEREY',
		'MONZA',
		'OMEGA',
		'REKORD',
		'SENATOR',
		'SIGNUM',
		'SINTRA',
		'SPEEDSTER',
		'TIGRA',
		'VECTRA',
		'ZAFIRA',
	],
	PEUGEOT: [
		'1007',
		'104',
		'106',
		'107',
		'108',
		'2008',
		'202',
		'204',
		'205',
		'206',
		'207',
		'208',
		'3008',
		'301',
		'304',
		'305',
		'306',
		'307',
		'308',
		'309',
		'4007',
		'4008',
		'402',
		'403',
		'404',
		'405',
		'406',
		'407',
		'408',
		'5008',
		'504',
		'505',
		'508',
		'604',
		'605',
		'607',
		'806',
		'807',
		'BIPPER',
		'EXPERT',
		'PARTNER',
		'RCZ',
		'RANGE',
		'RIFTER',
		'TRAVELER',
		'ION',
	],
	PGO: [
		'CEVENNES',
		'SPEEDSTER',
	],
	PLYMOUTH: [
		'ACCLAIM',
		'BARRACUDA',
		'BREEZE',
		'COLT',
		'GRAND_VOYAGER',
		'HORIZON',
		'LASER',
		'NEON',
		'PROWLER',
		'RELIANT',
		'ROAD_RUNNER',
		'SUNDANCE',
		'VOLARE',
		'VOYAGER',
	],
	POLESTAR: [
		'POLESTAR_ELECTRIC',
	],
	POLONEZ: [
		'PICKUP',
		'POLESTAR',
	],
	PONTIAC: [
		'AZTEC',
		'BONNEVILLE',
		'FIERO',
		'FIREBIRD',
		'G6',
		'GRAND_AM',
		'GRAND_PRIX',
		'GTO',
		'LEMANS',
		'SOLSTICE',
		'SUNBIRD',
		'SUNFIRE',
		'TEMPEST',
		'TORRENT',
		'TRANS_AM',
		'TRANS_SPORT',
		'VIBE',
	],
	PORSCHE: [
		'356_SPEEDSTER',
		'911',
		'918_SPYDER',
		'924',
		'928',
		'935',
		'944',
		'956',
		'968',
		'991',
		'993',
		'996',
		'BOXSTER',
		'CARRERA',
		'CAYENNE',
		'CAYMAN',
		'MACAN',
		'PANAMERA',
		'TAYCAN',
	],
	PROTON: [
		'400',
		'ARENA',
		'PERSONE',
		'SATRIA',
	],
	RENAULT: [
		'10',
		'11',
		'12',
		'14',
		'16',
		'18',
		'19',
		'20',
		'21',
		'25',
		'29',
		'30',
		'4',
		'5',
		'8',
		'9',
		'ALPINE',
		'ARKANA',
		'AVANTIME',
		'BAKARA',
		'BULGAR',
		'CAPTUR',
		'CARAVELLE',
		'CHAMADE',
		'CLIO',
		'DUSTER',
		'ESPACE',
		'EXPRESS',
		'FLORIDE',
		'FLUENCE',
		'FUEGO',
		'GRAND_ESPACE',
		'GRAND_SCENIC',
		'KADJAR',
		'KANGOO',
		'KOLEOS',
		'LAGUNA',
		'LAGUNA_COUPE',
		'LATITUDE',
		'MEGANE',
		'MODUS',
		'NEVADA',
		'RAPID',
		'SAFRANE',
		'SCENIC',
		'SCENIC_RX4',
		'SYMBOL',
		'TALISMAN',
		'TWINGO',
		'TWIZY',
		'VEL_SATIS',
		'WIND',
		'ZOE',
	],
	RIEJU: [
		'CENTURY',
		'MR',
	],
	ROLLS_ROYCE: [
		'CULLINAN',
		'DAWN',
		'GHOST',
		'PHANTOM',
		'RIEJU',
		'SILVER_CLOUD',
		'SILVER_SERAPH',
		'SILVER_SPUR',
		'WRAITH',
	],
	ROVER: [
		'111',
		'114',
		'200',
		'213',
		'214',
		'216',
		'220',
		'25',
		'400',
		'414',
		'416',
		'418',
		'420',
		'45',
		'600',
		'618',
		'620',
		'623',
		'75',
		'800',
		'820',
		'825',
		'827',
		'CITY',
		'ESTATE',
		'MAESTRO',
		'METRO',
		'MINI',
		'MONTEGO',
		'STREETWISE',
	],
	SECMA: [
		'F16',
		'F440DCI',
		'FUN_BUGGY',
		'FUN_EXTREEM',
		'FUN_LANDER',
		'FUN_QUAD',
	],
	SH_AUTO: [
		'CEO',
	],
	SAAB: [
		'9_3',
		'9_4X',
		'9_5',
		'9_7X',
		'900',
		'9000',
	],
	SAMAND: [
		'LX',
	],
	SANTANA: [
		'PS10',
	],
	SATURN: [
		'ASTRA',
		'AURA',
		'OUTLOOK',
		'SKY',
		'VUE',
	],
	SCION: [
		'TC',
		'XA',
		'XB',
	],
	SEAT: [
		'ALHAMBRA',
		'ALTEA',
		'ARONA',
		'AROSA',
		'ATECA',
		'CORDOBA',
		'CUPRA',
		'EXEO',
		'FURA',
		'IBIZA',
		'INKA',
		'LEON',
		'MALAGA',
		'MARBELLA',
		'MII',
		'RONDA',
		'TARRACO',
		'TERRA',
		'TOLEDO',
		'VARIO',
	],
	SKODA: [
		'100',
		'1000',
		'105',
		'120',
		'125',
		'130',
		'135',
		'136',
		'CITIGO',
		'ENYAQ',
		'FABIA',
		'FAVORIT',
		'FELICIA',
		'FORMAN',
		'KAMIQ',
		'KAROQ',
		'KODIAQ',
		'OCTAVIA',
		'PRAKTIK',
		'RAPID',
		'ROOMSTER',
		'SCALA',
		'SUPERB',
		'YETI',
	],
	SMART: [
		'FORFOUR',
		'FORTWO',
		'MC',
		'ROADSTER',
	],
	SSANG_YONG: [
		'CHAIRMAN',
		'RODIUS',
		'TIVOLI',
	],
	SSANGYONG: [
		'ACTYON',
		'ACTYON_SPORTS',
		'KORANDO',
		'KORANDO_SPORTS',
		'KYRON',
		'MUSSO',
		'REXTON',
		'TIVOLI',
		'XLV',
	],
	SUBARU: [
		'1800',
		'ASCENT',
		'B10_TRIBEKA',
		'B9_TRIBECA',
		'BRZ',
		'BAJA',
		'E12',
		'FORESTER',
		'G3X_JUSTY',
		'IMPREZA',
		'JUSTY',
		'LEGACY',
		'LEONE',
		'LEVORG',
		'LIBERO',
		'OUTBACK',
		'SVX',
		'TREZIA',
		'VIVIO',
		'XT',
		'XV',
	],
	SUZUKI: [
		'ACROSS',
		'ALTO',
		'BALENO',
		'CELERIO',
		'FORENZA',
		'GRAND_VITARA',
		'IGNIS',
		'JIMNY',
		'KIZASHI',
		'LIANA',
		'MARUTI',
		'RENO',
		'SX4',
		'SX4_S_CROSS',
		'SAMURAI',
		'SANTANA',
		'SG',
		'SIDEKICK',
		'SJ',
		'SPLASH',
		'SWACE',
		'SWIFT',
		'VITARA',
		'WAGON_R',
		'X_90',
		'XL_7',
	],
	TALBOT: [
		'1100',
		'1310',
		'HORIZON',
		'MATRA',
		'MURENA',
		'SAMBA',
		'SIMKA',
		'SOLARA',
	],
	TERBERG: [
		'FL2850',
		'SL3000',
	],
	TESLA: [
		'MODEL_3',
		'MODEL_S',
		'MODEL_X',
		'MODEL_Y',
		'ROADSTER',
		'ROADSTER_SPORT',
	],
	TOYOTA: [
		'4RUNNER',
		'ALPHARD',
		'AURIS',
		'AVALON',
		'AVENSIS',
		'AVENSIS_VERSO',
		'AYGO',
		'C_HR',
		'CAMRY',
		'CARINA',
		'CELICA',
		'COROLLA',
		'COROLLA_MATRIX',
		'COROLLA_VERSO',
		'CRESSIDA',
		'CROWN',
		'FJ_CRUISER',
		'GT86',
		'HARRIER',
		'HIGHLANDER',
		'HILUX',
		'IQ',
		'LAND_CRUISER',
		'MATRIX',
		'MR2',
		'PASEO',
		'PICNIC',
		'PREVIA',
		'PRIUS',
		'PROACE_CITY',
		'PROACE_CITY_VERSO',
		'RAV4',
		'SCION',
		'SEQUOIA',
		'SIENNA',
		'STARLET',
		'SUPRA',
		'TACOMA',
		'TERCEL',
		'TUNDRA',
		'URBAN_CRUISER',
		'VENZA',
		'VERSO',
		'VERSO_S',
		'YARIS',
		'YARIS_VERSO',
		'BZ4X',
	],
	VW: [
		'1200',
		'1300',
		'1302',
		'1303',
		'1500',
		'1600',
		'ALLTRACK',
		'AMAROK',
		'ARTEON',
		'ATLAS',
		'BORA',
		'CC',
		'CADDY',
		'CORRADO',
		'DERBY',
		'EOS',
		'FOX',
		'GOLF',
		'GOLF_PLUS',
		'GOLF_VARIANT',
		'ID_3',
		'ID_4',
		'ID_5',
		'ID_6',
		'JETTA',
		'K_70',
		'KARMANN_GHIA',
		'LUPO',
		'MULTIVAN',
		'NEW_BEETLE',
		'PASSAT',
		'PHAETON',
		'POLO',
		'RABBIT',
		'SANTANA',
		'SCIROCCO',
		'SHARAN',
		'SPORTSVAN',
		'T_CROSS',
		'T_ROC',
		'TAIGO',
		'TARO',
		'TIGUAN',
		'TOUAREG',
		'TOURAN',
		'UP',
		'VENTO',
	],
	VOLVO: [
		'142',
		'144',
		'145',
		'164',
		'1800_ES',
		'240',
		'244',
		'245',
		'262_C',
		'264',
		'340',
		'343',
		'344',
		'345',
		'360',
		'440',
		'460',
		'480',
		'66',
		'740',
		'744',
		'745',
		'760',
		'765',
		'770',
		'780',
		'850',
		'940',
		'960',
		'C30',
		'C70',
		'P_1800',
		'S40',
		'S60',
		'S70',
		'S80',
		'S90',
		'V40',
		'V40_CROSS_COUNTRY',
		'V50',
		'V60',
		'V60_CROSS_COUNTRY',
		'V70',
		'V90',
		'V90_CROSS_COUNTRY',
		'XC40',
		'XC60',
		'XC70',
		'XC90',
	],
}

export const MOBILE_VARIATIONS : { [key: string] : {[key: string] : string[]} } = {
	MERCEDES_BENZ: {
		'A_CLASS': [
			'A 140',
			'A 150',
			'A 160',
			'A 170',
			'A 180',
			'A 190',
			'A 200',
			'A 210',
			'A 220',
			'A 250',
			'A 35',
			'A 45',
			'A45 AMG',
		],
		'B_CLASS': [
			'B 150',
			'B 160',
			'B 170',
			'B 180',
			'B 200',
			'B 220',
			'B 250',
		],
		'C_CLASS': [
			'C 160',
			'C 180',
			'C 200',
			'C 220',
			'C 230',
			'C 240',
			'C 250',
			'C 270',
			'C 280',
			'C 30 AMG',
			'C 300',
			'C 32 AMG',
			'C 320',
			'C 350',
			'C 36 AMG',
			'C 400',
			'C 43 AMG',
			'C 450 AMG',
			'C 55 AMG',
			'C 63 AMG',
		],
		'CL_CLASS': [
			'CL 230',
			'CL 320',
			'CL 420',
			'CL 500',
			'CL 55 AMG',
			'CL 600',
			'CL 63 AMG',
			'CL 65 AMG',
		],
		'CLA_CLASS': [
			'CLA 180',
			'CLA 200',
			'CLA 220',
			'CLA 250',
			'CLA 45 AMG',
		],
		'CLC_CLASS': [
			'CLC 160',
			'CLC 180',
			'CLC 200',
			'CLC 220',
			'CLC 230',
			'CLC 250',
			'CLC 350',
		],
		'CLK_CLASS': [
			'CLK 55 AMG',
			'CLK 63 AMG',
		],
		'CLS_CLASS': [
			'CLS 250',
			'CLS 300',
			'CLS 320',
			'CLS 350',
			'CLS 400',
			'CLS 450',
			'CLS 500',
			'CLS 53 AMG',
			'CLS 55',
			'CLS 55 AMG',
			'CLS 550',
			'CLS 63',
			'CLS 63 AMG',
		],
		'E_CLASS': [
			'E 200',
			'E 220',
			'E 230',
			'E 240',
			'E 250',
			'E 260',
			'E 270',
			'E 280',
			'E 290',
			'E 300',
			'E 320',
			'E 350',
			'E 36 AMG',
			'E 400',
			'E 420',
			'E 43 AMG',
			'E 430',
			'E 450',
			'E 50 AMG',
			'E 500',
			'E 53 AMG',
			'E 55',
			'E 55 AMG',
			'E 60',
			'E 60 AMG',
			'E 63 AMG',
		],
		'EQA_CLASS': [
			'EQA',
		],
		'EQB_CLASS': [
			'EQB',
		],
		'EQC_CLASS': [
			'EQC',
		],
		'EQE_CLASS': [
			'EQE',
		],
		'EQS_CLASS': [
			'EQS',
		],
		'EQV_CLASS': [
			'EQV',
		],
		'G_CLASS': [
			'G 230',
			'G 240',
			'G 250',
			'G 270',
			'G 280',
			'G 290',
			'G 300',
			'G 320',
			'G 350',
			'G 36 AMG',
			'G 400',
			'G 500',
			'G 55 AMG',
			'G 63 AMG',
			'G 65 AMG',
		],
		'GL_CLASS': [
			'GL',
			'GL 320',
			'GL 350',
			'GL 420',
			'GL 450',
			'GL 500',
			'GL 55 AMG',
			'GL 63 AMG',
		],
		'GLA_CLASS': [
			'GLA 180',
			'GLA 200',
			'GLA 220',
			'GLA 250',
			'GLA 45 AMG',
		],
		'GLB_CLASS': [
			'GLB',
		],
		'GLC_CLASS': [
			'GLC 220',
			'GLC 250',
			'GLC 300',
			'GLC 350',
			'GLC 400',
			'GLC 43 AMG',
			'GLC 63 AMG',
		],
		'GLE_CLASS': [
			'GLE 250',
			'GLE 350',
			'GLE 400',
			'GLE 43 AMG',
			'GLE 450 AMG',
			'GLE 500',
			'GLE 53 4MATIC',
			'GLE 580',
			'GLE 63 AMG',
			'GLE 63 S AMG',
			'GLE Coupe',
		],
		'GLK_CLASS': [
			'GLK',
		],
		'GLS_CLASS': [
			'GLS 350',
			'GLS 400',
			'GLS 450',
			'GLS 500',
			'GLS 600',
			'GLS 63 AMG',
			'GLS580',
		],
		'ML_CLASS': [
			'ML 230',
			'ML 250',
			'ML 270',
			'ML 280',
			'ML 300',
			'ML 320',
			'ML 350',
			'ML 400',
			'ML 420',
			'ML 430',
			'ML 450',
			'ML 500',
			'ML 55 AMG',
			'ML 63 AMG',
		],
		'R_CLASS': [
			'R 280',
			'R 300',
			'R 320',
			'R 350',
			'R 500',
			'R 63 AMG',
		],
		'S_CLASS': [
			'S 250',
			'S 280',
			'S 300',
			'S 320',
			'S 350',
			'S 400',
			'S 420',
			'S 430',
			'S 450',
			'S 500',
			'S 55 AMG',
			'S 550',
			'S 560',
			'S 600',
			'S 63',
			'S 63 AMG',
			'S 65',
			'S 65 AMG',
			'S580',
			'S680',
		],
		'SL_CLASS': [
			'SL 400',
			'SL 43 AMG',
			'SL 500',
			'SL 53 AMG',
			'SL 55 AMG',
			'SL 60 AMG',
			'SL 600',
			'SL 63 AMG',
			'SL 65 AMG',
		],
		'SLC_CLASS': [
			'SLC',
		],
		'SLK_CLASS': [
			'SLK',
			'SLK 32 AMG',
			'SLK 55 AMG',
		],
		'SLR_CLASS': [
			'SLR',
		],
		'SLS_CLASS': [
			'SLS',
			'SLS AMG',
		],
		'T_CLASS': [
			'T-класа',
		],
		'X_CLASS': [
			'X-Klasse',
		],
	},
	BMW: {
		'1_ER': [
			'114',
			'116',
			'118',
			'120',
			'123',
			'125',
			'128',
			'130',
			'135',
			'140',
		],
		'2_ER': [
			'216',
			'218',
			'220',
			'225',
			'228',
			'230',
			'235',
			'240',
		],
		'3_ER': [
			'315',
			'316',
			'318',
			'320',
			'323',
			'324',
			'325',
			'328',
			'330',
			'335',
			'340',
			'3gt',
		],
		'4_ER': [
			'418',
			'420',
			'425',
			'428',
			'430',
			'435',
			'440',
		],
		'5_ER': [
			'501',
			'518',
			'520',
			'523',
			'524',
			'525',
			'528',
			'530',
			'535',
			'540',
			'545',
			'550',
		],
		'6_ER': [
			'620',
			'628',
			'630',
			'633',
			'635',
			'640',
			'645',
			'650',
		],
		'7_ER': [
			'700',
			'721',
			'723',
			'725',
			'728',
			'730',
			'732',
			'733',
			'735',
			'740',
			'745',
			'750',
			'760',
		],
		'8_ER': [
			'840',
			'850',
		],
	},
	LEXUS: {
		'CT': [
			'CT 200h',
		],
		'GX': [
			'GX 460',
			'GX 470',
		],
		'RX': [
			'RX',
			'RX 200t',
			'RX 300',
			'RX 330',
			'RX 350',
			'RX 400h',
			'RX 450',
			'RX350h',
			'RX450h+',
			'RX500h',
		],
	},
}
