import './App.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { HomePage } from './components/homepage/Homepage';
import { About } from './components/about/About';
import { NotImplemented } from './components/not-implemented/NotImplemented';
import { Information } from './components/information/Information'
import { CollapsibleExample } from './components/navbar/Navbar'
import { ErrorPage } from './components/error/Error'
import { BrandList } from './components/brandList/BrandList'
import { ModelList } from './components/modelList/ModelList';

function App() {
  return (
    <div className="App App-header">
      <meta name="description" content="Your Go-To Source for Informed Car Purchases: Navigate the Auto Market with Data-Driven Insights. Explore comprehensive statistics, reported issues, and market trends for various car models. From uncovering the most reliable options to understanding common problems, we equip you with valuable information to make confident and informed decisions on your next vehicle purchase." />
      <CollapsibleExample/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About content={'Finally'}/>} />
            <Route path="/cars" element={<BrandList />} />
            <Route path="/modelList" element={<ModelList />} />
            <Route path="/not-implemented" element={<NotImplemented />} />
            <Route path="/information" element={<Information />} />
            <Route path='*' element={<ErrorPage/>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
