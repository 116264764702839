import { createSearchParams, useNavigate } from 'react-router-dom';
import { mobileSearchModels } from '../helpers/mobile-search-models'
import './BrandList.css';

export function BrandList() {
    let navigate = useNavigate();

    function goTo(page : string, state: any) {
        navigate({
        pathname: page,
        search: `?${createSearchParams(state)}`
        });
    }

    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {mobileSearchModels.map((brandArray: any, index: any)=>{
            return <div className='modelCard'
                        key={index} 
                        onClick={()=>{goTo('/modelList', {make: brandArray[0], id: index})}}>
                        {brandArray[0]}
                    </div>
        })}
      </div>
    );
  }