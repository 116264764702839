import './Chart.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'DataPloting',
    },
  },
  redraw: true,
};

const labels = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December'];

let ChartData = {
  labels,
  datasets: [
    {
      label: 'Average Price (lv)',
      data: labels.map(() => (Math.random() * 0)),
      borderColor: 'rgb(255, 22, 132)',
      backgroundColor: 'rgba(255, 22, 132, 0.5)',
    }
  ],
  redraw: true,
};

export default function Chart(this: any, props : any) {
  options.plugins.title.text = `Prices for ${props.make} ${props.model} for the last year.`;

  if (props.data.length) {
    ChartData.labels = props.data.map((el : any) => { return `${el.date.getDate()} ${labels[el.date.getMonth()]}`});
    if (props.generation && props.generation != 'overall') {
      let gen = props.generation.split('/')[0];
      let engine = props.generation.split('/')[1];
      ChartData.datasets[0].data = props.data.map((el : any) => {
        const genInfo = el.data.get(gen);
        if (!genInfo) {
          return 0;
        }

        const engineInfo = genInfo.get(engine);
        if (!engineInfo) {
          return 0;
        }

        let totalSum = 0;
        let totalCount = 0;
        const vals = engineInfo.engineGearboxSplitInfo.values();
        for(let obj of vals) {
          totalSum += obj.total;
          totalCount += obj.count;
        }

        return Math.round(totalSum / totalCount);
       });
    } else {
      ChartData.datasets[0].data = props.data.map((el : any) => { return Math.round(el.data.get('overall').mean); });
    }
  }

  let el = ChartJS.getChart('BestPlot');
  if (el) {
    el.data = ChartData;
    el.update();
  }

  return <div className="chart-container">
          <Line id='BestPlot'
          options={options}
          data={ChartData} />
        </div>
};

