import './SearchBar.css';

import { useNavigate, createSearchParams } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

// Mappings
import * as mobileMap from '../../mappings/mobile_mappings';

export default function SearchBar() {

  let navigate = useNavigate();

  function goTo(page : string, state: any) {
    navigate({
      pathname: page,
      search: `?${createSearchParams(state)}`
    });
  }

  let items = [];
  let id = 0;

  for (const [brand, models] of Object.entries(mobileMap.MOBILE_MODELS)) {
    //console.log(`${brand}: ${models}`);

    for (const [modelKey, modelName] of Object.entries(models)) {
      let models = [ modelName ];

      if (mobileMap.MOBILE_VARIATIONS[brand as keyof Object] &&
        mobileMap.MOBILE_VARIATIONS[brand as keyof Object][modelKey as keyof Object]) {
        models = mobileMap.MOBILE_VARIATIONS[brand as keyof Object][modelKey as keyof Object];
      }

      for (const model of models) {
        items.push({
          id: id++,
          name: `${mobileMap.MOBILE_BRANDS[brand as keyof Object]} ${model}`,
          make: mobileMap.MOBILE_BRANDS[brand as keyof Object],
          model: model
        });
      }
    }
  }

  const handleOnSearch = (string: string, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result: any) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item: any) => {
    // the item selected
    console.log(item);

    goTo('/information', {make: item.make, model: item.model});
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item: any) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  return (
    <div className='searchbar-container'>
    <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
          />
    </div>
  );
}